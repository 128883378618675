import {
  FETCH_PROFILE,
  FETCH_PROFILES,
  CREATE_PROFILE,
} from "../Constants/actionTypes";

import * as API from "../API/employeeAPICalls";

export const fetchProfile = () => async (dispatch) => {
  try {
    const { data } = await API.fetchProfile();
    dispatch({ type: FETCH_PROFILE, payload: data.employee });
  } catch (error) {
    console.log(error);
  }
};

export const createProfile = (profileData) => async (dispatch) => {
  try {
    const { data } = await API.createProfile(profileData);
    dispatch({ type: CREATE_PROFILE, payload: data.employee });
  } catch (error) {
    console.log(error);
  }
};

export const fetchProfiles = () => async (dispatch) => {
  try {
    const { data } = await API.fetchProfiles();
    dispatch({ type: FETCH_PROFILES, payload: data.employees });
  } catch (error) {
    console.log(error);
  }
};
