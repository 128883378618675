import React, { useContext, useEffect, useState } from "react";
import axiosInstance from "../../Utils/axios";

import {
  Avatar,
  Skeleton,
  Typography,
  Radio,
  message,
  Button,
  Space,
  Tooltip,
} from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import ProgressBar from "../ProgressBar/ProgressBar";
import dayjs from "dayjs";
import GlobalContext from "../../Context/GlobalContext";
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);
const SondageCard = ({ id, access }) => {
  dayjs.extend(isBetween);
  const { profile } = useContext(GlobalContext);
  const [loadingCard, setLoadingCard] = useState(true);
  const [sondage, setSondage] = useState();
  const [ellipsis, setEllipsis] = useState(true);
  const colors = [
    "#91caff",
    "#bcd0ff",
    "#dabaf8",
    "#d3f3bb",
    "#ffc4a3",
    "#ffe99f",
    "#87e8de",
  ];

  useEffect(() => {
    async function fetchData() {
      const { data } = await axiosInstance.get(`/api/survey/${id}`);

      setSondage(data.sondage);
      setLoadingCard(false);

      return sondage;
    }

    fetchData();
  }, [id]);

  const removeVote = async () => {
    try {
      const { data } = await axiosInstance.patch(
        `/api/survey/remove/vote/${sondage._id}`
      );
      setSondage({ ...sondage, votes: data.sondage.votes });
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  const handleChange = async (e) => {
    try {
      const { data } = await axiosInstance.patch(
        "/api/survey/vote/" + sondage._id,
        { idOption: e.target.value }
      );

      setSondage({ ...sondage, votes: data.sondage.votes });
    } catch (error) {
      message.error(error.response.data.message);
    }
  };
  const startDateMessage = `Le sondage commence à partir du ${dayjs(
    sondage?.start_date
  ).format("DD/MM:YYYY HH:mm")} et se termine ${dayjs(sondage?.end_date).format(
    "DD/MM:YYYY HH:mm"
  )}`;
  return (
    <div className="col-lg-12 col-md-12 col-sm-12 d-flex">
      <div style={{ width: "30px" }}></div>
      {loadingCard ? (
        <div className="d-flex w-100">
          <Skeleton
            active
            avatar
            paragraph={{
              rows: 4,
            }}
            className="w-100 my-3 bg-light p-3 rounded-4 shadow-md"
          />
        </div>
      ) : (
        <div className=" d-flex my-3 w-100">
          <div className="d-flex w-100 flex-column  bg-light  rounded-3 pt-2 px-4 pb-2 shadow-sm">
            <div className="d-flex align-items-center justify-content-center">
              <Avatar
                size={{
                  xs: 24,
                  sm: 32,
                  md: 40,
                  lg: 40,
                  xl: 48,
                  xxl: 64,
                }}
                src={<img src={sondage?.from_image} alt="avatar" />}
              />
              <Typography.Paragraph className="d-flex justify-content-between w-100 pt-3 px-2">
                <div className="d-flex flex-column flex-lg-row flex-md-row justify-content-evenly">
                  <span>{sondage?.fullName} </span>
                </div>
                <span className="datePoste">
                  <FontAwesomeIcon icon={faCalendar} />{" "}
                  {dayjs(sondage?._posted_date).fromNow()}
                </span>
              </Typography.Paragraph>
            </div>
            <div className="w-100 px-2">
              <Typography.Paragraph
                style={{ marginBottom: "6px" }}
                ellipsis={
                  ellipsis
                    ? {
                        rows: 2,
                        expandable: ellipsis,
                        symbol: (
                          <Typography.Link
                            onClick={(event) => {
                              event.stopPropagation();
                              setEllipsis(false);
                            }}
                          >
                            Plus
                          </Typography.Link>
                        ),
                      }
                    : false
                }
                className="d-flex justify-content-between mt-3"
              >
                {sondage?.description}
                {!ellipsis && (
                  <Typography.Link
                    onClick={() => {
                      setEllipsis(true);
                    }}
                  >
                    {" "}
                    Moins
                  </Typography.Link>
                )}
              </Typography.Paragraph>
              <Typography.Paragraph>
                <Tooltip
                  title={
                    !dayjs().isBetween(sondage?.start_date, sondage?.end_date)
                      ? startDateMessage
                      : ""
                  }
                  placement="top"
                  color="cyan"
                >
                  <Radio.Group
                    disabled={
                      !dayjs().isBetween(
                        sondage?.start_date,
                        sondage?.end_date
                      ) || access
                    }
                    style={{
                      backgroundColor: "transparent",
                      width: "100%",
                      height: "100%",
                    }}
                    onChange={handleChange}
                    defaultValue={
                      sondage?.votes?.find(
                        (elem) => elem.idUser === profile.matricule
                      )
                        ? sondage?.votes?.find(
                            (elem) => elem.idUser === profile.matricule
                          ).idOption
                        : null
                    }
                    value={
                      sondage?.votes?.find(
                        (elem) => elem.idUser === profile.matricule
                      )
                        ? sondage?.votes?.find(
                            (elem) => elem.idUser === profile.matricule
                          ).idOption
                        : null
                    }
                  >
                    {sondage?.options.map((element, index) => {
                      const tempValues = sondage?.votes?.filter(
                        (elem) => elem.idOption === element._id
                      );
                      const precentage = Math.round(
                        (tempValues.length / sondage?.votes.length) * 100
                      );

                      return (
                        <ProgressBar
                          progress={precentage || 0}
                          color={colors[index]}
                          value={element.value}
                          valueId={element._id}
                        />
                      );
                    })}
                  </Radio.Group>
                </Tooltip>
                {!access && (
                  <div className="d-flex justify-content-end">
                    <Button
                      type="primary"
                      disabled={
                        !sondage?.votes?.find(
                          (elem) => elem.idUser === profile.matricule
                        ) ||
                        !dayjs().isBetween(
                          sondage?.start_date,
                          sondage?.end_date
                        )
                      }
                      onClick={removeVote}
                    >
                      Retirer mon vote
                    </Button>
                  </div>
                )}
                {access && (
                  <div className="d-flex justify-content-end">
                    <Space>
                      <Typography.Link>Masquer</Typography.Link>
                      <Typography.Link>Supprimer</Typography.Link>
                    </Space>
                  </div>
                )}
              </Typography.Paragraph>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SondageCard;
