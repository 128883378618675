import { useState, useEffect, useContext } from "react";

import { Button, Menu, Layout, Drawer, Typography, Tooltip } from "antd";
import closeIcon from "../../../Assets/close.svg";
import eyeIcon from "../../../Assets/eye.svg";
import eyeHiddenIcon from "../../../Assets/eyehidden.svg";
import { useNavigate, Link, Outlet } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInbox,
  faCalendar,
  faArrowRightFromBracket,
  faFolderOpen,
  faBoxArchive,
  faBell,
  faComputer,
  faPlus,
  faHouse,
  faBook,
  faGear,
  faFolder,
  faFolderTree,
  faFolderPlus,
  faQuestionCircle,
  faCalendarDays,
} from "@fortawesome/free-solid-svg-icons";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  UserAddOutlined,
  GroupOutlined,
  FileAddOutlined,
} from "@ant-design/icons";

import moment from "moment";
import axios from "../../../Utils/axios";
import LocalStorageService from "../../../Utils/localStorageService";

import iconDark from "../../../Assets/brand_icon_dark.svg";
import iconLight from "../../../Assets/brand_icon_light.svg";

import logoDark from "../../../Assets/brand_large_dark.png";
import logoLight from "../../../Assets/brand_large_light.svg";
import ThemeEditor from "../../../Components/ThemeEditor";
import GlobalContext from "../../../Context/GlobalContext";

const { Text } = Typography;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
const { Header, Sider, Content } = Layout;

const LayoutAdmin = ({ role }) => {
  const { theme } = useContext(GlobalContext);
  const [autoNotif, setAutoNotif] = useState(
    JSON.parse(LocalStorageService().getNotif())
  );

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const [Notifications, setNotifications] = useState([]);

  const [visible, setVisible] = useState(false);

  // eslint-disable-next-line
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
      if (windowSize.innerWidth < 992) {
        setCollapsed(true);
      }
    }
    window.addEventListener("resize", handleWindowResize);

    handleWindowResize();

    async function GetNotifications(autoNotif) {
      const { data } = await axios.get("/api/notif/");

      setNotifications(data.notifs);
      setAutoNotif(false);
      LocalStorageService().setNotif(false);
      return data;
    }

    GetNotifications(autoNotif);
    // eslint-disable-next-line
  }, []);

  let Navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    Navigate("/login");
    window.location.reload();
  };

  const [collapsed, setCollapsed] = useState(false);

  const items = [
    getItem(
      <Link
        to="/administration"
        style={{ textDecoration: "none", fontWeight: "bold" }}
      >
        Accueil
      </Link>,
      "1",
      <FontAwesomeIcon icon={faHouse} />
    ),
    (role === "HR" || role === "ACCOUNTANT" || role === "SUPER_ADMIN") &&
      getItem(
        <div style={{ fontWeight: "bold" }}>Employés</div>,
        "2",
        <UserOutlined />,
        [
          (role === "HR" || role === "SUPER_ADMIN") &&
            getItem(
              <Link
                to="/administration/employees/form"
                style={{ textDecoration: "none", fontWeight: "bold" }}
              >
                Ajouter
              </Link>,
              "21",
              <UserAddOutlined />
            ),
          getItem(
            <Link
              to="/administration/employees/list"
              style={{ textDecoration: "none", fontWeight: "bold" }}
            >
              Liste
            </Link>,
            "22",
            <GroupOutlined />
          ),
        ]
      ),
    (role === "HR" || role === "ACCOUNTANT" || role === "SUPER_ADMIN") &&
      getItem(
        <div style={{ fontWeight: "bold" }}>Registre</div>,
        "3",
        <FontAwesomeIcon icon={faBook} />,
        [
          (role === "HR" || role === "SUPER_ADMIN") &&
            getItem(
              <Link
                to="/administration/leaves/form"
                style={{ textDecoration: "none", fontWeight: "bold" }}
              >
                Déclarer une absence
              </Link>,
              "32",
              <FileAddOutlined />
            ),
          getItem(
            <Link
              to="/administration/absences/list"
              style={{ textDecoration: "none", fontWeight: "bold" }}
            >
              Liste des absences
            </Link>,
            "35",
            <GroupOutlined />
          ),
          getItem(
            <Link
              to="/administration/leaves/list"
              style={{ textDecoration: "none", fontWeight: "bold" }}
            >
              Registre mensuel
            </Link>,
            "31",
            <FontAwesomeIcon icon={faFolderOpen} />
          ),
        ]
      ),
    getItem(
      <Link
        to="/administration/team/calendar"
        style={{ textDecoration: "none", fontWeight: "bold" }}
      >
        Calendrier de l'équipe
      </Link>,
      "13",
      <FontAwesomeIcon icon={faCalendarDays} />
    ),
    (role === "HR" || role === "SUPER_ADMIN") &&
      getItem(
        <div style={{ fontWeight: "bold" }}>Documents partagés</div>,
        "15",
        <FontAwesomeIcon icon={faFolder} />,
        [
          getItem(
            <Link
              to="/administration/documents/form"
              style={{ textDecoration: "none", fontWeight: "bold" }}
            >
              Ajouter
            </Link>,
            "152",
            <FontAwesomeIcon icon={faFolderPlus} />
          ),
          getItem(
            <Link
              to="/administration/documents/list"
              style={{ textDecoration: "none", fontWeight: "bold" }}
            >
              Liste
            </Link>,
            "151",
            <FontAwesomeIcon icon={faFolderTree} />
          ),
        ]
      ),
    (role === "HR" || role === "SUPER_ADMIN") &&
      getItem(
        <div style={{ fontWeight: "bold" }}>Équipements</div>,
        "10",
        <FontAwesomeIcon icon={faComputer} />,
        [
          getItem(
            <Link
              to="/administration/equipments/managment"
              style={{ textDecoration: "none", fontWeight: "bold" }}
            >
              Ajouter
            </Link>,
            "103",
            <FontAwesomeIcon icon={faPlus} />
          ),
          getItem(
            <Link
              to="/administration/equipments/list"
              style={{ textDecoration: "none", fontWeight: "bold" }}
            >
              Liste
            </Link>,
            "102",
            <GroupOutlined />
          ),
        ]
      ),
    (role === "HR" || role === "SUPER_ADMIN") &&
      getItem(
        <div style={{ fontWeight: "bold" }}>Demandes</div>,
        "4",
        <FontAwesomeIcon icon={faInbox} />,
        [
          getItem(
            <Link
              to="/administration/requests"
              style={{ textDecoration: "none", fontWeight: "bold" }}
            >
              Demandes en cours
            </Link>,
            "444"
          ),
          getItem(
            <Link
              to="/administration/requests/archive"
              style={{ textDecoration: "none", fontWeight: "bold" }}
            >
              Historique des demandes
            </Link>,
            "454"
          ),
        ]
      ),
    (role === "HR" || role === "SUPER_ADMIN") &&
      getItem(
        <Link
          to="/administration/holidays"
          style={{ textDecoration: "none", fontWeight: "bold" }}
        >
          Jours fériés
        </Link>,
        "5",
        <FontAwesomeIcon icon={faCalendar} />
      ),
    getItem(
      // eslint-disable-next-line
      <Link
        to="/administration/logs"
        style={{ textDecoration: "none", fontWeight: "bold" }}
        s
      >
        Historique
      </Link>,
      "6",
      <FontAwesomeIcon icon={faBoxArchive} />
    ),
    getItem(
      // eslint-disable-next-line
      <a
        href="#"
        onClick={showDrawer}
        style={{ textDecoration: "none", fontWeight: "bold" }}
      >
        Notifications
      </a>,
      "7",
      <FontAwesomeIcon icon={faBell} />
    ),
    getItem(
      <Link
        style={{ textDecoration: "none", fontWeight: "bold" }}
        to="/administration/parametre"
      >
        Paramètres
      </Link>,
      "9",
      <FontAwesomeIcon icon={faGear} />
    ),
    getItem(
      <Link
        style={{ textDecoration: "none", fontWeight: "bold" }}
        to="/administration/faq"
      >
        FAQ
      </Link>,
      "99",
      <FontAwesomeIcon icon={faQuestionCircle} />
    ),
    getItem(
      // eslint-disable-next-line
      <a
        href="#"
        onClick={handleLogout}
        style={{ textDecoration: "none", fontWeight: "bold" }}
      >
        Se déconnecter
      </a>,
      "8",
      <FontAwesomeIcon icon={faArrowRightFromBracket} />
    ),
  ];

  const handleDisableNotif = async (id, status) => {
    try {
      const { data } = await axios.patch("/api/notif/read/" + id, {
        status: status,
      });
      if (data.status === "success") {
        const new_array = Notifications.map((notif) => {
          if (notif._id === id) {
            notif.read = status;
            return notif;
          } else {
            return notif;
          }
        });
        setNotifications(new_array);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleDeleteNotif = async (id, status) => {
    try {
      const { data } = await axios.patch("/api/notif/deleted/" + id, {
        status: status,
      });
      if (data.status === "success") {
        let new_array = [];
        Notifications.forEach((notif) => {
          if (notif._id !== id) new_array.push(notif);
        });
        setNotifications(new_array);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <Layout className="vh-100 vw-100 overflow-hidden">
      <Sider
        style={{ backgroundColor: theme.siderBg }}
        trigger={null}
        collapsible
        collapsed={collapsed}
        width={270}
      >
        <div className="text-center mt-2 mb-3">
          {!collapsed ? (
            <img
              src={theme.logo === "light" ? logoLight : logoDark}
              style={{
                height: "35px",
                cursor: "pointer",
                transition: "width 0.2s ease",
              }}
              alt="Neopolis"
            />
          ) : (
            <img
              src={theme.logo === "light" ? iconLight : iconDark}
              style={{
                height: "30px",
                cursor: "pointer",
                transition: "width 0.2s ease",
              }}
              alt="Neopolis"
            />
          )}
        </div>
        <div style={{ height: "calc(100vh - 80px)" }}>
          <Menu
            className="h-100"
            mode="inline"
            theme={theme.siderMode}
            style={{ overflowY: "scroll", backgroundColor: theme.siderBg }}
            inlineCollapsed={collapsed}
            items={items}
          />
        </div>
      </Sider>
      <Layout style={{ background: "#F1F6F9" }}>
        <Header
          style={{
            padding: 0,
            background: theme.headerBg,
          }}
          className="d-flex justify-content-between border-bottom "
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
            }}
          />
        </Header>
        <Drawer
          title="Notifications"
          placement="right"
          onClose={onClose}
          visible={visible}
        >
          {Notifications.map((notif) => {
            return !notif.read ? (
              <div
                className="rounded d-flex mt-2"
                style={{
                  backgroundColor: "#F0FAFD",
                  paddingRight: "10px",
                  paddingBottom: "5px",
                }}
              >
                <div className="px-2 py-1">
                  <span style={{ fontSize: "16px" }}>
                    <FontAwesomeIcon
                      icon={faBell}
                      style={{ color: "#2c5397" }}
                    />
                  </span>
                </div>
                <div className="d-flex justify-content-between w-100">
                  <div className="d-flex flex-column py-1">
                    <Text style={{ fontSize: "12px", fontWeight: "600" }}>
                      {notif.title}
                    </Text>
                    <Text style={{ fontSize: "12px", fontWeight: "400" }}>
                      {moment(notif.send_time_date).format(
                        "MMMM DD YYYY - hh:mm:ss"
                      )}
                    </Text>
                    <Text style={{ fontSize: "12px", fontWeight: "400" }}>
                      {notif.sender}
                    </Text>
                  </div>
                  <div className="d-flex flex-column justify-content-between">
                    <img
                      onClick={() => {
                        handleDeleteNotif(notif._id, true);
                      }}
                      src={closeIcon}
                      alt="close_icon"
                      style={{ cursor: "pointer" }}
                    />
                    <Tooltip
                      title="Cliquez pour désactiver"
                      placement="topRight"
                    >
                      <img
                        onClick={() => {
                          handleDisableNotif(notif._id, true);
                        }}
                        src={eyeHiddenIcon}
                        alt="hidden_icon"
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="rounded d-flex mt-2"
                style={{
                  backgroundColor: "#F0FAFD",
                  paddingRight: "10px",
                  paddingBottom: "7px",
                }}
              >
                <div className="px-2 py-1">
                  <span style={{ fontSize: "16px" }}>
                    <FontAwesomeIcon
                      icon={faBell}
                      style={{ color: "#2c5397" }}
                    />
                  </span>
                </div>
                <div className="d-flex justify-content-between w-100">
                  <div className="d-flex flex-column py-1">
                    <Text
                      style={{
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "#90A9CB",
                      }}
                    >
                      {notif.title}
                    </Text>
                    <Text
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#90A9CB",
                      }}
                    >
                      {moment(notif.send_time_date).format(
                        "MMMM DD YYYY - hh:mm:ss"
                      )}
                    </Text>
                    <Text
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#90A9CB",
                      }}
                    >
                      {notif.sender}
                    </Text>
                  </div>
                  <div className="d-flex flex-column justify-content-between">
                    <img
                      onClick={() => {
                        handleDeleteNotif(notif._id, true);
                      }}
                      src={closeIcon}
                      alt="close_icon"
                      style={{ cursor: "pointer" }}
                    />

                    <img src={eyeIcon} alt="hidden_icon" />
                  </div>
                </div>
              </div>
            );
          })}
        </Drawer>
        <Content
          className="container h-100 pb-4 pt-4"
          style={{
            overflowY: "scroll",
          }}
        >
          <Outlet />
          <ThemeEditor />
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutAdmin;
