import {
  Collapse,
  Form,
  Skeleton,
  Switch,
  Typography,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../Utils/axios";
const { Panel } = Collapse;
const OtherActions = () => {
  const [actions, setActions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();

  useEffect(() => {
    async function fetchData() {
      const { data } = await axiosInstance.get("/api/platform/validations");

      setActions(data.data);
      setLoading(false);
      return data;
    }

    fetchData();
  }, []);

  const handleChange = async (action, status) => {
    try {
      const { data } = await axiosInstance.patch(
        "/api/platform/validation?name=" + action,
        { status: status }
      );

      if (data.status === "success") {
        api["success"]({
          message: data.message,
        });
      }
    } catch (error) {
      api["error"]({
        message: error.response.data.message,
      });
    }
  };

  return (
    <div className="py-2">
      {/* <h5 className="mb-3">
        <span className=" ">Autres paramètres </span>
      </h5> */}
      {contextHolder}
      {loading ? (
        <>
          <div className="row">
            <div className="col-lg-4">
              <Skeleton.Input active className="w-100" size="small" />
            </div>
            <div className="col-lg-2">
              <Skeleton.Input active className="w-100" size="small" />
            </div>
          </div>
          <div className="row my-3">
            <div className="col-lg-4">
              <Skeleton.Input active className="w-100" size="small" />
            </div>
            <div className="col-lg-2">
              <Skeleton.Input active className="w-100" size="small" />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <Skeleton.Input active className="w-100" size="small" />
            </div>
            <div className="col-lg-2">
              <Skeleton.Input active className="w-100" size="small" />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-4">
              <Skeleton.Input active className="w-100" size="small" />
            </div>
            <div className="col-lg-2">
              <Skeleton.Input active className="w-100" size="small" />
            </div>
          </div>
        </>
      ) : (
        <div className="row">
          <Form
            form={form}
            layout="horizontal"
            className="col-lg-6 col-md-12 col-sm-12"
          >
            <Form.Item
              label={
                <Typography.Text strong>
                  Valider le reporting quotidien
                </Typography.Text>
              }
            >
              <Switch
                defaultChecked={
                  actions?.find((elem) => elem.name === "daily-validation")
                    ?.status
                }
                onChange={(checked) => {
                  handleChange("daily-validation", checked);
                }}
                checkedChildren="Activé"
                unCheckedChildren="Desactivé"
              />
            </Form.Item>
            <Form.Item
              label={
                <Typography.Text strong>
                  Valider le reporting du dernier jour
                </Typography.Text>
              }
            >
              <Switch
                defaultChecked={
                  actions?.find((elem) => elem.name === "validation-d-1")
                    ?.status
                }
                onChange={(checked) => {
                  handleChange("validation-d-1", checked);
                }}
                checkedChildren="Activé"
                unCheckedChildren="Desactivé"
              />
            </Form.Item>
            <Form.Item
              label={
                <Typography.Text strong>
                  Changer la disponibilité automatiquement
                </Typography.Text>
              }
            >
              <Switch
                defaultChecked={
                  actions?.find((elem) => elem.name === "validation-d-2")
                    ?.status
                }
                onChange={(checked) => {
                  handleChange("validation-d-2", checked);
                }}
                checkedChildren="Activé"
                unCheckedChildren="Desactivé"
              />
            </Form.Item>
            <Form.Item
              label={
                <Typography.Text strong>
                  Traitement des demandes de congé
                </Typography.Text>
              }
            >
              <Switch
                onChange={(checked) => {
                  handleChange("requests-daily-validation", checked);
                }}
                defaultChecked={
                  actions?.find(
                    (elem) => elem.name === "requests-daily-validation"
                  )?.status
                }
                checkedChildren="Activé"
                unCheckedChildren="Desactivé"
              />
            </Form.Item>
          </Form>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <h6 className="fw-bold">Descriptifs :</h6>
            <Collapse>
              <Panel header={"Valider le reporting quotidien"} key="1">
                <Typography.Paragraph>
                  En tant qu'utilisateur régulier d'activeCollab, vous
                  bénéficiez d'une fonctionnalité très pratique qui vous aide à
                  rester productif et à gérer votre emploi du temps de manière
                  efficace.
                  <br /> Du lundi au vendredi, N-Dev HaRmonie récupère
                  automatiquement l'ensemble de vos enregistrements quotidiens
                  dans activeCollab, si, pour une journée donnée, vous avez
                  enregistré moins de 4 heures de travail, activeCollab vous
                  envoie un rappel par e-mail pour vous inciter à consacrer plus
                  de temps à vos activités professionnelles
                </Typography.Paragraph>
              </Panel>
              <Panel header={"Valider le reporting du dernier jour"} key="2">
                <Typography.Paragraph>
                  Du lundi au vendredi, N-Dev HaRmonie récupère automatiquement
                  tous les enregistrements que vous avez effectués la veille
                  dans activeCollab.
                  <br />
                  Si, pour une journée donnée, vous avez enregistré moins de 4
                  heures de travail, N-Dev HaRmonie vous envoie un rappel par
                  e-mail pour vous inciter à dispenser plus de temps à vos
                  activités professionnelles.
                </Typography.Paragraph>
              </Panel>
              <Panel
                header={"Changer la disponibilité automatiquement"}
                key="3"
              >
                <Typography.Paragraph>
                  Du lundi au vendredi, N-Dev HaRmonie récupère automatiquement
                  tous les enregistrements que vous avez effectués il y a 2
                  jours dans activeCollab.
                  <br /> Si, pour un jour donné, vous avez enregistré moins de 2
                  heures de travail, N-Dev Harmonie déclare le jour donné comme
                  une absence déclarative et vous envoie un e-mail de rappel
                  pour vous inciter à consacrer plus de temps à vos activités
                  professionnelles.
                </Typography.Paragraph>
              </Panel>
              <Panel header={"Traitement des demandes de congé"} key="4">
                <Typography.Paragraph>
                  Tous les jours à 23h55 , N-dev HaRmonie va chercher toutes les
                  demandes de congés non encore traitées avec une date de début
                  égale à demain et les rejette et envoie un mail pour le
                  rappeler au collaborateur
                </Typography.Paragraph>
              </Panel>
            </Collapse>
          </div>
        </div>
      )}
    </div>
  );
};

export default OtherActions;
