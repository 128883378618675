import { useState, useRef, useEffect, useContext } from "react";
import {
  Button,
  DatePicker,
  Table,
  Typography,
  message,
  Input,
  Space,
  Tag,
  Tooltip,
} from "antd";

import * as XLSX from "xlsx";

import Highlighter from "react-highlight-words";

import { SearchOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faWrench } from "@fortawesome/free-solid-svg-icons";

import moment from "moment";
import axios from "../../../Utils/axios";
import GlobalContext from "../../../Context/GlobalContext";

const { Text } = Typography;

const LeavesList = () => {
  const { role } = useContext(GlobalContext);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [loading, setLoading] = useState(false);
  const searchInput = useRef(null);

  useEffect(() => {
    message.info("Sélectionner un mois");
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleExportToXLSX = () => {
    const data = monthlyRecords.map((record) => {
      return {
        Matricule: record.matricule,
        "Nom et Prénom": record.fullname,
        "Congé special": record.special,
        "Congé maladie": record.sickness,
        "Congé sans solde": record.sansSold,
        "Congé annuel": record.yearly,
        Absence: record.unjustified,
        "Absence Reporting": record.missingReport,
        "Jours fériés": record.holidays,
        "Nbr total des jours non travaillés": record.totalLeaves,
        "Jours travaillés": record.workedDays,
        "Nbr autorisation": record.authNumber,
        "Durée totale d'autorisation (H)": record.authHours,
        "Solde de congé actuel": record.oldSold,
        "Solde cumulé": record.sold,
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(data);

    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(
      workbook,
      `Register mensuel ${moment().format("DD/MM/YYYY HH-mm")}.xlsx`
    );
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Rechercher ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            // style={{
            //   width: 90,
            // }}
          >
            Rechercher
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Réinitialiser
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Employé",
      dataIndex: "fullname",
      ...getColumnSearchProps("fullname", "Nom employé"),
      render: (_, record) => (
        <Text strong>
          {record.fullname} <Text mark>#{record.matricule}</Text>
        </Text>
      ),
    },
    {
      title: "Jours non travaillés",
      children: [
        {
          title: "Jours fériés",
          dataIndex: "holidays",
          render: (text) => (
            <Text>
              <Tag color="cyan">
                <span className="fw-bold">{text}</span> Jour(s)
              </Tag>
            </Text>
          ),
        },
        {
          title: "Congé Special",
          dataIndex: "special",
          sorter: (a, b) => a.special - b.special,
          render: (_, record) => (
            <>
              {record.special === 0 && (
                <Tag color="green">
                  <span className="fw-bold">{record.special}</span> Jour(s)
                </Tag>
              )}
              {record.special > 0 && record.special <= 2 && (
                <Tag color="gold">
                  <span className="fw-bold">{record.special}</span> Jour(s)
                </Tag>
              )}
              {record.special >= 3 && (
                <Tag color="red">
                  <span className="fw-bold">{record.special}</span> Jour(s)
                </Tag>
              )}
            </>
          ),
        },
        {
          title: "Congé Annuel",
          dataIndex: "yearly",
          sorter: (a, b) => a.yearly - b.yearly,
          render: (text) => (
            <Tag color="cyan">
              <span className="fw-bold">{text}</span> Jour(s)
            </Tag>
          ),
        },
        {
          title: "Congé sans solde",
          dataIndex: "sansSold",
          sorter: (a, b) => a.yearly - b.yearly,
          render: (text) => (
            <Tag color="cyan">
              <span className="fw-bold">{text}</span> Jour(s)
            </Tag>
          ),
        },
        {
          title: "Congé Maladie",
          dataIndex: "sickness",
          sorter: (a, b) => a.sickness - b.sickness,
          render: (_, record) => (
            <>
              {record.sickness === 0 && (
                <Tag color="green">
                  <span className="fw-bold">{record.sickness}</span> Jour(s)
                </Tag>
              )}
              {record.sickness > 0 && record.sickness <= 2 && (
                <Tag color="gold">
                  <span className="fw-bold">{record.sickness}</span> Jour(s)
                </Tag>
              )}
              {record.sickness >= 3 && (
                <Tag color="red">
                  <span className="fw-bold">{record.sickness}</span> Jour(s)
                </Tag>
              )}
            </>
          ),
        },
        {
          title: "Absence",
          dataIndex: "unjustified",
          sorter: (a, b) => a.unjustified - b.unjustified,
          render: (_, record) => (
            <>
              {record.unjustified === 0 && (
                <Tag color="green">
                  <span className="fw-bold">{record.unjustified}</span> Jour(s)
                </Tag>
              )}
              {record.unjustified > 0 && record.unjustified <= 2 && (
                <Tag color="gold">
                  <span className="fw-bold">{record.unjustified}</span> Jour(s)
                </Tag>
              )}
              {record.unjustified >= 3 && (
                <Tag color="red">
                  <span className="fw-bold">{record.unjustified}</span> Jour(s)
                </Tag>
              )}
            </>
          ),
        },
        {
          title: "Total",
          dataIndex: "totalLeaves",
          sorter: (a, b) => a.totalLeaves - b.totalLeaves,
          render: (_, record) => (
            <>
              {record.totalLeaves === 0 && (
                <Tag color="green">
                  <span className="fw-bold">{record.totalLeaves}</span> Jour(s)
                </Tag>
              )}
              {record.totalLeaves > 0 && record.totalLeaves <= 3 && (
                <Tag color="gold">
                  <span className="fw-bold">{record.totalLeaves}</span> Jour(s)
                </Tag>
              )}
              {record.totalLeaves > 3 && (
                <Tag color="red">
                  <span className="fw-bold">{record.totalLeaves}</span> Jour(s)
                </Tag>
              )}
            </>
          ),
        },
      ],
    },
    {
      title: <Text style={{ top: "0" }}>Jours travaillés</Text>,
      dataIndex: "workedDays",
      sorter: (a, b) => a.workedDays - b.workedDays,
      render: (_, record) => (
        <>
          {record.workedDays >= 16 && record.workedDays && (
            <Tag color="green">
              <span className="fw-bold">{record.workedDays}</span> Jour(s)
            </Tag>
          )}
          {record.workedDays < 16 && record.workedDays > 8 && (
            <Tag color="gold">
              <span className="fw-bold">{record.workedDays}</span> Jour(s)
            </Tag>
          )}
          {record.workedDays <= 8 && record.workedDays >= 0 && (
            <Tag color="red">
              <span className="fw-bold">{record.workedDays}</span> Jour(s)
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "Solde",
      dataIndex: "oldSold",
      sorter: (a, b) => a.oldSold - b.oldSold,
    },
    {
      title: "Solde cumulé",
      // children: [
      //   {
      //     title: "Valeur Exact",
      //     dataIndex: "exactValue",
      //     sorter: (a, b) => a.exactValue - b.exactValue,
      //   },
      //   {
      //     title: "Valeur arrondi",
      //     dataIndex: "sold",
      //     sorter: (a, b) => a.sold - b.sold,
      //   },
      // ],
      dataIndex: "sold",
      sorter: (a, b) => a.sold - b.sold,
    },
  ];

  const [monthlyRecords, setMonthlyRecords] = useState([]);
  const [RecordDate, setRecordDate] = useState("");

  const onChange = async (date, dateString) => {
    setLoading(true);
    setRecordDate(dateString);
    if (!date) {
      setLoading(false);
      message.info("Aucune date n'est sélectionnée");
    }

    const { data } = await axios.get(
      "/api/leaves/record/monthly/" + dateString
    );
    setMonthlyRecords(data);
    setLoading(false);
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("month");
  };

  const UpdateSold = async () => {
    if (RecordDate === "") {
      return message.error("Aucune date n'est sélectionnée");
    }
    try {
      const { data } = await axios.patch(
        "/api/employees/monthlyUpdate/" + RecordDate,
        monthlyRecords
      );
      if (data) {
        message.info("Sold mensuelles ont été mises à jour");
      }
    } catch (error) {
      console.log(error.response);
      message.error(error.response.data.message);
    }
  };

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: "Type",
        dataIndex: "_typeLeave",
      },
      {
        title: "Répondu par",
        dataIndex: "_answered_by",
        render: (_, requestedLeaves) => (
          <>
            {requestedLeaves.status === "Pending" ? (
              <Tag color={"orange"}>En attente</Tag>
            ) : requestedLeaves._answered_by ? (
              <Tooltip
                title={
                  "Répondu à " +
                  moment(requestedLeaves._answered_at).format(
                    "DD/MM/YYYY HH:mm"
                  )
                }
              >
                <Text> {requestedLeaves._answered_by} </Text>
              </Tooltip>
            ) : (
              <Text>N/A</Text>
            )}
          </>
        ),
      },
      {
        title: "Date début",
        dataIndex: "BeginingDate",
        sorter: (a, b) => a.BeginingDate - b.BeginingDate,
        render: (text) => <Text>{moment(text).format("DD/MM/YYYY")}</Text>,
      },
      {
        title: "Date fin",
        dataIndex: "EndingDate",
        sorter: (a, b) => a.EndingDate - b.EndingDate,
        render: (text) => <Text>{moment(text).format("DD/MM/YYYY")}</Text>,
      },
      {
        title: "Durée",
        render: (_, requestedLeaves) => (
          <Text>
            {requestedLeaves._typeLeave === "Autorisation"
              ? requestedLeaves._duration
              : !requestedLeaves._is_half_day
              ? requestedLeaves.DaysNumber + " Jour(s)"
              : requestedLeaves._is_half_day}
          </Text>
        ),
      },
      {
        title: "Statut",
        dataIndex: "status",
        render: (_, record) => (
          <>
            {record.status === "Pending" && (
              <Tag color={"orange"}>En attente</Tag>
            )}
            {record.status === "Accepted" && (
              <Tag color={"green"}>Approuvée</Tag>
            )}
            {record.status === "Validating" && (
              <Tag color={"cyan"}>En attente d'approbation</Tag>
            )}
            {record.status === "Rejected" && <Tag color={"red"}>Rejeté</Tag>}
            {record.status === "Canceled" && (
              <Tag color={"geekblue"}>Annuler</Tag>
            )}
            {record.status === "Unjustified" && (
              <Tag color={"gold"}>Non encore justifié</Tag>
            )}
            {record.status === "Failed" && (
              <Tooltip title="Considérée comme absence non justifiée">
                <Tag>échue</Tag>
              </Tooltip>
            )}
          </>
        ),
      },
    ];

    return (
      <Table
        rowKey={record.key}
        columns={columns}
        dataSource={record.monthlyLeaves}
        pagination={false}
        size="small"
      />
    );
    // }
  };

  return (
    <div className="bg-white p-4 rounded-4 shadow-sm ">
      <div className="mb-3">
        <h4 className="fw-bold">Registre mensuel</h4>
      </div>
      <label>Sélectionner un mois</label>
      <br />
      <div className="row flex-column  flex-lg-row justify-content-between">
        <div className="col-lg-4 col-md-7 col-sm-12 ">
          <DatePicker
            disabledDate={disabledDate}
            onChange={onChange}
            style={{ width: "100%", marginRight: "5px" }}
            picker="month"
            className="mb-2"
          />
        </div>
        <div className="col-lg-5 col-md-5 col-sm-12 d-flex flex-column flex-md-row flex-lg-row justify-content-sm-start justify-content-lg-end">
          {(role === "RH" || role === "SUPER_ADMIN") && (
            <Button
              onClick={UpdateSold}
              type="primary"
              className="mb-2"
              style={{ marginRight: "5px" }}
            >
              <FontAwesomeIcon icon={faWrench} style={{ marginRight: "5px" }} />{" "}
              Incrémenter les soldes de congé
            </Button>
          )}

          <Button
            className="mb-2"
            style={{ marginRight: "5px" }}
            onClick={handleExportToXLSX}
            disabled={!(monthlyRecords.length > 0)}
          >
            <FontAwesomeIcon
              icon={faFileExport}
              style={{ marginRight: "5px" }}
            />{" "}
            Exporter
          </Button>
        </div>
      </div>
      <Table
        className="shadow-sm"
        bordered={true}
        loading={loading}
        columns={columns}
        expandable={{
          expandedRowRender,
        }}
        scroll={{ x: "max-content" }}
        dataSource={monthlyRecords}
      />
    </div>
  );
};

export default LeavesList;
