import { DatePicker, Spin, Tag, Timeline } from "antd";
import moment from "moment";
import axios from "../../../Utils/axios";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const Logs = () => {
  const [loading, setLoading] = useState(true);
  const [archives, setArchives] = useState([]);
  const [dateRange, setRange] = useState("");

  const onChange = (date) => {
    if (date) {
      const startDate = dayjs(date[0]).format("DD-MM-YYYY");
      const endDate = dayjs(date[1]).format("DD-MM-YYYY");
      setRange({ startDate: startDate, endDate: endDate });
    } else {
      setRange("");
    }
  };

  useEffect(() => {
    async function fetchLogs() {
      setLoading(true);
      const { data } = await axios.get(
        `/api/archive/${
          dateRange
            ? "?startDate=" +
              dateRange.startDate +
              "&endDate=" +
              dateRange.endDate
            : ""
        }`
      );
      if (data.status === "success") {
        setArchives(data.Archives);
        setLoading(false);
      }
      return data;
    }

    fetchLogs();
  }, [dateRange]);
  return (
    <div className="bg-white p-4 rounded shadow-sm ">
      <div className="pb-2 mb-3 d-flex justify-content-between align-items-center flex-column flex-lg-row flex-md-row">
        <h4 className="fw-bold">Historiques</h4>
        <div className="col-lg-6 col-md-7 col-sm-12 ">
          <RangePicker
            onChange={onChange}
            className="w-100"
            placement="bottomLeft"
          />
        </div>
      </div>
      <div>
        {loading ? (
          <div className="d-flex mt-5 justify-content-center">
            <Spin size="large" />
          </div>
        ) : (
          <div
            style={{ overflowY: "scroll", height: "100vh", paddingTop: "15px" }}
          >
            <Timeline mode="">
              {archives.map((Archive, index) => {
                return (
                  <Timeline.Item
                    color={
                      Archive.By === "Administrateur" ? "#00CCFF" : "green"
                    }
                    key={index}
                  >
                    <p style={{ fontSize: "16px" }}>
                      {Archive.Action} en{" "}
                      <Tag
                        color={
                          Archive.By === "Administrateur" ? "geekblue" : "green"
                        }
                        style={{ fontSize: "12px" }}
                      >
                        <span>
                          {moment(Number(Archive.at)).format(
                            "DD-MM-YYYY HH:mm"
                          )}
                        </span>
                      </Tag>{" "}
                      <br />
                      Acteur:{" "}
                      <span style={{ fontWeight: "700" }}> {Archive.By}</span>
                    </p>
                  </Timeline.Item>
                );
              })}
            </Timeline>
          </div>
        )}
      </div>
    </div>
  );
};

export default Logs;
