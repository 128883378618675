import React, { useState } from "react";

import {
  Button,
  Form,
  Input,
  message,
  Radio,
  Select,
  Spin,
  Typography,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import axios from "../../Utils/axios";
import sideImage from "../../Assets/Devices-rafiki.svg";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

const typeOptions = [
  {
    label: "Nouvelle",
    value: "Nouvelle",
  },
  {
    label: "Réparation du matériel existant",
    value: "Réparation du matériel existant",
  },
];

function RequestInfoComponents() {
  const [underCategory, setUnderCategory] = useState("");
  const [typeValue, setTypeValue] = useState("Nouvelle");
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const Navigate = useNavigate();

  const onChangeTypeValue = ({ target: { value } }) => {
    setTypeValue(value);
  };

  const handleSubmit = async (formValues) => {
    setLoading(true);
    try {
      const { data } = await axios.post("/api/request/equipment", formValues);
      message.success(data.message);
      form.resetFields();
      setLoading(false);
    } catch (error) {
      message.error(error.response.data.message);
    }
  };
  return (
    <div className="bg-white p-4 rounded shadow-sm mt-4">
      <div className="mb-3 d-flex justify-content-between align-items-center flex-lg-row flex-md-row flex-column pb-2">
        <h4 className="fw-bold">Demande de matériel informatique</h4>

        <Button type="primary" onClick={() => Navigate("/self/equipments")}>
          Mes équipements informatiques
        </Button>
      </div>
      <div className="row d-flex flex-column-reverse flex-lg-row flex-md-row ">
        <div className="col-lg-6 col-md-6 col-sm-12 h-100">
          <div>
            <Typography.Paragraph
              ellipsis={{
                rows: 2,
                expandable: true,
                symbol: "Plus",
              }}
              className="pb-3"
            >
              Bienvenue dans la section dédiée à la gestion du matériel
              informatique. Vous pouvez ici effectuer des demandes pour du
              matériel informatique, suivre le statut de vos demandes en cours
              et consulter l'historique de vos demandes précédentes. Si vous
              avez besoin de nouveau matériel pour améliorer votre productivité
              au travail, n'hésitez pas à nous en faire la demande via cette
              plateforme.
            </Typography.Paragraph>
            <Form
              form={form}
              disabled={loading}
              labelCol={{
                xs: { span: 24 },
                sm: { span: 24 },
                md: { span: 24 },
              }}
              wrapperCol={{
                xs: { span: 24 },
                sm: { span: 24 },
                md: { span: 24 },
                lg: { span: 24 },
              }}
              layout="vertical"
              size="middle"
              onFinish={handleSubmit}
            >
              <Form.Item name={"type"} label="Type">
                <Radio.Group
                  defaultValue={"Nouvelle"}
                  options={typeOptions}
                  onChange={onChangeTypeValue}
                  value={typeValue}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
              <Form.Item
                label={<Typography.Text>Categorie</Typography.Text>}
                name="_categorie"
                rules={[
                  {
                    required: true,
                    message: "Veuillez sélectionner une catégorie",
                  },
                ]}
              >
                <Select
                  placeholder="Choisir une catégorie"
                  onChange={(e) => {
                    setCategory(e);
                  }}
                  value={category}
                >
                  {typeValue === "Réparation du matériel existant" && (
                    <Option value="Réparation du PC">Réparation du PC</Option>
                  )}
                  <Option
                    value="Un nouveau PC"
                    disabled={typeValue === "Réparation du matériel existant"}
                  >
                    Un nouveau PC
                  </Option>
                  <Option
                    value="Une souris"
                    disabled={typeValue === "Réparation du matériel existant"}
                  >
                    Une souris
                  </Option>
                  <Option
                    value="Un écran"
                    disabled={typeValue === "Réparation du matériel existant"}
                  >
                    Un écran
                  </Option>
                  <Option
                    value="Un clavier"
                    disabled={typeValue === "Réparation du matériel existant"}
                  >
                    Un clavier
                  </Option>
                  <Option
                    value="Un téléphone portable"
                    disabled={typeValue === "Réparation du matériel existant"}
                  >
                    Un téléphone portable
                  </Option>
                  <Option
                    value="Une ligne téléphonique"
                    disabled={typeValue === "Réparation du matériel existant"}
                  >
                    Une ligne téléphonique
                  </Option>
                  <Option
                    value="Un casque"
                    disabled={typeValue === "Réparation du matériel existant"}
                  >
                    Un casque
                  </Option>
                  <Option value="Autres (à Préciser)">
                    Autres (à Préciser)
                  </Option>
                </Select>
              </Form.Item>
              {category === "Réparation du PC" && (
                <Form.Item
                  label={<Typography.Text>Sous categorie</Typography.Text>}
                  name="_sous_categorie"
                  rules={[
                    {
                      required: true,
                      message: "Veuillez sélectionner une sous catégories ",
                    },
                  ]}
                >
                  <Select
                    placeholder="Choisir une sous catégorie"
                    onChange={(e) => {
                      setUnderCategory(e);
                    }}
                    value={underCategory}
                  >
                    <Option value="La mémoire RAM">La mémoire RAM</Option>
                    <Option value="Le disque dur">Le disque dur</Option>
                    <Option value="L'alimentation">L'alimentation</Option>
                    <Option value="L'écran">L'écran</Option>
                    <Option value="Système d'exploitation">
                      Système d'exploitation
                    </Option>
                    <Option value="Autres (à Préciser)">
                      Autres (à Préciser)
                    </Option>
                  </Select>
                </Form.Item>
              )}
              {(category === "Autres (à Préciser)" ||
                underCategory === "Autres (à Préciser)") && (
                <Form.Item
                  label={<Typography.Text>Autres (à Préciser)</Typography.Text>}
                  name="_other"
                  rules={[
                    { required: true, message: "Veuillez remplir le champ" },
                  ]}
                >
                  <Input.TextArea placeholder="Autres (à Préciser)" />
                </Form.Item>
              )}
              <Form.Item
                label={<Typography.Text>Motif</Typography.Text>}
                name="_motif"
                rules={[
                  { required: true, message: "Veuillez entrer un motif" },
                ]}
              >
                <Input.TextArea placeholder="Motif" />
              </Form.Item>

              <Form.Item>
                <div className="d-flex">
                  <Button disabled={loading} htmlType="submit" type="primary">
                    Soumettre !
                  </Button>
                  {loading && (
                    <Spin
                      style={{ marginLeft: "10px" }}
                      size="large"
                      indicator={antIcon}
                    />
                  )}
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center h-100">
          <img src={sideImage} alt="side cover" />
        </div>
      </div>
    </div>
  );
}

export default RequestInfoComponents;
