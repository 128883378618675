import { useEffect, useState } from "react";

import { Spin, Button, Modal } from "antd";

import axios from "../../Utils/axios";

import { useNavigate, useParams } from "react-router-dom";

import Completed from "../../Assets/Completed-pana.svg";

import "./AcceptePage.css";

const Acceptepage = () => {
  const [loading, setLoading] = useState(true);
  const [answer, setAnswer] = useState(false);

  const Navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    async function UpdateLeave() {
      try {
        const { data } = await axios.patch("/api/leaves/options/" + id, {
          status: "Accepted",
        });

        if (data.status !== "success") {
          Modal.info({
            title: "La demande a déjà reçu une réponse ou a été annulée",
          });
          setAnswer(true);
        }

        setLoading(false);
        return data;
      } catch (error) {
        Modal.error({
          title: "Vérifier votre URL!",
          onOk: () => {
            Navigate("/error");
          },
        });
      }
    }

    UpdateLeave();
    // eslint-disable-next-line
  }, []);

  const handleReturn = () => {
    Navigate("/");
  };

  return (
    <div className="min-vh-100 w-100 errorPageBackground d-flex align-items-center">
      <div className="container pt-4 d-flex flex-column align-items-center">
        {loading ? (
          <>
            <div className="d-flex justify-content-center">
              <Spin size="large" />
            </div>
            <h5
              className="fw-bold text-center mt-3"
              style={{ letterSpacing: "2px", color: "#001529" }}
            >
              Cela peut prendre un moment...
            </h5>
          </>
        ) : (
          <>
            {!answer ? (
              <div className="col-lg-6 col-md-8 col-sm-12 d-flex flex-column align-items-center">
                <h5
                  className="fw-bold text-center"
                  style={{ letterSpacing: "2px", color: "#001529" }}
                >
                  La demande a été acceptée avec succès !
                </h5>

                <img src={Completed} style={{ width: "85%" }} alt="Completed" />

                <h6
                  className="fw-bold text-center"
                  style={{ letterSpacing: "2px", color: "#001529" }}
                >
                  Le processus est terminé, vous pouvez quitter cette page ou
                  revenir à la page d'accueil.
                </h6>

                <Button size="large" onClick={handleReturn}>
                  Accueil
                </Button>
              </div>
            ) : (
              <div className="col-lg-6 col-md-8 col-sm-12 d-flex flex-column align-items-center">
                <p style={{ fontSize: "18px" }}>
                  La demande a déjà reçu une réponse ou a été annulée
                </p>
                <Button size="large" onClick={handleReturn}>
                  Accueil
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Acceptepage;
