import { Avatar, Button, List, Skeleton, Typography } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import axios from "../../../Utils/axios";

const SubordinatesList = () => {
  const [initLoading, setInitLoading] = useState(true);

  /* eslint-disable-next-line */
  const [data, setData] = useState([]);
  const [list, setList] = useState([]);

  const { matricule } = useParams();
  const Navigate = useNavigate();

  useEffect(() => {
    async function fetchSubordinates() {
      const { data } = await axios.get(
        "/api/employees/subordinates/" + matricule
      );
      setData(data);
      setList(data);
      setInitLoading(false);
      return data;
    }

    fetchSubordinates();
  }, [matricule]);
  return (
    <List
      className="demo-loadmore-list"
      loading={initLoading}
      itemLayout="horizontal"
      dataSource={list}
      renderItem={(item) => (
        <List.Item
          actions={[
            <Button
              type="primary"
              size="small"
              onClick={() => {
                Navigate("/administration/employees/" + item.matricule);
              }}
            >
              Profil
            </Button>,
          ]}
        >
          <Skeleton avatar title={false} loading={item.loading} active>
            <List.Item.Meta
              avatar={<Avatar src={item.imgProfile} />}
              title={<Typography.Text strong>{item.fullName}</Typography.Text>}
              description={item.Poste}
            />
          </Skeleton>
        </List.Item>
      )}
    />
  );
};

export default SubordinatesList;
