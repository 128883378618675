import { Button } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import MaintainanceBG from "../../Assets/ComputerTroubleShot.svg";

const MaintainancePage = () => {
  useEffect(() => {
    localStorage.removeItem("access_token");
  });

  const Navigate = useNavigate();

  const handleNavigate = () => {
    Navigate("/login");
  };
  return (
    <div className="vh-100 px-3 errorPageBackground">
      <div className="d-flex flex-column mx-auto justify-content-center h-100 align-items-center col-lg-5 col-md-6 col-sm-12">
        <img src={MaintainanceBG} className="w-75" alt="Site en maintanance" />
        <p className="fw-bold text-center text-white lead"> Hey les gars!</p>
        <p className="fw-bold text-white">
          <br /> On s'excuse pour le désagrément, mais notre plateforme sera
          temporairement hors ligne pour une petite cure de rajeunissement. On
          travaille dur pour vous fournir un service encore plus cool et
          performant, alors patientez un peu pour le retour en ligne.
          <br />
          <br /> Nous reviendrons bientôt en ligne en grande forme et prêts à
          vous offrir une expérience encore meilleure. Merci pour votre
          compréhension et votre patience!
        </p>
        <Button size="large" onClick={handleNavigate}>
          Se connecter
        </Button>
      </div>
    </div>
  );
};

export default MaintainancePage;
