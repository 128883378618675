import { Card, Descriptions, Image, Modal, Typography } from "antd";
import { useState, useEffect } from "react";
import ContentLoader from "react-content-loader";
import { ContactsOutlined } from "@ant-design/icons";
import AvatarProfile from "../../Assets/avatar-svgrepo-com.svg";
import axios from "../../Utils/axios";
const { Meta } = Card;

const EmployeeCard = ({ employee }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const capitalizeFirstLetter = (str) => {
    const capitalized = str[0].toUpperCase() + str.slice(1).toLowerCase();
    return capitalized;
  };


  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(
        "/api/employees/byId/" + employee.matricule
      );
      setData(data.employee);
      
      setLoading(false);
    }
    fetchData();
  }, []);

  const info = () => {
    Modal.info({
      title: data.fullName,
      icon: <ContactsOutlined />,
      content: (
        <div>
          <Descriptions layout="horizontal" column={1} className="pt-3">
            <Descriptions.Item
              label={<Typography.Text strong>Email</Typography.Text>}
            >
              <Typography.Text copyable>{data.email}</Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item
              label={<Typography.Text strong>Téléphone</Typography.Text>}
            >
              <Typography.Text copyable>{data.phoneNum}</Typography.Text>
            </Descriptions.Item>
          </Descriptions>
        </div>
      ),
      onOk() {},
    });
  };
  return (
    <Card
      loading={loading}
      hoverable
      style={{
        width: "100%",
      }}
      className="responsive-card"
      onClick={info}
      cover={
        <div
          className="d-flex justify-content-center align-items-center pt-4 d-none d-lg-flex d-md-flex "
          style={{
            width: "100%",
            height: "100px",
          }}
        >
          {loading && (
            <ContentLoader
              speed={2}
              style={{
                borderRadius: "50%",
                maxWidth: "100px",
                maxHeight: "100px",
              }}
              viewBox="0 0 200 200"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="-72" y="-87" rx="2" ry="2" width="617" height="617" />
            </ContentLoader>
          )}
          {!loading && !data?.imgProfile && (
            <Image
              src={AvatarProfile}
              preview={false}
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
              }}
            />
          )}{" "}
          {!loading && data?.imgProfile && (
            <Image
              src={data?.imgProfile}
              preview={false}
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
              }}
            />
          )}
        </div>
      }
    >
      <Meta
        loading={loading}
        avatar={
          <div
            className="d-block d-lg-none d-md-none"
            style={{
              width: "100%",
              height: "100px",
            }}
          >
            {loading && (
              <ContentLoader
                speed={2}
                style={{
                  borderRadius: "50%",
                  maxWidth: "100px",
                  maxHeight: "100px",
                }}
                viewBox="0 0 200 200"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="-72" y="-87" rx="2" ry="2" width="617" height="617" />
              </ContentLoader>
            )}
            {!loading && !data?.imgProfile && (
              <Image
                src={AvatarProfile}
                preview={false}
                style={{
                  width: "75px",
                  height: "75px",
                  borderRadius: "50%",
                }}
              />
            )}{" "}
            {!loading && data?.imgProfile && (
              <Image
                src={data?.imgProfile}
                preview={false}
                style={{
                  width: "75px",
                  height: "75px",
                  borderRadius: "50%",
                }}
              />
            )}
          </div>
        }
        description={
          <div
            style={{
              fontSize: "12px",
            }}
          >
            <Typography.Text strong>
              {data?.fullName &&
                data?.fullName.split(" ").map((str) => {
                  return capitalizeFirstLetter(str) + " ";
                })}
            </Typography.Text>
            <br />
            <Typography.Text>
              {data?.Poste &&
                data?.Poste.split(" ").map((str) => {
                  return capitalizeFirstLetter(str) + " ";
                })}
            </Typography.Text>
          </div>
        }
      />
    </Card>
  );
};

export default EmployeeCard;
