import React, { useContext } from "react";
import voteFilled from "./VoteDownFilled.svg";
import axiosInstance from "../../Utils/axios";
import GlobalContext from "../../Context/GlobalContext";

const VoteDown = ({ post, setPost }) => {
  const { profile } = useContext(GlobalContext);
  const handleDislike = async () => {
    try {
      const { data } = await axiosInstance.patch(
        "/api/suggestion/dislike/" + post._id
      );
      setPost({
        ...post,
        _like: data.like._like,
        _dislike: data.like._dislike,
      });
    } catch (error) {}
  };

  return (
    <div className="vote" onClick={handleDislike}>
      {post?._dislike?.find(
        (elem) => elem._disliked_by === profile.matricule
      ) ? (
        <img src={voteFilled} />
      ) : (
        <svg
          width="18"
          height="20"
          viewBox="0 0 18 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.653667 9.51615C0.278667 10.0117 0.0710773 10.6188 0.0710773 11.2505C0.0710773 12.2528 0.631346 13.2014 1.53313 13.7304C1.76529 13.8666 2.02961 13.9383 2.29876 13.938L7.65143 13.938L7.51751 16.6813C7.48626 17.3443 7.72063 17.9737 8.17599 18.4537C8.39946 18.6902 8.66906 18.8784 8.96814 19.0066C9.26722 19.1349 9.58942 19.2004 9.91483 19.1992C11.0755 19.1992 12.1023 18.4179 12.4104 17.2996L14.3278 10.3577H17.2139C17.609 10.3577 17.9282 10.0385 17.9282 9.64338L17.9282 1.51838C17.9282 1.12329 17.609 0.804098 17.2139 0.804098H3.79206C3.5867 0.804098 3.38581 0.844276 3.20054 0.924635C2.13804 1.37776 1.45278 2.4157 1.45278 3.56749C1.45278 3.84874 1.49295 4.12553 1.57331 4.39338C1.19831 4.88892 0.990721 5.49606 0.990721 6.12776C0.990721 6.40901 1.0309 6.68579 1.11126 6.95365C0.736256 7.44919 0.528667 8.05633 0.528667 8.68803C0.533131 8.96928 0.573309 9.24829 0.653667 9.51615ZM16.3211 2.41124L16.3211 8.75053H14.513V2.41124H16.3211ZM2.11572 10.2907L2.60456 9.8666L2.29429 9.29963C2.19207 9.11287 2.13907 8.90316 2.14028 8.69026C2.14028 8.32195 2.30099 7.97151 2.57777 7.73044L3.06661 7.30633L2.75635 6.73937C2.65413 6.5526 2.60112 6.34289 2.60233 6.12999C2.60233 5.76169 2.76304 5.41124 3.03983 5.17017L3.52867 4.74606L3.2184 4.1791C3.11618 3.99233 3.06318 3.78263 3.06438 3.56972C3.06438 3.06972 3.35903 2.61883 3.81438 2.41347H13.0845L13.0845 8.82195L10.8635 16.8688C10.8062 17.0751 10.6833 17.257 10.5133 17.3871C10.3433 17.5172 10.1356 17.5884 9.92153 17.5898C9.75188 17.5898 9.58447 17.5407 9.45054 17.4403C9.22956 17.2751 9.11126 17.0251 9.12465 16.7595L9.33894 12.3309L2.32108 12.3309C1.92376 12.0876 1.67822 11.6769 1.67822 11.2505C1.67822 10.8822 1.83893 10.534 2.11572 10.2907Z"
            fill="#ACACAC"
          />
        </svg>
      )}
    </div>
  );
};

export default VoteDown;
