import React, { useContext, useEffect, useState } from "react";
import axiosInstance from "../../Utils/axios";
import {
  Avatar,
  Button,
  Dropdown,
  Form,
  Input,
  Menu,
  Skeleton,
  Typography,
  Modal,
  message,
  Space,
} from "antd";
import GlobalContext from "../../Context/GlobalContext";
import dayjs from "dayjs";
import {
  CaretDownOutlined,
  EllipsisOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";

const { confirm } = Modal;
const { TextArea } = Input;

const SuggestionComments = ({
  id,
  maxPages,
  setSuggestion,
  suggestion,
  access,
}) => {
  const { profile } = useContext(GlobalContext);

  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [comments, setComments] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState("");

  const postComment = async (formValues) => {
    try {
      const { data } = await axiosInstance.patch(
        `/api/suggestion/addComment/${id}`,
        formValues
      );

      const temp = {
        ...data.comment,
        _posted_by: profile.fullName,
        imgProfile: profile.imgProfile,
      };

      setSuggestion({
        ...suggestion,
        _comment: [...suggestion._comment, temp],
      });
      setComments([...comments, temp]);
    } catch (error) {}
    form.resetFields();
  };

  const deleteComment = async (id) => {
    try {
      const { data } = await axiosInstance.patch(
        "/api/suggestion/deleteComment/" + id
      );

      const temp = comments.filter((elem) => elem?._id !== id);

      setComments(temp);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };
  const handleCancel = () => {
    form.resetFields();
  };
  const updateComment = async (_id) => {
    if (!editForm.getFieldsValue()._commentContent) {
      return message.error("Merci de valider votre commentaire!");
    }
    const formValues = editForm.getFieldsValue();
    try {
      const { data } = await axiosInstance.patch(
        "/api/suggestion/updateComment/" + _id,
        formValues
      );

      const temp = comments.map((elem) => {
        if (elem._id === _id) {
          elem._commentContent = formValues._commentContent;
        }
        return elem;
      });

      setEdit("");
      setComments(temp);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const showConfirm = (id) => {
    confirm({
      title: "Supprimer le commentaire ?",
      icon: <ExclamationCircleFilled />,
      content: "Souhaitez-vous vraiment supprimer ce commentaire ?",
      okText: "Oui",
      onOk() {
        deleteComment(id);
      },
      onCancel() {
        return null;
      },
    });
  };

  useEffect(() => {
    async function fetchComments() {
      setLoading(true);
      const { data } = await axiosInstance.get(
        `/api/suggestion/comment/${id}?pageNumber=${pageNumber}&pageSize=3`
      );
      setComments([...comments, ...data]);
      setLoading(false);
    }

    fetchComments();
  }, [pageNumber]);

  return (
    <div>
      {!access && (
        <Form form={form} onFinish={postComment} className="border-bottom mb-2">
          <Form.Item
            name="_commentContent"
            rules={[{ required: true, message: "Entrez un commentaire" }]}
          >
            <TextArea
              allowClear
              placeholder="Text..."
              autoSize={{
                minRows: 3,
                maxRows: 3,
              }}
            />
          </Form.Item>
          <Form.Item className="d-flex justify-content-end">
            <Space>
              <Button onClick={handleCancel}>Annuler</Button>
              <Button htmlType="submit" type="primary">
                Publier
              </Button>
            </Space>
          </Form.Item>
        </Form>
      )}
      {comments?.length > 0 && (
        <div>
          {comments.map((elem) => {
            return (
              <div className="container-fluid d-flex flex-column bg-light px-2 rounded-3 py-1 shadow-sm my-3 custem-border">
                <div className="d-flex align-items-center">
                  <Avatar
                    size={{
                      xs: 24,
                      sm: 32,
                      md: 38,
                      lg: 38,
                      xl: 38,
                      xxl: 48,
                    }}
                    src={<img src={elem?.imgProfile} alt="avatar" />}
                  />
                  <Typography.Paragraph className="d-flex justify-content-between w-100 pt-3 px-1">
                    <span className="d-flex justify-content-between w-100 ">
                      <span>{elem?._posted_by}</span>

                      <span className="datePoste">
                        {dayjs(elem?._posted_date_).fromNow()}
                      </span>
                    </span>
                    {elem?._posted_by === profile.fullName ? (
                      <span style={{ marginTop: "3px" }}>
                        {" "}
                        <Dropdown
                          overlay={
                            <Menu
                              items={[
                                {
                                  key: "2",
                                  label: (
                                    <div
                                      onClick={() => {
                                        setEdit(elem?._id);
                                        editForm.setFieldsValue({
                                          _commentContent:
                                            elem?._commentContent,
                                        });
                                      }}
                                    >
                                      <Typography.Text
                                        strong
                                        style={{ cursor: "pointer" }}
                                      >
                                        <FontAwesomeIcon icon={faPen} />{" "}
                                        Modifier
                                      </Typography.Text>
                                    </div>
                                  ),
                                },
                                {
                                  key: "1",
                                  label: (
                                    <div onClick={() => showConfirm(elem?._id)}>
                                      <Typography.Text
                                        strong
                                        style={{ cursor: "pointer" }}
                                      >
                                        <FontAwesomeIcon icon={faTrash} />{" "}
                                        Supprimer
                                      </Typography.Text>
                                    </div>
                                  ),
                                },
                              ]}
                            />
                          }
                          placement="bottomRight"
                          trigger={["click"]}
                        >
                          <EllipsisOutlined
                            className="vote fw-bold"
                            style={{ transform: "rotate(90deg)" }}
                          />
                        </Dropdown>
                      </span>
                    ) : (
                      <span style={{ width: "25px" }}></span>
                    )}
                  </Typography.Paragraph>
                </div>
                <div className="mt-1 w-100">
                  {elem?._id !== edit ? (
                    <Typography.Paragraph
                      ellipsis={{
                        rows: 2,
                        expandable: true,
                        symbol: "Plus",
                      }}
                    >
                      {elem?._commentContent}
                    </Typography.Paragraph>
                  ) : (
                    <Form form={editForm} className="mb-2">
                      <Form.Item
                        name="_commentContent"
                        rules={[
                          { required: true, message: "Entrez un commentaire" },
                        ]}
                      >
                        <TextArea
                          allowClear
                          placeholder="Text..."
                          autoSize={{
                            minRows: 3,
                            maxRows: 3,
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        style={{ marginTop: "-15px" }}
                        className="d-flex justify-content-end"
                      >
                        <Space>
                          <Button
                            size="small"
                            onClick={() => {
                              setEdit("");
                            }}
                          >
                            Annuler
                          </Button>
                          <Button
                            size="small"
                            type="primary"
                            onClick={async () => {
                              await updateComment(elem?._id);
                            }}
                          >
                            Modifier
                          </Button>
                        </Space>
                      </Form.Item>
                    </Form>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
      {loading && (
        <div>
          <Skeleton
            active
            avatar
            paragraph={{
              rows: 2,
            }}
            className="w-100 "
          />
          <Skeleton
            active
            avatar
            paragraph={{
              rows: 2,
            }}
            className="w-100 "
          />
          <Skeleton
            active
            avatar
            paragraph={{
              rows: 2,
            }}
            className="w-100 "
          />
        </div>
      )}
      {pageNumber < maxPages && (
        <div className="d-flex justify-content-end">
          <Button
            shape="round"
            onClick={() => {
              const temp = pageNumber + 1;
              setPageNumber(temp);
            }}
            className="d-flex justify-content-center align-items-center mt-2"
          >
            <CaretDownOutlined />
            Afficher plus
          </Button>
        </div>
      )}
    </div>
  );
};

export default SuggestionComments;
