import React from "react";
import FAQ from "../../Components/FAQ";

const FaqPage = () => {
  return (
    <div className="px-3">
      <FAQ />
    </div>
  );
};

export default FaqPage;
