import { Typography } from "antd";
import { useState, useEffect } from "react";
import EmployeeCard from "../../Components/EmployeeCard";
import axios from "../../Utils/axios";
const Trombinoscope = () => {
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get("/api/employees/ids/list");
      setEmployees(data.employees);
      return data;
    }

    fetchData();
  }, []);

  return (
    <div className="pt-4 px-3">
      <div className="bg-white p-4 rounded-4 shadow-sm  ">
        <div className=" mb-3 ">
          <h4 className="fw-bold">Trombinoscope de l'équipe</h4>
        </div>
        <Typography.Paragraph
          ellipsis={{
            rows: 2,
            expandable: true,
            symbol: "Plus",
          }}
          style={{ whiteSpace: "pre-wrap" }}
          className="pb-3"
        >
          Bienvenue sur la page de trombinoscope de notre équipe ! Cette section
          est dédiée à la présentation de chaque membre de notre équipe, afin
          que vous puissiez mieux nous connaître !
        </Typography.Paragraph>
        <div className="row mt-4">
          {employees?.map((employee) => {
            return (
              <div className="col-lg-2 col-md-4 col-sm-6 my-2">
                <EmployeeCard employee={employee} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Trombinoscope;
