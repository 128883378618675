import React, { useContext, useEffect, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import {
  Button,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
  message,
  Dropdown,
} from "antd";
import dayjs from "dayjs";
import axios from "../../../Utils/axios";
import GlobalContext from "../../../Context/GlobalContext";
import AffectToModal from "../AffectToModal";
import EquipmentHistoryDrawer from "../EquipmentHistoryDrawer";
import EditEquipmentModal from "../EditEquipmentModal";

const EquipmentStock = ({ access }) => {
  const { equipments, setEquipments } = useContext(GlobalContext);
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      title: "Réference",
      dataIndex: "_ref",
      key: "Catégorie",
    },
    {
      title: "Catégorie",
      dataIndex: "_categorie",
      key: "Référence",
    },
    {
      title: "Marque",
      dataIndex: "_brand",
      key: "Marque",
    },
    access
      ? {
          title: "Affectée à",
          render: (record) => (
            <>
              {record._status === "AFFECTED_TO" ? (
                <Tooltip
                  title={
                    "Affecté à " +
                    employees?.find(
                      (elem) => elem.matricule === record._current_holder
                    )?.fullName
                  }
                >
                  <Tag color="blue">
                    {" "}
                    {
                      employees?.find(
                        (elem) => elem.matricule === record._current_holder
                      )?.fullName
                    }
                  </Tag>
                </Tooltip>
              ) : (
                <Typography.Text>
                  {record._status === "AVAILABLE" ? (
                    <Tag color="green">Disponible</Tag>
                  ) : (
                    <Tag color="red">En pane</Tag>
                  )}
                </Typography.Text>
              )}
            </>
          ),
        }
      : {},
    {
      title: "Date d'acquisition",
      key: "date",
      render: (record) => (
        <Typography.Text>
          {dayjs(
            record._time_line[record._time_line.length - 1]._start_date
          ).format("DD/MM/YYYY")}
        </Typography.Text>
      ),
    },
    {
      title: "Numéro du facture",
      dataIndex: "_num_bill",
      render: (text) => (
        <Typography.Text>{text ? text : "N/A"}</Typography.Text>
      ),
    },
    {
      title: "Actions",
      render: (record) => (
        <Space>
          <EditEquipmentModal record={record} />
          <AffectToModal record={record} employees={employees} />
          {access && (
            <Dropdown
              menu={{
                items: [
                  {
                    label: "Autre",
                    key: "1",
                  },
                  {
                    label: (
                      <EquipmentHistoryDrawer timeLine={record._time_line} />
                    ),
                    key: "2",
                  },
                  {
                    label: "Désaffecter",
                    key: "3",
                    danger: true,
                    onClick: async () => {
                      try {
                        const { data } = await axios.patch(
                          "/api/equipments/desaffect/" + record._id
                        );
                        const tmp = equipments.map((elem) => {
                          if (elem._id === record._id) {
                            elem._status = "AVAILABLE";
                            elem._current_holder = "N/A";
                          }
                          return elem;
                        });
                        setEquipments(tmp);
                        message.success(data.message);
                      } catch (error) {
                        message.error(error.response.data.message);
                      }
                    },
                  },
                ],
              }}
            >
              <Button size="small">
                <Space>
                  Autre <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    async function fetchData() {
      axios
        .get("/api/employees/")
        .then(async (res) => {
          setEmployees(res.data.employees);
          const { data } = await axios.get(
            `/api/equipments/${!access ? "?status=AVAILABLE" : ""}`
          );

          setEquipments(data.equipments);
          setLoading(false);
        })
        .catch((err) => {
          message.error(err.response.data.message);
        });
    }

    fetchData();
  }, []);

  return (
    <div className="container bg-white p-4 rounded shadow-sm">
      <div className="mb-3">
        <h5 className="fw-bold">Liste des équipements</h5>
      </div>

      <Table
        dataSource={equipments}
        loading={loading}
        scroll={{ x: "max-content" }}
        columns={columns}
        size="small"
      />
    </div>
  );
};

export default EquipmentStock;
