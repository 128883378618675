import { List } from "antd";
import { useEffect, useState } from "react";

import axios from "../../Utils/axios";
import SubordinateItem from "../SubordinateItem";

const SubordinatesList = () => {
  /* eslint-disable-next-line */
  const [list, setList] = useState([]);

  useEffect(() => {
    async function fetchSubordinates() {
      const { data } = await axios.get("/api/employees/subordinates");
      setList(data);
      return data;
    }

    fetchSubordinates();
  }, []);
  return (
    <>
      <List className="demo-loadmore-list" itemLayout="horizontal">
        {list.map((item) => {
          return <SubordinateItem id={item._id} />;
        })}
      </List>
    </>
  );
};

export default SubordinatesList;
