import React, { useState } from "react";
import { Button, Checkbox, Form, Input, Spin, Typography, message } from "antd";
import sideImage from "../../Assets/Certification-pana.svg";
import axios from "../../Utils/axios";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

const AttestationTravail = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleFinish = async (formValues) => {
    let requestData = form.getFieldsValue();
    if (!requestData._is_signed) requestData._is_signed = false;
    setLoading(true);
    try {
      const { data } = await axios.post("/api/certif/", requestData);
      if (data.status === "success") {
        message.success(data.message);
        setLoading(false);
      }
    } catch (error) {
      message.error(error.response.data.message);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      _is_signed: e.target.checked,
    });
  };

  return (
    <div className="bg-white p-4 rounded shadow-sm mt-4">
      <div className=" mb-3 d-flex justify-content-between flex-lg-row flex-md-row flex-column pb-2 align-items-center">
        <h4 className="fw-bold">Demande d'attestation de travail</h4>

        <Button type="primary">Mes attestations de travail</Button>
      </div>
      <div className="row d-flex flex-column-reverse flex-lg-row flex-md-row ">
        <div className="col-lg-6 col-md-6 col-sm-12 h-100">
          <div>
            <Typography.Paragraph
              ellipsis={{
                rows: 2,
                expandable: true,
                symbol: "Plus",
              }}
              className="pb-3"
            >
              Bienvenue dans la section dédiée à la gestion des demandes
              d'attestation de travail. Vous pouvez ici effectuer une demande
              d'attestation de travail , suivre le statut de votre demande en
              cours . Le certificat de travail est un document remis par
              l'employeur à un employé à la fin de son contrat de travail. Si
              vous avez besoin d'une attestation de travail , n'hésitez pas à
              nous en faire la demande via cette plateforme.Le certificat de
              travail consiste en une synthèse structurée, la plus objective
              possible, de l'activité professionnelle du collaborateur.
            </Typography.Paragraph>
            <Form
              form={form}
              disabled={loading}
              onFinish={handleFinish}
              labelCol={{
                xs: { span: 24 },
                sm: { span: 24 },
                md: { span: 24 },
              }}
              wrapperCol={{
                xs: { span: 24 },
                sm: { span: 24 },
                md: { span: 24 },
                lg: { span: 24 },
              }}
              layout="vertical"
              size="middle"
            >
              <Form.Item
                label="Motif"
                name="_motif"
                rules={[
                  { required: true, message: "Veuillez entrer le motif" },
                ]}
              >
                <Input.TextArea placeholder="Motif" />
              </Form.Item>
              <Form.Item name="_is_signed">
                <Checkbox onChange={handleChange}>
                  <Typography.Text strong>Signature légalisée</Typography.Text>
                </Checkbox>
              </Form.Item>

              <Form.Item>
                <div className="d-flex justify-content-between">
                  <Button htmlType="submit" type="primary" disabled={loading}>
                    Soumettre !
                  </Button>
                  {loading && <Spin indicator={antIcon} />}
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center align-items-center h-100">
          <img src={sideImage} alt="side cover" />
        </div>
      </div>
    </div>
  );
};

export default AttestationTravail;
