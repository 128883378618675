import { useContext, useEffect, useState } from "react";
import {
  Space,
  Table,
  Tag,
  Typography,
  message,
  Form,
  Modal,
  Button,
  Tooltip,
  Select,
  Popconfirm,
  Input,
  Radio,
  Checkbox,
  Dropdown,
  Popover,
} from "antd";

import moment from "moment";

import { ColumnHeightOutlined } from "@ant-design/icons";

import axios from "../../Utils/axios";
import OtherRequestsTable from "../../Components/OtherRequestsTable";
import UploadInput from "../../Components/UploadInput";
import GlobalContext from "../../Context/GlobalContext";
import EquipmentsRequests from "../../Components/EquipmentsRequests";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";

const { Text, Paragraph } = Typography;

const options = [
  {
    label: "Autres",
    value: "OTHERS",
  },
  {
    label: "Attestation du travail",
    value: "CERTIF",
  },
];

const OwnLeaves = () => {
  const { redirectData } = useContext(GlobalContext);
  const [form] = Form.useForm();
  const [size, setSize] = useState("small");

  const handleSizeChange = (new_size) => {
    setSize(new_size);
  };

  const items = [
    {
      key: "1",
      label: (
        /* eslint-disable-next-line */
        <a
          href="#"
          className="text-decoration-none"
          onClick={() => {
            handleSizeChange("large");
          }}
        >
          Plus grand
        </a>
      ),
    },
    {
      key: "2",
      label: (
        /* eslint-disable-next-line */
        <a
          href="#"
          className="text-decoration-none"
          onClick={() => {
            handleSizeChange("middle");
          }}
        >
          Moyenne
        </a>
      ),
    },
    {
      key: "3",
      label: (
        /* eslint-disable-next-line */
        <a
          href="#"
          className="text-decoration-none"
          onClick={() => {
            handleSizeChange("small");
          }}
        >
          Compact
        </a>
      ),
    },
  ];

  const [currentValue, setCurrentValue] = useState("OTHERS");

  const onChange = ({ target: { value } }) => {
    setCurrentValue(value);
  };

  const [leaves, setLeaves] = useState([]);
  // eslint-disable-next-line
  const [expand, setExpand] = useState(true);
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState(
    redirectData?.type ? redirectData?.type : "DEFAULT"
  );

  const [optionsTab, setOptions] = useState([
    "_typeLeave",
    "_posted_date",
    "_answered_by",
    "_event",
    "BeginingDate",
    "EndingDate",
    "Durée",
    "status",
  ]);

  const setFile = (base64) => {
    form.setFieldsValue({
      OptionalFiles: base64,
    });
  };

  const columns = [
    optionsTab.find((elem) => elem === "_typeLeave")
      ? {
          title: "Type",
          dataIndex: "_typeLeave",
          width: 200,
          render: (_, requestedLeaves) => (
            <>
              {requestedLeaves._special_leave ? (
                <Paragraph
                  style={{ margin: 0 }}
                  ellipsis={{
                    rows: 2,
                    expandable: true,
                    symbol: "Plus",
                    onExpand: () => {
                      setExpand(true);
                    },
                    onEllipsis: () => {
                      setExpand(false);
                    },
                  }}
                >
                  {requestedLeaves._typeLeave +
                    `${
                      requestedLeaves._special_leave &&
                      " - " + requestedLeaves._special_leave
                    }`}
                </Paragraph>
              ) : (
                <Text>{requestedLeaves._typeLeave}</Text>
              )}
            </>
          ),
        }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "_posted_date")
      ? {
          title: "Soumis le",
          dataIndex: "_posted_date",
          sorter: (a, b) => moment(a._posted_date) - moment(b._posted_date),
          render: (_, requestedLeave) => (
            <Text strong>
              {requestedLeave._posted_date
                ? moment(requestedLeave._posted_date).format("DD/MM/YYYY HH:mm")
                : "N/A"}
            </Text>
          ),
        }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "_answered_by")
      ? {
          title: "Répondu par",
          dataIndex: "_answered_by",
          render: (_, requestedLeaves) => (
            <>
              {requestedLeaves.status === "Pending" ? (
                <Tag color={"orange"}>En attente</Tag>
              ) : requestedLeaves._answered_by ? (
                <Tooltip
                  title={
                    "Répondu à " +
                    moment(requestedLeaves._answered_at).format(
                      "DD/MM/YYYY HH:mm"
                    )
                  }
                >
                  <Text> {requestedLeaves._answered_by} </Text>
                </Tooltip>
              ) : (
                <Text>N/A</Text>
              )}
            </>
          ),
        }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "_event")
      ? {
          title: "Motif",
          dataIndex: "_event",
          width: 300,
          render: (_, requestedLeaves) => (
            <>
              <Paragraph
                style={{ margin: 0, whiteSpace: "pre-wrap" }}
                ellipsis={{
                  rows: 2,
                  expandable: true,
                  symbol: "Plus",
                }}
              >
                {requestedLeaves._event ||
                  requestedLeaves._motif ||
                  requestedLeaves._typeLeave}
              </Paragraph>
            </>
          ),
        }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "BeginingDate")
      ? {
          title: "Date de début",
          dataIndex: "BeginingDate",
          sorter: (a, b) => moment(a.BeginingDate) - moment(b.BeginingDate),
          render: (text) => <Text>{moment(text).format("DD/MM/YYYY")}</Text>,
        }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "EndingDate")
      ? {
          title: "Date de Fin",
          dataIndex: "EndingDate",
          sorter: (a, b) => moment(a.EndingDate) - moment(b.EndingDate),
          render: (text) => <Text>{moment(text).format("DD/MM/YYYY")}</Text>,
        }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "Durée")
      ? {
          title: "Durée",
          render: (_, requestedLeaves) => (
            <Text>
              {requestedLeaves._typeLeave === "Autorisation"
                ? requestedLeaves._duration
                : !requestedLeaves._is_half_day
                ? requestedLeaves.DaysNumber + " Jour(s)"
                : requestedLeaves._is_half_day}
            </Text>
          ),
        }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "status")
      ? {
          title: "Statut",
          dataIndex: "status",
          filters: [
            {
              text: <Tag color={"orange"}>En Attente</Tag>,
              value: "Pending",
            },

            {
              text: <Tag color={"green"}>Approuvée</Tag>,
              value: "Accepted",
            },

            {
              text: <Tag color={"cyan"}>En attente d'approbation</Tag>,
              value: "Validating",
            },

            {
              text: <Tag color={"red"}>Rejeté</Tag>,
              value: "Rejected",
            },

            {
              text: <Tag color="red">Annulée</Tag>,
              value: "Canceled",
            },

            {
              text: <Tag color={"red"}>Non encore justifié</Tag>,
              value: "Unjustified",
            },
            {
              text: <Tag>échue</Tag>,
              value: "Failed",
            },
          ],
          defaultFilteredValue:
            redirectData?.type === "DEFAULT" && redirectData?.filters
              ? redirectData?.filters
              : [],
          onFilter: (value, record) => {
            return record.status.includes(value);
          },
          render: (_, record) => (
            <>
              {record.status === "Pending" && (
                <Tag color={"orange"}>En Attente</Tag>
              )}
              {record.status === "Accepted" && (
                <Tag color={"green"}>Approuvée</Tag>
              )}
              {record.status === "Validating" && (
                <Tag color={"green"}>En attente d'approbation</Tag>
              )}
              {record.status === "Rejected" && <Tag color={"red"}>Rejeté</Tag>}
              {record.status === "Canceled" && <Tag color="red">Annulée</Tag>}
              {record.status === "Unjustified" && (
                <Tag color={"red"}>Non encore justifiée</Tag>
              )}
              {record.status === "Failed" && <Tag color={"red"}>échue</Tag>}
            </>
          ),
        }
      : { width: 0, className: "d-none" },
    {
      title: "Actions",
      render: (_, record) => (
        <>
          <Space>
            {record.status === "Unjustified" && (
              <>
                {/* eslint-disable-next-line  */}
                <a>
                  <Button
                    size="small"
                    onClick={() => {
                      Modal.info({
                        title: "Joindre un justificatif",
                        okText: "Joindre",
                        content: (
                          <>
                            <Form form={form} layout="vertical">
                              <Form.Item
                                name="OptionalFiles"
                                label="Justificatif:"
                              >
                                {/* <FileBase64
                                  onDone={(e) => {
                                    form.setFieldsValue({
                                      OptionalFiles: e.base64,
                                    });
                                  }}
                                /> */}
                                <UploadInput setFile={setFile} />
                              </Form.Item>
                            </Form>
                          </>
                        ),
                        onOk: async () => {
                          const formValues = form.getFieldsValue();
                          if (formValues.OptionalFiles) {
                            await axios.patch("/api/leaves/" + record._id, {
                              OptionalFiles: formValues.OptionalFiles,
                              status: "Validating",
                            });
                            let newLeaves = leaves.map((RL) => {
                              if (RL._id === record._id) {
                                RL.status = "Validating";
                              }
                              return RL;
                            });

                            setLeaves(newLeaves);
                          }
                        },
                      });
                    }}
                  >
                    Joindre un justificatif
                  </Button>
                  <Button
                    style={{ marginLeft: "5px" }}
                    onClick={() => {
                      handleActions({
                        _id: record._id,
                        value: "Canceled",
                      });
                    }}
                    type="primary"
                    size="small"
                  >
                    Annuler
                  </Button>
                </a>
              </>
            )}

            {record.status === "Pending" && (
              <>
                <Button
                  onClick={() => {
                    handleActions({
                      _id: record._id,
                      value: "Canceled",
                    });
                  }}
                  type="primary"
                  size="small"
                >
                  Annuler
                </Button>
              </>
            )}
            {(record.status === "Rejected" ||
              record.status === "Failed" ||
              record.status === "Canceled" ||
              record.status === "Validating") && (
              <Button type="primary" size="small" disabled>
                Annuler
              </Button>
            )}
            {record.status === "Accepted" && (
              <Popconfirm
                disabled={moment().isSameOrAfter(moment(record.BeginingDate))}
                placement="leftBottom"
                title="êtes-vous sûr d'annuler la demande"
                okText="Oui"
                onConfirm={() => {
                  Modal.info({
                    title: "Annuler la demande",
                    content: (
                      <>
                        <Form form={form} layout="vertical">
                          <Form.Item name="_motif" label="Motif:" required>
                            <Input.TextArea />
                          </Form.Item>
                        </Form>
                      </>
                    ),
                    onOk: () => {
                      handleCancel(record._id);
                    },
                  });
                }}
              >
                <Button
                  disabled={moment().isSameOrAfter(moment(record.BeginingDate))}
                  type="primary"
                  size="small"
                >
                  Annuler
                </Button>
              </Popconfirm>
            )}
          </Space>
        </>
      ),
    },
  ];

  const handleActions = async (options) => {
    try {
      const { data } = await axios.patch("/api/leaves/options/" + options._id, {
        status: options.value,
        self: true,
      });
      if (data.status === "success") {
        message.success(data.message);
        const newRequestedLeaves = leaves.map((RL) => {
          if (RL._id === options._id) {
            RL.status = options.value;
          }
          return RL;
        });
        setLeaves(newRequestedLeaves);
      }
    } catch (error) {}
  };

  const handleCancel = async (id) => {
    const formValues = form.getFieldsValue();
    if (!formValues._motif) {
      return message.warning("Veuillez remplir le motif");
    }
    try {
      const { data } = await axios.patch("/api/leaves/cancel/" + id, {
        _motif: formValues._motif,
      });
      if (data.status === "success") {
        const temp = leaves.map((leave) => {
          if (leave._id === id) {
            leave.status = "Canceled";
          }
          return leave;
        });

        setLeaves(temp);
        message.success(data.message);
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  useEffect(() => {
    async function fetchLeaves() {
      const { data } = await axios.get("/api/leaves/");
      setLoading(false);
      setLeaves(data.Leaves);
      return data;
    }
    fetchLeaves();
  }, []);

  const onChangeOptions = (checkedValues) => {
    setOptions(checkedValues);
  };

  return (
    <div className="pt-4">
      <div className="bg-white p-4 rounded shadow-sm ">
        <div className="mb-3">
          <h4 className="fw-bold">Mes Demandes</h4>
        </div>

        <Typography.Paragraph
          ellipsis={{
            rows: 2,
            expandable: true,
            symbol: "Plus",
          }}
          className="pb-3"
        >
          {/* To change */}
          Ici, vous pouvez facilement suivre l'état de vos demandes de congé et
          autorisations, ainsi que consulter l'historique de ces demandes.
          Gardez toujours une trace de vos congés et autorisations en un seul
          endroit, pour une gestion efficace de votre temps de travail.
        </Typography.Paragraph>
        <div
          className="row justify-content-between mb-4"
          style={{
            backgroundColor: "#f2f5fb",
            padding: "10px",
            borderRadius: "13px",
            margin: "0 .4rem",
          }}
        >
          <div className="col-lg-5 my-2">
            <Select
              style={{ width: "100%" }}
              defaultValue={type}
              placeholder="Choisissez les demandes que vous souhaitez gerer"
              onChange={(e) => {
                setType(e);
              }}
            >
              <Select.Option value="DEFAULT">
                Demandes de congés/autorisations
              </Select.Option>
              <Select.Option value="EQUIPMENTS">
                Demandes de materiel informatique
              </Select.Option>
              <Select.Option value="OTHERS">Autres demandes</Select.Option>
            </Select>
            {type === "OTHERS" && (
              <div className="my-1">
                <label>Demandes: </label>{" "}
                <Radio.Group
                  options={options}
                  onChange={onChange}
                  value={currentValue}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            )}
          </div>

          <div className="col-lg-3 d-flex align-items-center justify-content-end">
            <Tooltip title="Paramètres du tableau">
              <Popover
                trigger="click"
                placement="bottom"
                title="Colonne(s) sélectionnée(s):"
                // className="gearIcon"
                content={
                  <Checkbox.Group
                    onChange={onChangeOptions}
                    defaultValue={optionsTab}
                  >
                    <div className="d-flex flex-column">
                      <div>
                        <Checkbox value="_typeLeave">Demande</Checkbox>
                      </div>
                      <div>
                        <Checkbox value="_posted_date">Soumis le</Checkbox>
                      </div>
                      <div>
                        <Checkbox value="_answered_by">Répondu par</Checkbox>
                      </div>
                      <div>
                        <Checkbox value="_event">Motif</Checkbox>
                      </div>
                      <div>
                        <Checkbox value="BeginingDate">Date de début</Checkbox>
                      </div>
                      <div>
                        <Checkbox value="EndingDate">Date de fin</Checkbox>
                      </div>
                      <div>
                        <Checkbox value="Durée">Durée</Checkbox>
                      </div>
                      <Checkbox value="status">Status</Checkbox>
                    </div>
                  </Checkbox.Group>
                }
              >
                <FontAwesomeIcon icon={faGear} style={{ cursor: "pointer" }} />
              </Popover>
            </Tooltip>
            <Tooltip title="Densité">
              <Dropdown
                menu={{
                  items,
                }}
              >
                <ColumnHeightOutlined
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                />
              </Dropdown>
            </Tooltip>
          </div>
        </div>
        <div className="rounded-3 mx-1">
          {type === "DEFAULT" ? (
            <Table
              className="shadow-md "
              bordered={true}
              loading={loading}
              size={size}
              columns={columns}
              scroll={{ x: "max-content" }}
              dataSource={leaves}
            />
          ) : type === "OTHERS" ? (
            <OtherRequestsTable
              size={size}
              currentValue={currentValue}
              filteredData={
                redirectData?.type !== "DEFAULT" && redirectData?.filters
                  ? redirectData.filters
                  : []
              }
            />
          ) : (
            <EquipmentsRequests size={size} />
          )}
        </div>
      </div>
    </div>
  );
};

export default OwnLeaves;
