import CalendarCongeModern from "../../Components/CalendarCongeModern";

const TeamCalendar = () => {
  return (
    <div className="pt-4 ">
      <div className="bg-white p-4 rounded-4 shadow-sm w-100 h-100">
        <div className="mb-3 d-flex justify-content-between align-items-center">
          <h4 className="fw-bold">Calendrier de l'équipe</h4>
        </div>
        <div className="h-100 w-100">
          <CalendarCongeModern />
        </div>
      </div>
    </div>
  );
};

export default TeamCalendar;
