import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Checkbox,
  message,
  Typography,
  Drawer,
  Select,
  Radio,
  DatePicker,
  Space,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import axios from "../../Utils/axios";
import UploadInput from "../UploadInput";
import PhoneInput from "react-phone-number-input";
import moment from "moment";
import dayjs from "dayjs";
const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;
const RequestChangeModal = (props) => {
  const {
    onClose,
    TriggerModal,
    Trigger,
    visible,
    variables,
    drawerSize,
    setVariables,
  } = props;
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isAlternate, setIsAlternate] = useState(false);

  const [form] = Form.useForm();
  variables.birthDate = dayjs(variables.birthDate);
  variables.embaucheDate = dayjs(variables.embaucheDate);

  const setProfilePic = (base64) => {
    form.setFieldsValue({
      imgProfile: base64,
    });
  };
  const setFile = (base64) => {
    form.setFieldsValue({
      attachment_file: base64,
    });
  };
  const setCIN = (base64) => {
    form.setFieldsValue({
      _cin_File: base64,
    });
  };
  const setCV = (base64) => {
    form.setFieldsValue({
      CV: base64,
    });
  };
  const setRIB = (base64) => {
    form.setFieldsValue({
      _rib_File: base64,
    });
  };
  const handleChangeChecked = () => {
    setChecked(!checked);
  };

  const handleOk = () => {
    TriggerModal(false);
  };

  const handleChangePassword = async (formValues) => {
    if (checked) {
      if (!formValues.attachment_file) {
        message.error("Veuillez télécharger un fichier valide");
      }
    }
    try {
      const { data } = await axios.post("/api/request/change", formValues);
      message.success(data.message);
      TriggerModal(false);
      form.resetFields();
    } catch (error) {
      message.error(error.response.data.message);
    }
  };
  useEffect(() => {
    form.setFieldsValue({ ...variables });
    setIsAlternate(variables._is_alternate);
  }, [variables]);

  const UpdateUser = async () => {
    setLoading(true);
    const formValues = form.getFieldsValue();
    try {
      const _edit = JSON.stringify(formValues);
      const { data } = await axios.patch(`/api/employees/request/edit`, {
        _edit,
      });
      setLoading(false);
      if (data.status === "success") {
        message.success(data.message);
        setVariables(formValues);
        onClose();
      }
    } catch (error) {
      setLoading(false);
      message.error(error.response.data.message);
    }
    onClose();
  };
  return (
    <>
      <Drawer
        onClose={onClose}
        width={drawerSize}
        visible={visible}
        title=" Modification d'une information personnelle"
        // onCancel={() => TriggerModal(false)}
        footer={
          [
            // <Button key="submit" onClick={handleOk}>
            //   <FontAwesomeIcon
            //     icon={faArrowLeft}
            //     style={{ marginRight: "10px" }}
            //   />{" "}
            //   Retour
            // </Button>,
          ]
        }
        extra={
          <Space>
            <Button onClick={onClose}>Annuler</Button>
            <Button onClick={UpdateUser} type="primary">
              Modifier
            </Button>
          </Space>
        }
      >
        {/* <Form
          form={form}
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
          }}
          layout="vertical"
          size="middle"
          onFinish={handleChangePassword}
        >
          <Form.Item name="request" label="Sujet">
            <Input size="large" />
          </Form.Item>
          <Form.Item name="motif" label="Message">
            <TextArea maxLength={400} />
          </Form.Item>
          <Form.Item>
            <Checkbox onChange={handleChangeChecked}>Fichier joint ?</Checkbox>
          </Form.Item>
          {checked && (
            <>
              <Form.Item name="attachment_file">
                <UploadInput setFile={setFile} />
              
              </Form.Item>
              <Text strong type="danger">
                Si vous joignez plus d'un fichier, essayez de les compresser au
                format zip ou rare
              </Text>
            </>
          )}
          <Form.Item className="mt-2">
            <div className="d-flex justify-content-between">
              <Button htmlType="submit" type="primary" size="large">
                Soumettre !
              </Button>
            </div>
          </Form.Item>
        </Form> */}
        <Form form={form} layout="vertical" size="middle" disabled={loading}>
          <div className="row border-bottom mb-3">
            <div className="col-lg-6 col-sm-12">
              <Form.Item label="Nom et prénom" name="fullName">
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      fullName: e.target.value.replace(/[^a-zA-Z ]/g, ""),
                    });
                  }}
                />
              </Form.Item>
              <Form.Item label="Email Professionel" name="email">
                <Input disabled />
              </Form.Item>
              <Form.Item label="Email Personelle" name="_personal_email">
                <Input type="email" />
              </Form.Item>
              <Form.Item label="Numéro du télèphone" name="phoneNum">
                <PhoneInput defaultCountry="TN" placeholder="12 345 678" />
              </Form.Item>

              <Form.Item label="RIB" name="Rib">
                <Input status={!variables.Rib && "error"} />
              </Form.Item>
              <Form.Item label="CIN" name="CIN">
                <Input disabled />
              </Form.Item>
              <Form.Item label="Adresse" name="address">
                <Input />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-sm-12">
              <Form.Item label="Matricule" name="matricule">
                <Input disabled />
              </Form.Item>
              <Form.Item label="Date d'embauche" name="embaucheDate">
                <DatePicker style={{ width: "100%" }} disabled />
              </Form.Item>
              <Form.Item label="Date de naissance" name="birthDate">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item label="Lieu naissance" name="birthPlace">
                <Input />
              </Form.Item>
              <Form.Item label="Type de contrat" name="contractType">
                <Input disabled />
              </Form.Item>
              <Form.Item label="Poste" name="Poste">
                <Input disabled />
              </Form.Item>
            </div>
          </div>
          <div>
            <h4>Personne à prévenir en cas d'urgence</h4>
          </div>
          <div className="col-lg-6 col-sm-12">
            <Form.Item name="_urgence_name" label="Nom et prénom">
              <Input status={!form.getFieldsValue()._urgence_name && "error"} />
            </Form.Item>
            <Form.Item name="_urgence_relation" label="Relation">
              <Input
                status={!form.getFieldsValue()._urgence_relation && "error"}
              />
            </Form.Item>
            <Form.Item name="_urgence_phone" label="Numéro du télèphone">
              <Input
                status={!form.getFieldsValue()._urgence_phone && "error"}
              />
            </Form.Item>
          </div>
          <div>
            <div className="border-top pt-3">
              <h4>Pièces a joindre</h4>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <Form.Item name="_cin_File" label="CIN">
                  <UploadInput
                    setFile={setCIN}
                    danger={!form.getFieldsValue()._cin_File}
                  />
                </Form.Item>
                <Form.Item name="CV" label="CV">
                  <UploadInput
                    setFile={setCV}
                    danger={!form.getFieldsValue().CV}
                  />
                </Form.Item>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <Form.Item name="_rib_File" label="RIB">
                  <UploadInput
                    setFile={setRIB}
                    danger={!form.getFieldsValue()._rib_File}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      </Drawer>
    </>
  );
};

export default RequestChangeModal;
