import { useState, useEffect, useRef, useContext } from "react";

import { SearchOutlined, ColumnHeightOutlined } from "@ant-design/icons";

import {
  Button,
  Space,
  Table,
  Tag,
  Typography,
  message,
  Modal,
  Input,
  Form,
  Tooltip,
  Popconfirm,
  Select,
  DatePicker,
  Popover,
  Checkbox,
  Dropdown,
} from "antd";

import Highlighter from "react-highlight-words";

import axios from "../../Utils/axios";
import moment from "moment";
import HistoryLeaves from "../../Components/HistoryLeaves";
import SubordinatesList from "../../Components/SubordinatesList";
import GlobalContext from "../../Context/GlobalContext";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";

const { Text, Paragraph, Link } = Typography;
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const LeavesListEmployees = () => {
  const [form] = Form.useForm();
  const [options, setOptions] = useState([
    "fullName",
    "request",
    "status",
    "motif",
    "startDate",
    "endDate",
    "duration",
    "answered_by",
  ]);
  const { redirectData, setRedirectData } = useContext(GlobalContext);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [dateRange, setDateRange] = useState("");
  const [size, setSize] = useState("small");
  const [loading, setLoading] = useState(true);
  const [requestedLeaves, setRequestedLeaves] = useState([]);
  const [type, setType] = useState("DEFAULT");
  const searchInput = useRef(null);

  const handleSizeChange = (new_size) => {
    setSize(new_size);
  };

  const items = [
    {
      key: "1",
      label: (
        <a
          href="#"
          className="text-decoration-none"
          onClick={() => {
            handleSizeChange("large");
          }}
        >
          Plus grand
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          href="#"
          className="text-decoration-none"
          onClick={() => {
            handleSizeChange("middle");
          }}
        >
          Moyenne
        </a>
      ),
    },
    {
      key: "3",
      label: (
        <a
          href="#"
          className="text-decoration-none"
          onClick={() => {
            handleSizeChange("small");
          }}
        >
          Compact
        </a>
      ),
    },
  ];

  const onChange = (date) => {
    if (date) {
      const startDate = dayjs(date[0]).format("DD-MM-YYYY");
      const endDate = dayjs(date[1]).format("DD-MM-YYYY");
      setDateRange({ startDate: startDate, endDate: endDate });
    } else {
      setDateRange("");
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        justify-content-center
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    options.find((elem) => elem === "fullName")
      ? {
          title: "Nom et prénom",
          dataIndex: "fullName",
          key: "1",
          ...getColumnSearchProps("fullName"),
          render: (_, requestedLeaves) => (
            <Link strong>
              #{requestedLeaves.idEmployee} - {requestedLeaves.fullName}
            </Link>
          ),
        }
      : { width: 0, className: "d-none" },
    options.find((elem) => elem === "request")
      ? {
          title: "Demande",
          dataIndex: "_typeLeave",
          width: 200,
          render: (_, requestedLeaves) => (
            <>
              {requestedLeaves._special_leave ? (
                <Paragraph
                  style={{ margin: 0 }}
                  ellipsis={{
                    rows: 2,
                    expandable: true,
                    symbol: "Plus",
                  }}
                >
                  {requestedLeaves._typeLeave +
                    `${
                      requestedLeaves._special_leave &&
                      " - " + requestedLeaves._special_leave
                    }`}
                </Paragraph>
              ) : (
                <Text>{requestedLeaves._typeLeave}</Text>
              )}
            </>
          ),
        }
      : { width: 0, className: "d-none" },
    options.find((elem) => elem === "status")
      ? {
          title: "Statut",
          dataIndex: "status",
          key: "7",
          filters: [
            {
              text: <Tag color={"orange"}>En attente</Tag>,
              value: "Pending",
            },

            {
              text: <Tag color={"green"}>Approuvée</Tag>,
              value: "Accepted",
            },

            {
              text: <Tag color={"cyan"}>En attente d'approbation</Tag>,
              value: "Validating",
            },

            {
              text: <Tag color={"red"}>Rejeté</Tag>,
              value: "Rejected",
            },

            {
              text: <Tag color="red">Annulée</Tag>,
              value: "Canceled",
            },

            {
              text: <Tag color={"gold"}>Non encore justifié</Tag>,
              value: "Unjustified",
            },

            {
              text: <Tag>échue</Tag>,
              value: "Failed",
            },
          ],
          defaultFilteredValue:
            redirectData?.type === "DEFAULT" && redirectData?.filters
              ? redirectData?.filters
              : [],
          onFilter: (value, record) => {
            setRedirectData({});
            return record.status.includes(value);
          },
          render: (_, record) => (
            <>
              {record.status === "Pending" && (
                <Tag color={"orange"}>En attente</Tag>
              )}
              {record.status === "Accepted" && (
                <Tag color={"green"}>Approuvée</Tag>
              )}
              {record.status === "Validating" && (
                <Tag color={"cyan"}>En attente d'approbation</Tag>
              )}
              {record.status === "Rejected" && <Tag color={"red"}>Rejeté</Tag>}
              {record.status === "Canceled" && <Tag color="red">Annulée</Tag>}
              {record.status === "Unjustified" && (
                <Tag color={"gold"}>Non encore justifié</Tag>
              )}
              {record.status === "Failed" && (
                <Tooltip title="Considérée comme absence non justifiée">
                  <Tag>échue</Tag>
                </Tooltip>
              )}
            </>
          ),
        }
      : { width: 0, className: "d-none" },
    options.find((elem) => elem === "motif")
      ? {
          title: "Motif",
          dataIndex: "_event",
          key: "4",
          width: 300,
          render: (_, requestedLeaves) => (
            <>
              <Paragraph
                style={{ margin: 0, whiteSpace: "pre-wrap" }}
                ellipsis={{
                  rows: 2,
                  expandable: true,
                  symbol: "Plus",
                }}
              >
                {requestedLeaves._event || requestedLeaves._motif || "N/A"}
              </Paragraph>
            </>
          ),
        }
      : { width: 0, className: "d-none" },
    options.find((elem) => elem === "startDate")
      ? {
          title: (
            <Tooltip title="Les filtres et la recherche sont en cours de développement">
              Date de début
            </Tooltip>
          ),
          dataIndex: "BeginingDate",

          sorter: (a, b) => moment(a.BeginingDate) - moment(b.BeginingDate),
          render: (text) => <Text>{moment(text).format("DD/MM/YYYY")}</Text>,
        }
      : { width: 0, className: "d-none" },
    options.find((elem) => elem === "endDate")
      ? {
          title: (
            <Tooltip title="Les filtres et la recherche sont en cours de développement">
              Date de fin
            </Tooltip>
          ),
          dataIndex: "EndingDate",
          key: "6",
          sorter: (a, b) => moment(a.EndingDate) - moment(b.EndingDate),
          render: (text) => <Text>{moment(text).format("DD/MM/YYYY")}</Text>,
        }
      : { width: 0, className: "d-none" },
    options.find((elem) => elem === "duration")
      ? {
          title: "Durée",
          render: (_, requestedLeaves) => (
            <Tooltip
              color="cyan"
              title={
                requestedLeaves._typeLeave === "Télétravail" ? (
                  <div>
                    Sauf:
                    <br />
                    {requestedLeaves._except.map((elem) => {
                      return <Tag color="blue">{elem}</Tag>;
                    })}
                  </div>
                ) : requestedLeaves._typeLeave === "Autorisation" ? (
                  requestedLeaves._duration
                ) : !requestedLeaves._is_half_day ? (
                  requestedLeaves.DaysNumber + " Jour(s)"
                ) : (
                  requestedLeaves._is_half_day
                )
              }
            >
              <Text>
                {requestedLeaves._typeLeave === "Autorisation"
                  ? requestedLeaves._duration
                  : !requestedLeaves._is_half_day
                  ? requestedLeaves.DaysNumber + " Jour(s)"
                  : requestedLeaves._is_half_day}
              </Text>
            </Tooltip>
          ),
        }
      : { width: 0, className: "d-none" },
    {
      title: "Soumis le",
      dataIndex: "_posted_date",
      sorter: (a, b) => moment(a._posted_date) - moment(b._posted_date),
      render: (_, leave) => (
        <Text strong>
          {leave?._posted_date
            ? moment(leave._posted_date).format("DD/MM/YYYY HH:mm")
            : "N/A"}
        </Text>
      ),
    },
    options.find((elem) => elem === "answered_by")
      ? {
          title: "Répondu par",
          dataIndex: "_answered_by",
          render: (_, requestedLeaves) => (
            <>
              {requestedLeaves.status === "Pending" ? (
                <Tag color={"orange"}>En attente</Tag>
              ) : requestedLeaves._answered_by ? (
                <Tooltip
                  title={
                    "Répondu à " +
                    moment(requestedLeaves._answered_at).format(
                      "DD/MM/YYYY HH:mm"
                    )
                  }
                >
                  <Text> {requestedLeaves._answered_by} </Text>
                </Tooltip>
              ) : (
                <Text>N/A</Text>
              )}
            </>
          ),
        }
      : { width: 0, className: "d-none" },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {(record.status === "Pending" || record.status === "Validating") && (
            <>
              <Popconfirm
                okText="Oui"
                title="Vous êtes sûr d'approuver la demande?"
                onConfirm={() => {
                  handleActions({
                    _id: record._id,
                    value: "Accepted",
                  });

                  const newRecord = requestedLeaves.map((RL) => {
                    if (RL._id === record._id) {
                      RL.status = "Accepted";
                    }
                    return RL;
                  });

                  setRequestedLeaves(newRecord);
                }}
              >
                {/* eslint-disable-next-line */}
                <a>
                  <Button type="primary" size="small">
                    Approuver
                  </Button>
                </a>
              </Popconfirm>

              <Popconfirm
                okText="Oui"
                title="Vous êtes sûr de rejeter la demande?"
                onConfirm={() => {
                  Modal.info({
                    title: "Rejet de la demande",
                    okCancel: true,
                    cancelText: "Annuler",
                    content: (
                      <>
                        <Form form={form} layout="vertical">
                          <Form.Item name="message" label="Reponse:">
                            <TextArea />
                          </Form.Item>
                        </Form>
                      </>
                    ),
                    onOk: async () => {
                      const formValues = form.getFieldsValue();
                      await axios.patch("/api/leaves/options/" + record._id, {
                        message: formValues.message,
                        status: "Rejected",
                      });
                      const newRecord = requestedLeaves.map((RL) => {
                        if (RL._id === record._id) {
                          RL.status = "Rejected";
                        }
                        return RL;
                      });

                      setRequestedLeaves(newRecord);
                    },
                  });
                }}
              >
                <Button type="primary" danger size="small">
                  Rejeter
                </Button>
              </Popconfirm>
            </>
          )}{" "}
          {(record.status === "Accepted" ||
            record.status === "Rejected" ||
            record.status === "Failed" ||
            record.status === "Canceled" ||
            record.status === "Unjustified") && (
            <Button size="small" disabled>
              Valider
            </Button>
          )}
        </Space>
      ),
    },
  ];

  const handleActions = async (options) => {
    try {
      const { data } = await axios.patch("/api/leaves/options/" + options._id, {
        status: options.value,
        self: false,
      });
      if (data.status === "success") {
        message.success(data.message);
        const newRequestedLeaves = requestedLeaves.map((RL) => {
          if (RL._id === options._id) {
            RL.status = options.value;
          }
          return RL;
        });
        setRequestedLeaves(newRequestedLeaves);
      }
    } catch (error) {}
  };

  useEffect(() => {
    async function fetchRequestedLeaves() {
      const { data } = await axios.get("/api/leaves/requested/leaves");
      setRequestedLeaves(data);
      setLoading(false);
      return data;
    }

    fetchRequestedLeaves();
  }, []);

  const onChangeOptions = (checkedValues) => {
    setOptions(checkedValues);
  };

  return (
    <div>
      <div className="pt-4">
        <div className="bg-white p-4 rounded-4 shadow-sm ">
          <div className="mb-3 ">
            <h4 className="fw-bold">Gérer mes subordonnées</h4>
          </div>
          <Typography.Paragraph
            style={{ whiteSpace: "pre-wrap" }}
            ellipsis={{
              rows: 2,
              expandable: true,
              symbol: "Plus",
            }}
            className="pb-3"
          >
            vous permet de suivre les demandes de congé et les autorisations de
            sortie de vos subordonnés, d'approuver ou de refuser ces demandes en
            temps réel. Cette rubrique vous offre une vue d'ensemble de la
            gestion des congés pour votre équipe, vous permettant de maintenir
            une harmonie au sein de votre service.
          </Typography.Paragraph>
          <div
            className="row justify-content-between mb-2 "
            style={{
              backgroundColor: "#f2f5fb",
              padding: "20px",
              borderRadius: "13px",
              margin: "0 .4rem",
            }}
          >
            <div className="col-lg-4 my-1">
              <div>
                <Select
                  defaultValue={type}
                  placeholder="Choisissez les demandes que vous souhaitez gerer"
                  onChange={(e) => {
                    setType(e);
                  }}
                >
                  <Select.Option value="DEFAULT">
                    Demandes en cours
                  </Select.Option>
                  <Select.Option value="OTHERS">
                    Historique des demandes
                  </Select.Option>
                </Select>
              </div>
            </div>
            <div className="col-lg-5 my-1 d-flex align-items-center">
              <RangePicker
                onChange={onChange}
                className="w-100"
                placement="bottomLeft"
              />
              <Tooltip title="Paramètres du tableau">
                <Popover
                  trigger="click"
                  placement="bottom"
                  title="Colonne(s) sélectionnée(s):"
                  // className="gearIcon"
                  content={
                    <Checkbox.Group
                      onChange={onChangeOptions}
                      defaultValue={options}
                    >
                      <div className="d-flex flex-column">
                        <div>
                          <Checkbox value="fullName">Nom et prénom</Checkbox>
                        </div>
                        <div>
                          <Checkbox value="request">Demande</Checkbox>
                        </div>
                        <div>
                          <Checkbox value="status">Statut</Checkbox>
                        </div>
                        <div>
                          <Checkbox value="motif">Motif</Checkbox>
                        </div>
                        <div>
                          <Checkbox value="startDate">Date de début</Checkbox>
                        </div>
                        <div>
                          <Checkbox value="endDate">Date de fin</Checkbox>
                        </div>
                        <div>
                          <Checkbox value="duration">Durée</Checkbox>
                        </div>
                        <Checkbox value="answered_by">Répondu par</Checkbox>
                      </div>
                    </Checkbox.Group>
                  }
                >
                  <FontAwesomeIcon
                    icon={faGear}
                    style={{ marginLeft: "10px", cursor: "pointer" }}
                  />
                </Popover>
              </Tooltip>

              <Tooltip title="Densité">
                <Dropdown
                  menu={{
                    items,
                  }}
                >
                  <ColumnHeightOutlined
                    style={{ marginLeft: "10px", cursor: "pointer" }}
                  />
                </Dropdown>
              </Tooltip>
            </div>
          </div>
          <div className="mx-1 rounded-5">
            {type === "DEFAULT" ? (
              <Table
                className="shadow-md rounded-4"
                bordered={false}
                loading={loading}
                columns={columns}
                size={size}
                scroll={{ x: "max-content" }}
                dataSource={requestedLeaves}
              />
            ) : (
              <HistoryLeaves dateRange={dateRange} size={size} />
            )}
          </div>
          <div
            className="container mx-1 shadow-md p-3 mt-4 rounded"
            style={{ backgroundColor: "#f2f5fb" }}
          >
            <div className="mb-2">
              <Typography.Text className="fw-bold h4">
                Mes subordonnées
              </Typography.Text>
            </div>

            <SubordinatesList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeavesListEmployees;
