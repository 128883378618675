import { useContext } from "react";
import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";

import EditPassword from "../../Components/EditPassword";
import PlatformActions from "../../Components/PlatformActions";
import OtherActions from "../../Components/OtherActions";
import GlobalContext from "../../../Context/GlobalContext";

const { Panel } = Collapse;
const panelStyle = {
  marginBottom: 24,
  background: "#f2f5fb",
  borderRadius: "8px",
  border: "none",
};
const Parameters = ({ status, setStatus }) => {
  const { role } = useContext(GlobalContext);

  return (
    <div className="pt-4">
      <div className="bg-white p-4 rounded shadow-sm ">
        <div className="mb-3">
          <h4 className="fw-bold">Paramètres</h4>
        </div>

        <Collapse
          style={{ backgroundColor: "transparent" }}
          bordered={false}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
        >
          <Panel
            style={panelStyle}
            header={"Nouvelle compte admin"}
            key="1"
            disabled={true}
          ></Panel>
          {(role === "RH" || role === "SUPER_ADMIN") && (
            <Panel
              style={panelStyle}
              header={"Lister comptes admin"}
              key="2"
              disabled={true}
            ></Panel>
          )}

          <Panel style={panelStyle} header={"Modifier Mot de passe"} key="3">
            <EditPassword />
          </Panel>
          {(role === "RH" || role === "SUPER_ADMIN") && (
            <Panel
              style={panelStyle}
              header={"Actions de la platforme"}
              key="4"
            >
              <PlatformActions status={status} setStatus={setStatus} />
            </Panel>
          )}
          {(role === "RH" || role === "SUPER_ADMIN") && (
            <Panel style={panelStyle} header={"Autres paramètres"} key="5">
              <OtherActions />
            </Panel>
          )}
        </Collapse>
      </div>
    </div>
  );
};

export default Parameters;
