import React from "react";
import { Modal, Form, Input, Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import axios from "../../Utils/axios";

const ChangePasswordModal = ({ TriggerModal, Trigger }) => {
  const Navigate = useNavigate();
  const handleOk = () => {
    TriggerModal(false);
  };

  const handleChangePassword = async (formValues) => {
    if (formValues.password !== formValues.passwordConfirm) {
      return message.error("Confirmer votre mot de passe !");
    }
    try {
      const { data } = await axios.patch(
        "/api/auth/change-password",
        formValues
      );
      if (data.status === "success") {
        message.success("Le mot de passe a été changé avec succès", 10);
        localStorage.removeItem("access_token");
        Navigate("/login");
      }
    } catch (error) {
      message.error(error.response.data.message, 10);
    }
  };

  return (
    <>
      <Modal
        visible={Trigger}
        title="Changer mon mot de passe"
        onCancel={() => TriggerModal(false)}
        footer={[
          <Button key="submit" onClick={handleOk}>
            <FontAwesomeIcon
              icon={faArrowLeft}
              style={{ marginRight: "10px" }}
            />{" "}
            Retour
          </Button>,
        ]}
      >
        <Form
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
          }}
          layout="vertical"
          size="middle"
          onFinish={handleChangePassword}
        >
          <Form.Item
            label="Nouveau mot de passe"
            name="password"
            rules={[
              {
                required: true,
                message: "S’il vous plaît entrer votre mot de passe!",
              },
            ]}
          >
            <Input.Password size="large" />
          </Form.Item>
          <Form.Item
            label="Confirmer nouveau mot de passe"
            name="passwordConfirm"
            rules={[
              {
                required: true,
                message: "S’il vous plaît entrer votre mot de passe!",
              },
            ]}
          >
            <Input.Password size="large" />
          </Form.Item>
          <Form.Item>
            <div className="d-flex justify-content-between">
              <Button htmlType="submit">Réinitialiser !</Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ChangePasswordModal;
