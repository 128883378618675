import React, { useState } from "react";
import { Form, Input, Select, Button, message, DatePicker } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import {
  faPlus,
  faHeadphones,
  faComputerMouse,
  faPlug,
  faKeyboard,
  faLaptop,
  faDesktop,
  faMobile,
  faSimCard,
} from "@fortawesome/free-solid-svg-icons";

import GlobalContext from "../../../Context/GlobalContext";
import axios from "../../../Utils/axios";

import dayjs from "dayjs";

const { Option } = Select;

const EquipmentForm = () => {
  const [form] = Form.useForm();
  const { equipments, setEquipments } = useContext(GlobalContext);

  const handleFinish = async (formValues) => {
    try {
      const { data } = await axios.post("/api/equipments/", formValues);

      message.success(data.message);

      formValues._time_line = [
        {
          _start_date: dayjs(formValues.date),
          _end_date: dayjs(formValues.date),
        },
      ];
      setEquipments([formValues, ...equipments]);

      form.resetFields();
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  return (
    <div className="container bg-white p-4 rounded shadow-sm">
      <div className="mb-3">
        <h5 className="fw-bold">Ajouter un nouvel équipement</h5>
      </div>

      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 20 }}
        onFinish={handleFinish}
      >
        <Form.Item
          name="_categorie"
          rules={[
            {
              required: true,
              message: "Veuillez remplir le champ",
            },
          ]}
          label="Catégorie"
        >
          <Select placeholder="Choisir une catégorie">
            <Option value="ECRAN">
              Ecran <FontAwesomeIcon icon={faDesktop} />
            </Option>
            <Option value="PC">
              PC <FontAwesomeIcon icon={faLaptop} />
            </Option>
            <Option value="SOURIS">
              Souris <FontAwesomeIcon icon={faComputerMouse} />
            </Option>
            <Option value="CLAVIER">
              Clavier <FontAwesomeIcon icon={faKeyboard} />
            </Option>
            <Option value="CABLE">
              Cable <FontAwesomeIcon icon={faPlug} />
            </Option>
            <Option value="CASQUE">
              Casque <FontAwesomeIcon icon={faHeadphones} />
            </Option>
            <Option value="TELEPHONE_PORTABLE">
              Téléphone portable <FontAwesomeIcon icon={faMobile} />
            </Option>
            <Option value="LIGNE_TELEPHONIQUE">
              Ligne téléphonique <FontAwesomeIcon icon={faSimCard} />
            </Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="_ref"
          rules={[
            {
              required: true,
              message: "Veuillez remplir le champ",
            },
          ]}
          label="S/N"
        >
          <Input placeholder="S/N" />
        </Form.Item>
        <Form.Item
          name="_brand"
          rules={[
            {
              required: true,
              message: "Veuillez remplir le champ",
            },
          ]}
          label="Marque"
        >
          <Input placeholder="Mark (HP/DELL/SAMSUNG)" />
        </Form.Item>
        <Form.Item
          name="date"
          rules={[
            {
              required: true,
              message: "Veuillez remplir le champ",
            },
          ]}
          label="Date d'acquisition"
        >
          <DatePicker format={"DD/MM/YYYY"} className="w-100" />
        </Form.Item>
        <Form.Item name="_num_bill" label="N° de facture">
          <Input placeholder="D7845" />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            lg: { offset: 8 },
          }}
        >
          <Button htmlType="submit" type="primary">
            <FontAwesomeIcon icon={faPlus} style={{ marginRight: "5px" }} />{" "}
            Ajouter
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EquipmentForm;
