import React, { useEffect, useState } from "react";
import { Button, Modal, Typography } from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import { faBookmark, faClock } from "@fortawesome/free-solid-svg-icons";
const Popup = (props) => {
  const { isModalOpen, handleOk, handleCancel, data } = props;

  return (
    <>
      <Modal
        title={"COLLABORATEUR: " + data?.fullName}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          disabled: false,
        }}
        footer={null}
      >
        <div>
          <Typography.Text style={{ fontSize: "16px" }}>
            <FontAwesomeIcon icon={faBookmark} /> Etat:{" "}
            <Typography.Text style={{ backgroundColor: data?.color }}>
              {data?._typeLeave === "Autorisation" ? "Autorisation" : "Congé"}{" "}
              {data?._typeLeave === "Congé annuel" &&
              data?._is_half_day !== undefined
                ? " - demi journée"
                : ""}
              {data?._typeLeave === "Congé sans solde" &&
              data?._is_half_day !== undefined
                ? " - demi journée"
                : " "}
              {data?._typeLeave === "Congé Special"
                ? " (" + data?._special_leave + ")"
                : ""}
              .
            </Typography.Text>
          </Typography.Text>
          <br />
          <Typography.Text style={{ fontSize: "16px" }}>
            <FontAwesomeIcon icon={faClock} /> Durée:{" "}
            <Typography.Text style={{ fontStyle: "italic" }} mark>
              {data?._typeLeave === "Autorisation"
                ? "Le " +
                  dayjs(data.startAt).format("DD/MM/YYYY") +
                  " (" +
                  data?._duration +
                  ")"
                : data?._is_half_day !== undefined
                ? "Le " +
                  dayjs(data.startAt).format("DD/MM/YYYY") +
                  " (" +
                  data?._is_half_day +
                  ")"
                : dayjs(data.startAt).format("DD/MM/YYYY") ===
                  dayjs(data.endAt).format("DD/MM/YYYY")
                ? "Le " + dayjs(data._startAt).format("DD/MM/YYYY")
                : "De " +
                  dayjs(data.startAt).format("DD/MM/YYYY") +
                  " Jusqu'à " +
                  dayjs(data.endAt).format("DD/MM/YYYY")}
              .
            </Typography.Text>
          </Typography.Text>
        </div>
      </Modal>
    </>
  );
};
export default Popup;
