import React, { useState } from "react";
import { Form, Input, Button, Typography } from "antd";
import LoginTag from "../../Assets/Forgot-password-pana.svg";
import axios from "../../Utils/axios";
import Logo from "../../Assets/logo-neop-dev-bleu.png";
import { useNavigate } from "react-router-dom";
import ConfirmEmailModal from "../../Components/ConfirmEmailModal";

const { Text, Link } = Typography;

const ForgotPassword = () => {
  const Navigate = useNavigate();

  const [Trigger, setTrigger] = useState(false);

  const handleTrigger = (TriggerValue) => {
    setTrigger(TriggerValue);
  };

  const handleReset = async (formValues) => {
    // Navigate("/");
    try {
      const { data } = await axios.post("api/auth/forgot-password", formValues);
      if (data.status === "success") handleTrigger(true);
    } catch (e) {}
  };
  return (
    <div className="min-vh-100 h-100 bg-custom">
      <div className="container">
        <ConfirmEmailModal Trigger={Trigger} TriggerModal={handleTrigger} />
        <div className="d-flex justify-content-center px-4">
          <div className="col-lg-9 col-md-12 col-sm-12 row bg-light mt-5 bg-opacity-75 p-3 rounded-5 shadow flex-column-reverse flex-lg-row ">
            <div className="col-lg-6 col-md-6 col-sm-12 p-4">
              <h5 className="fw-bold text-center mb-4">
                Mot de passe oublié ?
              </h5>
              <h6 className="fw-bold mb-4">
                Entrez l'email de votre compte et nous vous enverrons un lien
                pour réinitialiser votre mot de passe.
              </h6>
              <Form
                labelCol={{
                  xs: { span: 24 },
                  sm: { span: 24 },
                  md: { span: 24 },
                }}
                wrapperCol={{
                  xs: { span: 24 },
                  sm: { span: 24 },
                  md: { span: 24 },
                  lg: { span: 24 },
                }}
                layout="vertical"
                size="middle"
                onFinish={handleReset}
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "S’il vous plaît entrer votre email!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item>
                  <div className="d-flex justify-content-between">
                    <Button type="primary" htmlType="submit">
                      Envoyer !
                    </Button>
                    <Link
                      className="mt-1"
                      onClick={() => {
                        Navigate("/login");
                      }}
                    >
                      Se connecter ?
                    </Link>
                  </div>
                </Form.Item>
              </Form>
              <div className="pt-5">
                <div className="d-flex justify-content-between pt-5">
                  <Text type="primary" className="mt-2" strong>
                    ©2022 Neopolis-dev
                  </Text>
                  <img src={Logo} style={{ height: "35px" }} alt="Logo" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 border-left-custom ">
              <img src={LoginTag} alt="Login" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
