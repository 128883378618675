import { Button, Form, Modal, Select, message } from "antd";
import { useContext, useEffect, useState } from "react";
import axios from "../../../Utils/axios";
import GlobalContext from "../../../Context/GlobalContext";

const AffectToModal = ({ employees, record }) => {
  const { equipments, setEquipments } = useContext(GlobalContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    const formValues = form.getFieldsValue();

    if (
      formValues._current_holder &&
      formValues._current_holder !== record._current_holder
    ) {
      try {
        const { data } = await axios.patch(
          "/api/equipments/affect/" + record._id,
          formValues
        );
        const tmp = equipments.map((elem) => {
          if (elem._id === record._id) {
            elem._status = "AFFECTED_TO";
            elem._current_holder = formValues._current_holder;
          }
          return elem;
        });
        setEquipments(tmp);
        message.success(data.message);
      } catch (error) {
        message.error(error.response.data.message);
      }
    }
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button size="small" type="primary" onClick={showModal}>
        Affecter à
      </Button>
      <Modal
        title="Affecter l'équipement à"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form}>
          <Form.Item name="_current_holder" label="Collaborateur">
            <Select defaultValue={record._current_holder}>
              {employees?.map((elem) => {
                return (
                  <Select.Option value={elem.matricule}>
                    {elem.fullName} #{elem.matricule}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default AffectToModal;
