import { useState, useEffect, useRef, useContext } from "react";
import {
  Table,
  Typography,
  Space,
  Spin,
  Button,
  Input,
  Form,
  Modal,
  InputNumber,
  message,
  Tag,
  Radio,
} from "antd";

import * as XLSX from "xlsx";
import { SearchOutlined, ExclamationCircleFilled } from "@ant-design/icons";

import Highlighter from "react-highlight-words";

import { useNavigate } from "react-router-dom";

import axios from "../../../Utils/axios";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import GlobalContext from "../../../Context/GlobalContext";

const { Text } = Typography;
const { confirm } = Modal;

const EmployeesList = () => {
  const { role } = useContext(GlobalContext);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [soldForm] = Form.useForm();

  const [size, setSize] = useState("small");
  const handleSizeChange = (e) => {
    setSize(e.target.value);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleChangeSold = async (matricule) => {
    try {
      const formValues = soldForm.getFieldsValue();
      const { data } = await axios.patch(
        "/api/employees/solde/" + matricule,
        formValues
      );
      message.success("Mise à jour avec succès");
      const temp = employees.map((employee) => {
        if (employee.matricule === matricule) {
          employee.sold = formValues.sold;
        }
        return employee;
      });

      setEmployees(temp);
    } catch (error) {}
  };

  const showConfirm = (matricule, sold) => {
    confirm({
      title: "Voulez-vous modifier le sold?",
      icon: <ExclamationCircleFilled />,
      content: (
        <Form form={soldForm} layout="vertical" className="pt-4">
          <Form.Item name="sold" label={<Text strong>Sold</Text>}>
            <InputNumber defaultValue={sold} />
          </Form.Item>
          <Form.Item name="message" label={<Text strong>Motif</Text>}>
            <Input.TextArea />
          </Form.Item>
        </Form>
      ),
      okText: "Modifier",
      onOk() {
        handleChangeSold(matricule);
      },
      onCancel() {},
    });
  };

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Recherche ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            // style={{
            //   width: 90,
            // }}
          >
            Recherche
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Réinitialiser
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Matricule",
      dataIndex: "matricule",
      ...getColumnSearchProps("matricule", "Matricule"),
    },
    {
      title: "Nom et prénom",
      dataIndex: "fullName",
      ...getColumnSearchProps("fullName", "Nom et prénom"),
    },
    {
      title: "Date d'embauche",
      dataIndex: "embaucheDate",
      sorter: (a, b) => moment(a.embaucheDate) - moment(b.embaucheDate),
      render: (text) => <Text>{moment(text).format("DD/MM/YYYY")}</Text>,
    },
    {
      title: "Solde",
      dataIndex: "sold",
      sorter: (a, b) => a.sold - b.sold,
    },
    {
      title: "Statut",
      render: (_, employee) => (
        <Text>
          {employee.status === true ? (
            <Tag color="green">Actif</Tag>
          ) : (
            <Tag color="red">Inactif</Tag>
          )}
        </Text>
      ),
      filters: [
        {
          text: <Tag color="green">Actif</Tag>,
          value: true,
        },
        {
          text: <Tag color="red">Inactif</Tag>,
          value: false,
        },
      ],
      onFilter: (value, record) => record.status === value,
    },
    {
      title: "Actions",
      render: (_, employee) => (
        <Space size="middle">
          <Button
            size="small"
            type="primary"
            onClick={() => {
              Navigate("/administration/employees/" + employee.matricule);
            }}
          >
            Profil
          </Button>

          {(role === "RH" || role === "SUPER_ADMIN") && (
            <>
              {employee.status === true ? (
                <Button
                  size="small"
                  type="primary"
                  danger
                  onClick={async () => {
                    await axios.patch(
                      "/api/auth/disable/" + employee.matricule
                    );
                    employee.status = false;
                    const temp = employees.filter((emp) =>
                      emp.matricule === employee.matricule ? employee : emp
                    );
                    setEmployees(temp);
                  }}
                >
                  Désactiver
                </Button>
              ) : (
                <Button
                  size="small"
                  onClick={async () => {
                    await axios.patch(
                      "/api/auth/activate/" + employee.matricule
                    );
                    employee.status = true;
                    const temp = employees.filter((emp) =>
                      emp.matricule === employee.matricule ? employee : emp
                    );
                    setEmployees(temp);
                  }}
                >
                  Activer
                </Button>
              )}
              <Button
                size="small"
                type="primary"
                ghost
                onClick={() => showConfirm(employee.matricule, employee.sold)}
              >
                Modifier solde
              </Button>{" "}
            </>
          )}
        </Space>
      ),
    },
  ];

  const Navigate = useNavigate();

  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleExportToXLSX = () => {
    const data = employees.map((record) => {
      return {
        "Nom et prénom": record.fullName,
        Matricule: record.matricule,
        Poste: record.Poste,
        "Numéro CIN": record.CIN,
        RIB: record.Rib,
        "Date d'embauche": moment(record.embaucheDate).format("DD/MM/YYYY"),
        "Email professionnel": record.email,
        "Email personnel": record._personal_email,
        Téléphone: record.phoneNum,
        Adresse: record.address,
        "Date de naissance": moment(record.birthDate).format("DD/MM/YYYY"),
        "Lieu de naissance": record.birthPlace,
        "Type de contrat": record.contractType,
        "Solde de congé": record.sold,
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(data);

    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(
      workbook,
      `Liste des collaborateur ${moment().format("DD/MM/YYYY HH-mm")}.xlsx`
    );
  };

  useEffect(() => {
    async function fetchEmployees() {
      const { data } = await axios.get("/api/employees/");
      if (data.status === "success") {
        setEmployees(data.employees);
        setLoading(false);
      }
    }
    fetchEmployees();
  }, []);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  /* eslint-disable-next-line */
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
  };
  return (
    <div className="pt-4">
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spin size="large" />
        </div>
      ) : (
        <div className="bg-white p-4 rounded-4 shadow-sm ">
          <div className="mb-3">
            <h4 className="fw-bold">Liste des Employées</h4>
          </div>
          <div className="d-flex justify-content-between flex-column flex-lg-row flex-md-row pb-3">
            <div className="my-1">
              <Button
                type="primary"
                onClick={handleExportToXLSX}
                disabled={!(employees.length > 0)}
              >
                <FontAwesomeIcon
                  icon={faFileExport}
                  style={{ marginRight: "5px" }}
                />{" "}
                Exporter
              </Button>
            </div>
            <div className="my-1">
              <label className="mb-1">Taille du tableau:</label>{" "}
              <Radio.Group
                value={size}
                onChange={handleSizeChange}
                buttonStyle="solid"
              >
                <Radio.Button value="large">Gande</Radio.Button>
                <Radio.Button value="middle">Moyen</Radio.Button>
                <Radio.Button value="small">Petite</Radio.Button>
              </Radio.Group>
            </div>
          </div>
          <Table
            bordered={true}
            className="shadow-sm"
            columns={columns}
            size={size}
            dataSource={employees}
            scroll={{ x: "max-content" }}
          />
        </div>
      )}
    </div>
  );
};

export default EmployeesList;
