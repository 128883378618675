import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Empty,
  Skeleton,
  Spin,
  Statistic,
  Tag,
  Timeline,
  Typography,
} from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { CaretDownOutlined } from "@ant-design/icons";
import axios from "../../../Utils/axios";
import { useNavigate } from "react-router-dom";
import GlobalContext from "../../../Context/GlobalContext";
import DocumentCard from "../../../Components/DocumentCard";
import PostCard from "../../../Components/PostCard";
import SondageCard from "../../../Components/SondageCard/SondageCard";
const Home = ({ status, setStatus }) => {
  const { setRedirectData, role } = useContext(GlobalContext);
  const Navigate = useNavigate();
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(true);
  const [todayLeaves, setTodayLeaves] = useState([]);
  const [page, setPage] = useState(1);
  const [pageNumbers, setPageNumbers] = useState(1);
  const [actualities, setActualities] = useState([]);
  const [todayRemote, setTodayRemote] = useState([]);

  useEffect(() => {
    async function getStatistics() {
      const { data } = await axios.get("/api/platform/stats");
      if (data) {
        setStats(data);
        setLoading(false);
      }
      return data;
    }

    async function getTodayLeaves() {
      const { data } = await axios.get("/api/leaves/get/today/leaves");
      setTodayLeaves(data.todayLeave);
      setTodayRemote(data.todayRemote);
      return data;
    }

    getTodayLeaves();
    getStatistics();
  }, []);

  useEffect(() => {
    async function getActualities() {
      const { data } = await axios.get(
        `/api/factory/actualities?page=${page}&pageSize=3`
      );
      setActualities([...actualities, ...data.actualities]);
      setPageNumbers(data.totalPages);
    }
    getActualities();
  }, [page]);

  const handlePlusOthers = () => {
    setRedirectData({
      type: "OTHERS",
      filters: ["PENDING"],
    });
    Navigate("/administration/requests");
  };

  const handlePlusLeaves = () => {
    setRedirectData({
      type: "DEFAULT",
      filters: ["Pending", "Validating", "Unjustified"],
    });
    Navigate("/administration/requests");
  };
  return (
    <div className="bg-white p-4 rounded-4 shadow-sm ">
      <div className="mb-3">
        <h4 className="fw-bold">Accueil</h4>
      </div>
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spin size="large" />
        </div>
      ) : (
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 border-right-custom mt-3">
            {(role === "RH" || role === "SUPER_ADMIN") && (
              <div className="bg-light shadow-sm container py-2 mb-4">
                <h4 className="text-center">
                  <span className="">Dashboard</span>
                </h4>

                <div className="d-flex  flex-md-row flex-lg-row flex-column justify-content-evenly my-4">
                  <Statistic
                    title="Collaborateurs actifs"
                    value={stats.actifUsers}
                  />
                  <Statistic
                    title="Collaborateurs non actifs"
                    value={stats.disabledUsers}
                  />
                </div>

                <div className="d-flex flex-column align-items-start align-items-lg-center my-4">
                  <div className="col-lg-8 col-md-12 col-sm-12 ">
                    <Statistic
                      title="Demandes de congé/autorisations non traitées"
                      value={stats.untreatedLeaves}
                      suffix={
                        <Button
                          size="small"
                          type="primary"
                          style={{ marginLeft: "10px", marginTop: "-5px" }}
                          onClick={handlePlusLeaves}
                        >
                          Voir plus
                        </Button>
                      }
                    />
                  </div>
                  <div className="col-lg-8 col-md-12 col-sm-12 ">
                    <Statistic
                      title="Autres demandes non traitées"
                      value={stats.untreatedRequests}
                      suffix={
                        <Button
                          size="small"
                          type="primary"
                          onClick={handlePlusOthers}
                          style={{ marginLeft: "10px", marginTop: "-5px" }}
                        >
                          Voir plus
                        </Button>
                      }
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="bg-white p-2  rounded-4 shadow-sm mb-2">
              <h4 className="text-center mb-4">
                <span>Collaborateurs indisponibles </span>
              </h4>
              <Timeline className="mt-3">
                {todayLeaves?.length > 0 ? (
                  todayLeaves.map((leave, idx) => {
                    if (
                      leave._typeLeave === "Autorisation" &&
                      !moment(leave._duration.split(" - ")[1], "HH:mm").isAfter(
                        moment()
                      )
                    ) {
                      return null;
                    }
                    return (
                      leave._typeLeave && (
                        <Timeline.Item key={idx}>
                          <Typography.Text strong>
                            {leave.fullName}
                            {" - "}{" "}
                            {leave._is_half_day
                              ? leave._typeLeave + " - demi journée"
                              : leave._typeLeave}
                          </Typography.Text>
                          <br />
                          <Typography.Text>
                            {leave._typeLeave === "Autorisation" && (
                              <>
                                <Tag color={"geekblue"}>{leave._duration}</Tag>
                              </>
                            )}
                          </Typography.Text>
                        </Timeline.Item>
                      )
                    );
                  })
                ) : (
                  <>
                    <Empty description={"Aucune collaborateurs indisponible"} />
                  </>
                )}
              </Timeline>
            </div>
            <div className="bg-white p-2  rounded-4 shadow-sm">
              <h4 className="text-center mb-4">
                <span>Collaborateurs en télétravail </span>
              </h4>
              {loading ? (
                <>
                  <Skeleton
                    active
                    size="small"
                    paragraph={{
                      rows: 1,
                    }}
                  />
                  <Skeleton
                    active
                    size="small"
                    paragraph={{
                      rows: 1,
                    }}
                  />
                </>
              ) : (
                <Timeline className="mt-3">
                  {todayRemote?.length > 0 ? (
                    todayRemote.map((leave, idx) => {
                      return (
                        leave?._except?.find(
                          (val) => val === dayjs().format("dddd")
                        ) === undefined && (
                          <Timeline.Item key={idx}>
                            <Typography.Text strong>
                              {leave.fullName}
                              {" - "}{" "}
                              {leave._is_half_day
                                ? leave._typeLeave + " - demi journée"
                                : leave._typeLeave}
                            </Typography.Text>
                          </Timeline.Item>
                        )
                      );
                    })
                  ) : (
                    <>
                      <Empty
                        description={"Aucune collaborateurs en télétravail"}
                      />
                    </>
                  )}
                </Timeline>
              )}
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 px-4 mt-3">
            <h4 className="text-center">
              <span>Historique</span>
            </h4>
            <Timeline className="mt-3">
              {stats.Archives.map((Archive, index) => {
                return (
                  <Timeline.Item
                    color={
                      Archive.By === "Administrateur" ? "#00CCFF" : "green"
                    }
                    key={index}
                  >
                    {Archive.Action} en{" "}
                    <Tag
                      color={
                        Archive.By === "Administrateur" ? "#00CCFF" : "green"
                      }
                    >
                      {moment(Number(Archive.at)).format("DD-MM-YYYY HH:mm")}
                    </Tag>
                    <p>Acteur: {Archive.By}</p>
                  </Timeline.Item>
                );
              })}
            </Timeline>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-11 bg-white p-3 rounded-4 mt-2 d-flex flex-column justify-content-center align-items-center">
              {actualities.map((elem) => {
                if (elem.type === "document") {
                  return <DocumentCard id={elem._id} />;
                } else if (elem.type === "suggestion") {
                  return <PostCard id={elem._id} access={true} />;
                } else if (elem.type === "survey") {
                  return <SondageCard id={elem._id} access={true} />;
                }
              })}
              {actualities.length > 0 ? (
                <Button
                  shape="round"
                  disabled={pageNumbers === page}
                  onClick={() => {
                    const temp = page + 1;
                    setPage(temp);
                  }}
                  className="d-flex justify-content-center align-items-center mt-2"
                >
                  <CaretDownOutlined />
                  Afficher plus
                </Button>
              ) : (
                <Empty description={"Aucune donnée"} />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
