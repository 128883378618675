import { Button, Input, message, Space, Table, Tag, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import axios from "../../Utils/axios";

const { Paragraph } = Typography;

const EquipmentsRequests = ({ filteredData, size }) => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  /* eslint-disable-next-line */
  const [expand, setExpand] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get("/api/request/self?status=EQUIPMENT");

      setRequests(data.requests);
      setLoading(false);
      return data;
    }

    fetchData();
  }, []);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  /* eslint-disable-next-line */
  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Recherche ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            // style={{
            //   width: 90,
            // }}
          >
            Recherche
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Réinitialiser
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          import {SearchOutlined} from "@ant-design/icons";
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleCancel = async (record) => {
    try {
      const { data } = await axios.patch("/api/request/cancel/" + record._id, {
        status: "CANCELED",
      });
      if (data.status === "success") {
        message.success(data.message);
        const new_requests = requests.map((r) => {
          if (r._id === record._id) {
            r.status = "CANCELLED";
            return r;
          } else {
            return r;
          }
        });
        setRequests(new_requests);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const columns = [
    {
      title: "Demande",
      dataIndex: "request",
      width: 250,
      render: (_, requestedLeaves) => (
        <>
          <Paragraph
            style={{ margin: 0 }}
            ellipsis={{
              rows: 2,
              expandable: true,
              symbol: "Plus",
              onExpand: () => {
                setExpand(true);
              },
              onEllipsis: () => {
                setExpand(false);
              },
            }}
          >
            {requestedLeaves.request}
          </Paragraph>
        </>
      ),
    },
    {
      title: "Soumis le",
      dataIndex: "send_time_date",
      render: (_, leave) => (
        <Typography.Text strong>
          {leave?.send_time_date
            ? moment(leave.send_time_date).format("DD/MM/YYYY HH:mm")
            : "N/A"}
        </Typography.Text>
      ),
      sorter: (a, b) => a.send_time_date - b.send_time_date,
    },
    // {
    //   title: "Type",
    //   dataIndex: "type",
    //   render: (type) => (
    //     <>
    //       {type === "EQUIPMENT" ? (
    //         <Tag  color="cyan">
    //           Materiel Informatique
    //         </Tag>
    //       ) : (
    //         <Tag  color="green">
    //           Modification Personnelle
    //         </Tag>
    //       )}
    //     </>
    //   ),
    //   filters: [
    //     {
    //       text: (
    //         <Tag  color="cyan">
    //           Materiel Informatique
    //         </Tag>
    //       ),
    //       value: "EQUIPMENT",
    //     },
    //     {
    //       text: (
    //         <Tag  color="green">
    //           Modification Personnelle
    //         </Tag>
    //       ),
    //       value: "MODIFICATION",
    //     },
    //   ],
    //   onFilter: (value, record) => record.type === value,
    // },
    {
      title: "Motif",
      dataIndex: "motif",
      width: 250,
      render: (_, requestedLeaves) => (
        <>
          <Paragraph
            style={{ margin: 0, whiteSpace: "pre-wrap" }}
            ellipsis={{
              rows: 2,
              expandable: true,
              symbol: "Plus",
            }}
          >
            {requestedLeaves.motif}
          </Paragraph>
        </>
      ),
    },
    {
      title: "Autres (à Préciser)",
      dataIndex: "other",
      width: 200,
      render: (text) => (
        <Typography.Paragraph
          style={{ margin: 0, whiteSpace: "pre-wrap" }}
          ellipsis={{
            rows: 2,
            expandable: true,
            symbol: "Plus",
          }}
        >
          {text}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Statut",
      dataIndex: "status",
      render: (_, record) => (
        <>
          {record.status === "PENDING" && <Tag color="orange">En attente</Tag>}
          {record.status === "APPROVED" && <Tag color="green">Accepter</Tag>}

          {record.status === "REJECTED" && <Tag color="red">Rejeter</Tag>}
          {record.status === "CANCELLED" && <Tag color="red">Annuler</Tag>}
        </>
      ),
      filters: [
        {
          text: <Tag color="orange">En attente</Tag>,
          value: "PENDING",
        },
        {
          text: <Tag color="green">Accepter</Tag>,
          value: "APPROVED",
        },
        {
          text: <Tag color="red">Rejeter</Tag>,
          value: "REJECTED",
        },
        {
          text: <Tag>Annuler</Tag>,
          value: "CANCELLED",
        },
      ],
      defaultFilteredValue: filteredData || [],
      onFilter: (value, record) => record.status === value,
    },

    {
      title: "Actions",
      render: (_, record) => (
        <>
          {record.status === "PENDING" && (
            <>
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  handleCancel(record);
                }}
              >
                Annuler
              </Button>
            </>
          )}
          {record.status === "APPROVED" && (
            <Button disabled size="small" type="primary">
              Annuler
            </Button>
          )}
          {record.status === "REJECTED" && (
            <Button disabled size="small" type="primary">
              Annuler
            </Button>
          )}
          {record.status === "CANCELLED" && (
            <Button disabled size="small" type="primary">
              Annuler
            </Button>
          )}
        </>
      ),
    },
  ];

  return (
    <div>
      <Table
        // bordered
        size={size}
        className="shadow-sm"
        columns={columns}
        bordered={true}
        loading={loading}
        dataSource={requests}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default EquipmentsRequests;
