import React, { useContext, useEffect, useState } from "react";
import { Button, Space, Table, Tag, Tooltip, Typography, message } from "antd";
import dayjs from "dayjs";
import axios from "../../Utils/axios";
import GlobalContext from "../../Context/GlobalContext";

const EquipmentList = ({}) => {
  const { equipments, setEquipments } = useContext(GlobalContext);
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      title: "Réference",
      dataIndex: "_ref",
      key: "Catégorie",
    },
    {
      title: "Catégorie",
      dataIndex: "_categorie",
      key: "Référence",
    },
    {
      title: "Marque",
      dataIndex: "_brand",
      key: "Marque",
    },

    // {
    //   title: "Date d'acquisition",
    //   key: "date",
    //   render: (record) => (
    //     <Typography.Text>
    //       {dayjs(
    //         record._time_line[record._time_line.length - 1]._start_date
    //       ).format("DD/MM/YYYY")}
    //     </Typography.Text>
    //   ),
    // },
    {
      title: "Actions",
      render: (record) => (
        <Space>
          {/* <EditEquipmentModal record={record} /> */}
          {/* {access && <EquipmentHistoryDrawer timeLine={record._time_line} />} */}
          {/* <AffectToModal record={record} employees={employees} /> */}
          <Tooltip title="Under Development">
            <Button type="primary">Demander</Button>
          </Tooltip>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(`/api/equipments/?status=AVAILABLE`);

      setEquipments(data.equipments);
      setLoading(false);
    }

    fetchData();
  }, []);

  return (
    <div className="container bg-white p-4 rounded shadow-sm mt-4">
      <div className="mb-3">
        <h4 className="fw-bold">Liste des équipements disponible</h4>
      </div>

      <Table
        dataSource={equipments}
        loading={loading}
        columns={columns}
        size="small"
      />
    </div>
  );
};

export default EquipmentList;
