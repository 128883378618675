import React from "react";
import EquipmentForm from "../../Components/EquipmentForm";
import EquipmentStock from "../../Components/EquipmentStock";
import EquipmentFormList from "../../Components/EquipmentFormList";
import { Typography } from "antd";

const EquipmentsManagment = () => {
  return (
    <div className="container">
      <div className="row justify-content-between">
        <div className="col-lg-5 my-1">
          <EquipmentForm />
          <br />
          <EquipmentFormList />
        </div>
        <div className="col-lg-7 my-1">
          <EquipmentStock />
          <div className="bg-white my-3  p-4 rounded-2 shadow-sm">
            <div className="mb-3">
              <h5 className="fw-bold">Note</h5>
            </div>
            <div>
              <Typography.Paragraph>
                Si vous allez importer un document, assurez-vous que les
                catégories sont toutes en majuscules et qu'il s'agit de l'une de
                celles-ci :
                <ul>
                  <li>ECRAN</li>
                  <li>PC</li>
                  <li>SOURIS</li>
                  <li>CLAVIER</li>
                  <li>CABLE</li>
                  <li>CASQUE</li>
                  <li>TELEPHONE_PORTABLE</li>
                  <li>LIGNE_TELEPHONIQUE</li>
                </ul>
              </Typography.Paragraph>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EquipmentsManagment;
