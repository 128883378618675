import React, { useState } from "react";
// import UploadInput from "../../../Components/UploadInput";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import { useEffect } from "react";
import moment from "moment";
import { Button, Typography } from "antd";
import dayjs from "dayjs";

const EquipmentFormList = () => {
  const [uploadedData, setUploadedData] = useState();
  const handleUpload = (info) => {
    const { status, originFileObj } = info.fileList[info.fileList.length - 1];

    const reader = new FileReader();

    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(sheet, { raw: true });
      setUploadedData(json);
    };
    reader.readAsBinaryString(originFileObj);
  };

  const handleDownload = () => {
    const data = [
      {
        Réference: "EXP: M000004",
        Catégorie: "CLAVIER",
        Marque: "DELL",
        "Date d'acquisition": "Format: JJ/MM/AAAA",
        "Numéro de facture": "NDEV-755469",
      },
    ];

    const worksheet = XLSX.utils.json_to_sheet(data);

    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(
      workbook,
      `Liste d'equipements ${moment().format("DD/MM/YYYY HH-mm")}.xlsx`
    );
  };

  // const convertExcelToJson = (base64ExcelData) => {
  //   const arrayBuffer = base64ToArrayBuffer(base64ExcelData);
  //   const workbook = XLSX.read(arrayBuffer, { type: "array" });
  //   const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  //   const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  //   return jsonData;
  // };

  // const base64ToArrayBuffer = (base64) => {
  //   const binaryString = window.atob(base64);
  //   const len = binaryString.length;
  //   const bytes = new Uint8Array(len);
  //   for (let i = 0; i < len; i++) {
  //     bytes[i] = binaryString.charCodeAt(i);
  //   }
  //   return bytes.buffer;
  // };

  useEffect(() => {
    console.log(uploadedData);
  }, [uploadedData]);

  return (
    <div className="container bg-white p-4 rounded shadow-sm">
      <div className="mb-3">
        <h5 className="fw-bold">Ajouter à partir un document Excel</h5>
      </div>
      <Typography.Text>
        Téléchargez et remplissez ce document avant de télécharger le fichier.
      </Typography.Text>
      <Button
        disabled
        style={{ marginLeft: "10px", marginRight: "10px" }}
        // type="primary"
        onClick={handleDownload}
      >
        Télécharger
      </Button>
      <Dragger
        disabled
        style={{ marginTop: "10px" }}
        type="file"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        onChange={handleUpload}
        beforeUpload={() => false}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Cliquez ou faites glisser le fichier dans cette zone
        </p>
      </Dragger>
      <Button type="primary" disabled style={{ marginTop: "10px" }}>
        Importer
      </Button>
    </div>
  );
};

export default EquipmentFormList;
