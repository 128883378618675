import { useEffect, useState } from "react";
import axios from "../../../Utils/axios";
import {
  Form,
  Input,
  DatePicker,
  Select,
  Modal,
  Radio,
  Button,
  Checkbox,
} from "antd";

import UploadInput from "../../../Components/UploadInput";

import StepsForm from "../../Components/StepsForm";
import { createProfile } from "../../../API/employeeAPICalls";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import FormItem from "antd/es/form/FormItem";
import SelfAccountForm from "../../Components/SelfAccountForm";

const { Option } = Select;

const EmployeeForm = () => {
  const Navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [isAlternate, setIsAlternate] = useState(false);
  const [initFormValues, setInitFormValues] = useState({
    type: "",
    isFull: false,
  });
  const [form] = Form.useForm();
  const [initForm] = Form.useForm();
  const setCV = (base64) => {
    form.setFieldsValue({
      CV: base64,
    });
  };

  const setProfilePic = (base64) => {
    form.setFieldsValue({
      imgProfile: base64,
    });
  };

  const setCIN = (base64) => {
    form.setFieldsValue({
      _cin_File: base64,
    });
  };

  const setRIB = (base64) => {
    form.setFieldsValue({
      _rib_File: base64,
    });
  };

  const handleSubmit = async () => {
    const formValues = form.getFieldsValue(true);
    setLoading(true);
    try {
      const { data } = await createProfile(formValues);
      if (data) {
        Modal.success({ title: "Le profil a été crée avec succès !" });
        Navigate("/administration/employees/list");
      }
    } catch (error) {
      Modal.error({
        title: "Impossible de créer un compte!",
        content: <p>{error.response.data.message}</p>,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    async function fetchProfiles() {
      const { data } = await axios.get("api/employees/");
      setEmployees(data.employees);
      return data;
    }

    fetchProfiles();
  }, []);

  const steps = [
    {
      title: "Généralités",
      content: (
        <>
          <Form.Item
            label="Nom et prénom"
            name="fullName"
            validateTrigger={"onDone"}
            rules={[{ required: true, message: "Merci de remplir le champ" }]}
          >
            <Input
              onChange={(e) => {
                form.setFieldsValue({
                  fullName: e.target.value.replace(/[^a-zA-Z ]/g, ""),
                });
              }}
            />
          </Form.Item>

          <Form.Item
            label="Numéro du télèphone"
            name="phoneNum"
            validateTrigger={"onDone"}
            rules={[{ required: true, message: "Merci de remplir le champ" }]}
          >
            <PhoneInput defaultCountry="TN" placeholder="12 345 678" />
          </Form.Item>

          <Form.Item
            label="Email Professionel"
            name="email"
            validateTrigger={"onDone"}
            rules={[
              { type: "email", message: "Veuillez entrer un email valid" },
              { required: true, message: "Merci de remplir le champ" },
              {
                validator: (_, value) => {
                  if (value) {
                    if (!value.includes("@neopolis-dev.com")) {
                      return Promise.reject(
                        "l'email professionnel doit se terminer par '@neopolis-dev.com'"
                      );
                    } else {
                      return Promise.resolve();
                    }
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Input placeholder="email@neopolis-dev.com" />
          </Form.Item>

          <Form.Item
            label="Email Personelle"
            name="_personal_email"
            validateTrigger={"onDone"}
            rules={[
              { required: true, message: "Merci de remplir le champ" },
              { type: "email", message: "veuillez entrer un email valid" },
            ]}
          >
            <Input type="email" />
          </Form.Item>

          <Form.Item label="Alternance" name="_is_alternate">
            <Radio.Group
              onChange={(e) => {
                form.setFieldsValue({
                  ...form.getFieldsValue(),
                  _is_alternate: e.target.value,
                });
                setIsAlternate(e.target.value);
              }}
              defaultValue={false}
              buttonStyle="solid"
            >
              <Radio.Button value={true}>Oui</Radio.Button>
              <Radio.Button value={false}>Non</Radio.Button>
            </Radio.Group>
          </Form.Item>
          {isAlternate && (
            <Form.Item
              label="École"
              name="_alternant_school"
              rules={[{ required: true, message: "Veuillez remplir le champ" }]}
            >
              <Input />
            </Form.Item>
          )}

          <Form.Item
            name="CV"
            label="CV"
            validateTrigger={"onDone"}
            rules={[{ required: true, message: "Merci de remplir le champ" }]}
          >
            <UploadInput setFile={setCV} />
          </Form.Item>

          <Form.Item name="imgProfile" label="Photo de profil">
            <UploadInput setFile={setProfilePic} />
          </Form.Item>
        </>
      ),
    },
    {
      title: "Personnelle",
      content: (
        <>
          <Form.Item
            label="Date de naissance"
            name="birthDate"
            rules={[{ required: true, message: "Merci de remplir le champ" }]}
          >
            <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
          </Form.Item>

          <Form.Item
            label="Lieu naissance"
            name="birthPlace"
            rules={[{ required: true, message: "Merci de remplir le champ" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Adresse"
            name="address"
            rules={[{ required: true, message: "Merci de remplir le champ" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            maxLength={8}
            minLength={8}
            type="Number"
            label="CIN"
            name="CIN"
            rules={[
              {
                pattern: /^[\d]{8,8}$/,
                message: "CIN ne doit avoir que 8 chiffres",
              },
              { required: true, message: "Merci de remplir le champ" },
            ]}
            validateTrigger="onDone"
          >
            <Input
              style={{ width: "100%" }}
              maxLength={8}
              onChange={(e) => {
                form.setFieldsValue({
                  CIN: e.target.value.replace(/[^0-9]/g, ""),
                });
              }}
            />
          </Form.Item>

          <Form.Item
            name="_cin_File"
            label="Joindre CIN"
            rules={[{ required: true, message: "Merci de remplir le champ" }]}
          >
            <UploadInput setFile={setCIN} />
          </Form.Item>
        </>
      ),
    },
    {
      title: "Liés au travail",
      content: (
        <>
          <Form.Item
            label="Type de contrat"
            name="contractType"
            rules={[{ required: true, message: "Merci de remplir le champ" }]}
          >
            <Select placeholder="Type de contract (CDI, CIVP, KARAMA, CDD)">
              <Option key="1" value="CDI">
                CDI
              </Option>
              <Option key="2" value="CIVP">
                CIVP
              </Option>
              <Option key="3" value="KARAMA">
                KARAMA
              </Option>
              <Option key="4" value="CDD">
                CDD
              </Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Date d'embauche"
            name="embaucheDate"
            rules={[{ required: true, message: "Merci de remplir le champ" }]}
          >
            <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
          </Form.Item>
          <Form.Item label="Responsable" name="_idResponsable">
            <Select placeholder="choisir un responsable pour cet employé">
              {employees.map((emp) => {
                return (
                  <Option value={emp.matricule}>
                    {emp.fullName} #{emp.matricule}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            label="Matricule"
            name="matricule"
            rules={[{ required: true, message: "Merci de remplir le champ" }]}
          >
            <Input maxLength={5} />
          </Form.Item>

          <Form.Item
            label="Poste"
            name="Poste"
            rules={[{ required: true, message: "Merci de remplir le champ" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="RIB"
            name="Rib"
            rules={[
              { required: true, message: "Merci de remplir le champ" },
              {
                pattern: /^[\d]{20,20}$/,
                message: "RIB ne doit avoir que 20 chiffres",
              },
            ]}
          >
            <Input
              style={{ width: "100%" }}
              maxLength={20}
              onChange={(e) => {
                form.setFieldsValue({
                  Rib: e.target.value.replace(/[^0-9]/g, ""),
                });
              }}
            />
          </Form.Item>
          <Form.Item
            maxLength={2}
            minLength={0}
            type="Number"
            label="Solde"
            name="sold"
            rules={[{ required: true, message: "Merci de remplir le champ" }]}
            validateTrigger="onDone"
          >
            <Input
              style={{ width: "100%" }}
              onChange={(e) => {
                form.setFieldsValue({
                  sold: e.target.value.replace(/[^0-9 .]/g, ""),
                });
              }}
            />
          </Form.Item>

          <Form.Item
            name="_rib_File"
            label="Joindre RIB"
            rules={[{ required: true, message: "Merci de remplir le champ" }]}
          >
            <UploadInput setFile={setRIB} />
          </Form.Item>
        </>
      ),
    },
    {
      title: "Personne à prévenir en cas d'urgence",
      content: (
        <>
          <Form.Item
            name="_urgence_name"
            label="Nom et prénom"
            rules={[{ required: true, message: "Merci de remplir le champ" }]}
          >
            <Input
              onChange={(e) => {
                form.setFieldsValue({
                  _urgence_name: e.target.value.replace(/[^a-zA-Z ]/g, ""),
                });
              }}
            />
          </Form.Item>

          <Form.Item
            validateTrigger={"onDone"}
            name="_urgence_relation"
            label="Relation"
            rules={[{ required: true, message: "Merci de remplir le champ" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="_urgence_phone"
            label="Numéro du télèphone"
            rules={[{ required: true, message: "Merci de remplir le champ" }]}
          >
            <PhoneInput defaultCountry="TN" placeholder="12 345 678" />
          </Form.Item>
        </>
      ),
    },
  ];

  const handleFinish = (formValues) => {
    if (!formValues.isFull) {
      formValues.isFull = false;
    }
    setInitFormValues(formValues);
  };

  return (
    <div
      className="container bg-white p-4 rounded-4 shadow-sm"
      style={{ minHeight: "75vh" }}
    >
      <div className="mb-3">
        <h4 className="fw-bold">Ajouter Employé</h4>
      </div>
      {initFormValues.type === "" && (
        <Form
          form={initForm}
          className="d-flex flex-column justify-content-between"
          style={{ minHeight: "60vh" }}
          onFinish={handleFinish}
        >
          <div className="d-flex justify-content-center align-items-center flex-column">
            <FormItem name="type">
              <Radio.Group className="d-flex" buttonStyle="solid">
                <Radio.Button
                  value="Stagiare"
                  style={{ width: "9rem", height: "9rem" }}
                  className="d-flex align-items-center  justify-content-center"
                >
                  Stagiare
                </Radio.Button>
                <Radio.Button
                  value="Collaborateur"
                  style={{ width: "9rem", height: "9rem" }}
                  className="d-flex align-items-center  justify-content-center"
                >
                  Collaborateur
                </Radio.Button>
              </Radio.Group>
            </FormItem>
            <Form.Item name="isFull" valuePropName="checked">
              <Checkbox>Parcours complet?</Checkbox>
            </Form.Item>
          </div>
          <div className="d-flex  d-flex justify-content-evenly">
            <Button disabled={true}>
              <FontAwesomeIcon
                icon={faArrowLeft}
                style={{ marginRight: "5px", marginTop: "5px" }}
              />{" "}
              Précédent
            </Button>
            <Button type="primary" htmlType="submit">
              Suivant{" "}
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ marginLeft: "5px", marginTop: "5px" }}
              />
            </Button>
          </div>
        </Form>
      )}
      {initFormValues.isFull === true &&
        initFormValues.type === "Collaborateur" && (
          <Form
            form={form}
            disabled={loading}
            labelCol={{
              sm: { span: 10 },
              md: { span: 8 },
              lg: { span: 7 },
            }}
            wrapperCol={{
              sm: { span: 16 },
              md: { span: 14 },
              lg: { span: 16 },
            }}
            layout="horizontal"
            size="middle"
            onFinish={handleSubmit}
          >
            <StepsForm
              setInitFormValues={setInitFormValues}
              steps={steps}
              form={form}
              loading={loading}
            />
          </Form>
        )}
      {initFormValues.isFull === false &&
        initFormValues.type === "Collaborateur" && (
          <SelfAccountForm employees={employees} />
        )}
    </div>
  );
};

export default EmployeeForm;
