import { useState, useEffect, useContext } from "react";
import {
  Drawer,
  Avatar,
  Dropdown,
  Menu,
  Typography,
  Tooltip,
  Badge,
  Button,
  Layout,
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  message,
  Space,
} from "antd";

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PullRequestOutlined,
  LinkOutlined,
} from "@ant-design/icons";

import iconDark from "../../Assets/brand_icon_dark.svg";
import iconLight from "../../Assets/brand_icon_light.svg";

import logoDark from "../../Assets/brand_large_dark.png";
import logoLight from "../../Assets/brand_large_light.svg";

import closeIcon from "../../Assets/close.svg";
import eyeIcon from "../../Assets/eye.svg";
import eyeHiddenIcon from "../../Assets/eyehidden.svg";

import AvatarProfile from "../../Assets/avatar-svgrepo-com.svg";

import { Link, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";

import axios from "../../Utils/axios";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faUser,
  faHouse,
  faFileCirclePlus,
  faBell,
  faCalendarXmark,
  faLaptopFile,
  faPeopleLine,
  faFileLines,
  faToolbox,
  faPeopleGroup,
  faQuestionCircle,
  faLightbulb,
  faFileInvoice,
  faStamp,
  faPlusCircle,
  faFolderTree,
  faCalendarDays,
} from "@fortawesome/free-solid-svg-icons";

import LocalStorageService from "../../Utils/localStorageService";
import GlobalContext from "../../Context/GlobalContext";
import ThemeEditor from "../ThemeEditor";
import axiosInstance from "../../Utils/axios";

const { Text } = Typography;
const { Header, Sider, Content } = Layout;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const LayoutClient = () => {
  const { profile, setProfile, theme } = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();

  const handleRequestProd = async () => {
    try {
      form
        .validateFields()
        .then(async (values) => {
          modal.confirm({
            title: "Confirmation de Conformité du Déploiement",
            width: 500,
            content: (
              <p>
                Je certifie par la présente que toutes les conditions requises
                pour le déploiement en production sont remplies:
                <br />
                <br />
                <ul>
                  <li>L'intégration de Sentry</li>
                  <li>La mise en place d'une procédure de versionnage</li>
                  <li>La mise à jour du changelog sur le tag Git.</li>
                </ul>
              </p>
            ),
            okText: "Confirmer",
            onOk: async () => {
              try {
                const { data } = await axiosInstance.post(
                  "/api/platform/requrest/prod",
                  values
                );

                message.success(data.message);

                form.resetFields();

                setOpen(false);
              } catch (error) {
                message.error(
                  error?.response?.data?.message ||
                    "Erreur lors de la soumission de la demande"
                );
              }
            },
          });
        })
        .catch((error) => {
          message.error("Veuillez vérifier tous les champs");
        });
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const [collapsed, setCollapsed] = useState(false);

  const items = [
    getItem(
      <Link to="/" style={{ textDecoration: "none", fontWeight: "bold" }}>
        Accueil
      </Link>,
      "1",
      <FontAwesomeIcon icon={faHouse} />
    ),
    getItem(
      <Link
        to="/manage/self"
        style={{ textDecoration: "none", fontWeight: "bold" }}
      >
        Mes demandes
      </Link>,
      "2",
      <FontAwesomeIcon icon={faFileLines} />
    ),
    getItem(
      <Link
        to="/team/calendar"
        style={{ textDecoration: "none", fontWeight: "bold" }}
      >
        Calendrier de l'équipe
      </Link>,
      "13",

      <FontAwesomeIcon icon={faCalendarDays} />
    ),
    getItem(
      <Link
        to="/absences"
        style={{ textDecoration: "none", fontWeight: "bold" }}
      >
        Mes absences
      </Link>,
      "3",
      <FontAwesomeIcon icon={faCalendarXmark} />
    ),
    getItem(
      <Link
        to="/request"
        style={{ textDecoration: "none", fontWeight: "bold" }}
      >
        Nouvelle demande
      </Link>,
      "4",
      <FontAwesomeIcon icon={faFileCirclePlus} />
    ),
    getItem(
      <Link
        to="/manage/subordinates"
        style={{ textDecoration: "none", fontWeight: "bold" }}
      >
        Gérer mes subordonnés
      </Link>,
      "5",
      <FontAwesomeIcon icon={faPeopleLine} />
    ),
    getItem(
      /* eslint-disable-next-line */
      <a style={{ textDecoration: "none", fontWeight: "bold" }}>
        Materiel Informatique
      </a>,
      "51",
      <FontAwesomeIcon icon={faLaptopFile} />,
      [
        getItem(
          <Link
            to="/request/equipments"
            style={{ textDecoration: "none", fontWeight: "bold" }}
          >
            Nouvelle demande
          </Link>,
          "511",
          <FontAwesomeIcon icon={faPlusCircle} />
        ),
        getItem(
          <Link
            to="/self/equipments"
            style={{ textDecoration: "none", fontWeight: "bold" }}
          >
            Mes équipements
          </Link>,
          "512",
          <FontAwesomeIcon icon={faFolderTree} />
        ),
      ]
    ),
    getItem(
      <Link to="/tools" style={{ textDecoration: "none", fontWeight: "bold" }}>
        Boîte à outils
      </Link>,
      "6",
      <FontAwesomeIcon icon={faToolbox} />
    ),
    getItem(
      <Link
        to="/trombinoscope"
        style={{ textDecoration: "none", fontWeight: "bold" }}
      >
        Trombinoscope
      </Link>,
      "7",
      <FontAwesomeIcon icon={faPeopleGroup} />
    ),
    getItem(
      <Link to="/faq" style={{ textDecoration: "none", fontWeight: "bold" }}>
        FAQ
      </Link>,
      "8",
      <FontAwesomeIcon icon={faQuestionCircle} />
    ),
    getItem(
      <Tooltip title="Not working for the moment!">
        <Link
          // to="/suggestions"
          to="#"
          style={{ textDecoration: "none", fontWeight: "bold" }}
        >
          Suggestion / Sondage
        </Link>
      </Tooltip>,
      "10",
      <FontAwesomeIcon icon={faLightbulb} />
    ),
    getItem(
      <Tooltip title="Not working for the moment!">
        <Link
          // to="work/certificate"
          to="#"
          style={{ textDecoration: "none", fontWeight: "bold" }}
        >
          Attestation de travail
        </Link>
      </Tooltip>,
      "11",
      <FontAwesomeIcon icon={faStamp} />
    ),
    getItem(
      <Tooltip title="Coming soon">
        <Link to="#" style={{ textDecoration: "none", fontWeight: "bold" }}>
          Fiche de paie
        </Link>
      </Tooltip>,
      "12",
      <FontAwesomeIcon icon={faFileInvoice} />
    ),

    getItem(
      // eslint-disable-next-line
      <a
        href="#"
        onClick={() => {
          Navigate("/login");
          localStorage.removeItem("access_token");
        }}
        style={{ textDecoration: "none", fontWeight: "bold" }}
      >
        Se déconnecter
      </a>,
      "14",
      <FontAwesomeIcon icon={faArrowRightFromBracket} />
    ),
  ];

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
      if (windowSize.innerWidth < 992) {
        setCollapsed(true);
      }
    }
    window.addEventListener("resize", handleWindowResize);

    handleWindowResize();

    async function GetNotifications(autoNotif) {
      const { data } = await axios.get("/api/notif/employee");

      setNotifications(data.notifs);
      setAutoNotif(false);
      LocalStorageService().setNotif(false);
      return data;
    }

    GetNotifications(autoNotif);

    async function fetchProfilePhotoAndName() {
      const { data } = await axios.get("/api/employees/me/mini");
      setProfile(data);
      setLoading(false);
      return data;
    }

    fetchProfilePhotoAndName();
    // eslint-disable-next-line
  }, []);

  const [visibleDrawer, setVisibleDrawer] = useState(false);

  // eslint-disable-next-line
  const showDrawerVisible = () => {
    setVisibleDrawer(true);
  };

  const onCloseDrawer = () => {
    setVisibleDrawer(false);
  };

  const Navigate = useNavigate();

  const handleLogin = async () => {
    try {
      setLoading(true);
      setTimeout(() => {
        localStorage.removeItem("access_token");
        Navigate("/login");
      }, [1000]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleProfile = () => {
    Navigate("/profile");
  };

  const handleHome = () => {
    Navigate("/");
  };

  const [autoNotif, setAutoNotif] = useState(
    JSON.parse(LocalStorageService().getNotif() || true)
  );

  const [Notifications, setNotifications] = useState([]);

  const handleDisableNotif = async (id, status) => {
    try {
      const { data } = await axios.patch("/api/notif/read/" + id, {
        status: status,
      });
      if (data.status === "success") {
        const new_array = Notifications.map((notif) => {
          if (notif._id === id) {
            notif.read = status;
            return notif;
          } else {
            return notif;
          }
        });
        setNotifications(new_array);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleDeleteNotif = async (id, status) => {
    try {
      const { data } = await axios.patch("/api/notif/deleted/" + id, {
        status: status,
      });
      if (data.status === "success") {
        let new_array = [];
        Notifications.forEach((notif) => {
          if (notif._id !== id) new_array.push(notif);
        });
        setNotifications(new_array);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const menu = (
    <Menu
      items={[
        {
          key: "3",
          label: (
            <Text strong style={{ cursor: "pointer" }}>
              <FontAwesomeIcon icon={faCalendarXmark} /> Solde: {profile.sold}
            </Text>
          ),
        },

        {
          key: "2",
          label: (
            <div onClick={handleProfile}>
              <Text strong style={{ cursor: "pointer" }}>
                <FontAwesomeIcon icon={faUser} /> Profil
              </Text>
            </div>
          ),
        },
        {
          key: "1",
          label: (
            <div onClick={handleLogin}>
              <Text strong style={{ cursor: "pointer" }}>
                <FontAwesomeIcon icon={faArrowRightFromBracket} /> Se
                deconnecter
              </Text>
            </div>
          ),
        },
      ]}
    />
  );

  return loading ? (
    <div className="loading">
      <div class="dot-spinner">
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
        <div class="dot-spinner__dot"></div>
      </div>
    </div>
  ) : (
    <Layout className="vh-100 vw-100 overflow-hidden ">
      <Sider
        className="collaps-sider"
        style={{ backgroundColor: theme.siderBg }}
        trigger={null}
        breakpoint="lg"
        collapsedWidth="0"
        collapsible
        collapsed={collapsed}
        width={270}
      >
        <div className="text-center mt-2 mb-3">
          {!collapsed ? (
            <img
              src={theme.logo === "light" ? logoLight : logoDark}
              style={{
                height: "35px",
                cursor: "pointer",
                transition: "width 0.2s ease",
              }}
              alt="Neopolis"
              onClick={handleHome}
            />
          ) : (
            <img
              src={theme.logo === "light" ? iconLight : iconDark}
              style={{
                height: "30px",
                cursor: "pointer",
                transition: "width 0.2s ease",
              }}
              alt="Neopolis"
              onClick={handleHome}
            />
          )}
        </div>
        <div style={{ height: "calc(100vh - 120px)" }}>
          <Menu
            className="h-100"
            mode="inline"
            theme={theme.siderMode}
            style={{ overflowY: "scroll", backgroundColor: theme.siderBg }}
            inlineCollapsed={collapsed}
            items={items}
          />
        </div>
        <div>
          <Menu
            mode="inline"
            theme={theme.siderMode}
            style={{ backgroundColor: theme.siderBg }}
            items={[
              getItem(
                <div
                  style={{ fontWeight: "bold" }}
                  onClick={() => setOpen(true)}
                >
                  Déploiement Prod
                </div>,
                1,
                <PullRequestOutlined />
              ),
            ]}
          />
        </div>
      </Sider>
      <Layout style={{ background: "#F1F6F9" }}>
        <Header
          style={{
            padding: 0,
            background: theme.headerBg,
          }}
          className="d-flex justify-content-between border-bottom "
        >
          <div>
            <Button
              className="btn-collaps"
              type="text"
              icon={
                collapsed ? (
                  <MenuUnfoldOutlined />
                ) : (
                  <MenuFoldOutlined
                    style={{
                      color:
                        windowSize.innerWidth < 500 && theme.logo !== "light"
                          ? "white"
                          : "black",
                    }}
                  />
                )
              }
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
              }}
            />
          </div>
          <div className="mx-3">
            <span
              style={{ paddingRight: "5px", cursor: "pointer" }}
              onClick={showDrawerVisible}
            >
              <Badge dot={Notifications.length > 0}>
                <Avatar icon={<FontAwesomeIcon icon={faBell} />} />
              </Badge>
            </span>

            <Dropdown
              overlay={menu}
              placement="bottomRight"
              trigger={["click"]}
            >
              <Avatar
                style={{
                  cursor: "pointer",
                  width: "32.45px",
                  height: "32.45px",
                }}
                src={profile.imgProfile || AvatarProfile}
              />
            </Dropdown>
          </div>
        </Header>
        <Drawer
          title="Notifications"
          placement="right"
          onClose={onCloseDrawer}
          visible={visibleDrawer}
        >
          {Notifications.map((notif) => {
            return notif.title !== "" && !notif.read ? (
              <div
                className="rounded d-flex mt-2"
                style={{
                  backgroundColor: "#F0FAFD",
                  paddingRight: "10px",
                  paddingBottom: "5px",
                }}
              >
                <div className="px-2 py-1">
                  <span style={{ fontSize: "16px" }}>
                    <FontAwesomeIcon
                      icon={faBell}
                      style={{ color: "#2c5397" }}
                    />
                  </span>
                </div>
                <div className="d-flex justify-content-between w-100">
                  <div className="d-flex flex-column py-1">
                    <Text style={{ fontSize: "12px", fontWeight: "600" }}>
                      {notif.title}
                    </Text>
                    <Text style={{ fontSize: "12px", fontWeight: "400" }}>
                      {moment(notif.send_time_date).format(
                        "MMMM DD YYYY - hh:mm:ss"
                      )}
                    </Text>
                    <Text style={{ fontSize: "12px", fontWeight: "400" }}>
                      {notif.sender}
                    </Text>
                  </div>
                  <div className="d-flex flex-column justify-content-between">
                    <img
                      onClick={() => {
                        handleDeleteNotif(notif._id, true);
                      }}
                      src={closeIcon}
                      title
                      alt="close_icon"
                      style={{ cursor: "pointer" }}
                    />
                    <Tooltip
                      title="Cliquez pour désactiver"
                      placement="topRight"
                    >
                      <img
                        onClick={() => {
                          handleDisableNotif(notif._id, true);
                        }}
                        src={eyeHiddenIcon}
                        alt="hidden_icon"
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="rounded d-flex mt-2"
                style={{
                  backgroundColor: "#F0FAFD",
                  paddingRight: "10px",
                  paddingBottom: "7px",
                }}
              >
                <div className="px-2 py-1">
                  <span style={{ fontSize: "16px" }}>
                    <FontAwesomeIcon
                      icon={faBell}
                      style={{ color: "#2c5397" }}
                    />
                  </span>
                </div>
                <div className="d-flex justify-content-between w-100">
                  <div className="d-flex flex-column py-1">
                    <Text
                      style={{
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "#90A9CB",
                      }}
                    >
                      {notif.title}
                    </Text>
                    <Text
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#90A9CB",
                      }}
                    >
                      {moment(notif.send_time_date).format(
                        "MMMM DD YYYY - hh:mm:ss"
                      )}
                    </Text>
                    <Text
                      style={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#90A9CB",
                      }}
                    >
                      {notif.sender}
                    </Text>
                  </div>
                  <div className="d-flex flex-column justify-content-between">
                    <img
                      onClick={() => {
                        handleDeleteNotif(notif._id, true);
                      }}
                      src={closeIcon}
                      alt="close_icon"
                      style={{ cursor: "pointer" }}
                    />

                    <img src={eyeIcon} alt="hidden_icon" />
                  </div>
                </div>
              </div>
            );
          })}
        </Drawer>
        <Modal
          title="Nouvelle demande de déploiement en prod"
          centered
          open={open}
          okText="Soumettre"
          onOk={handleRequestProd}
          onCancel={() => setOpen(false)}
          width={1000}
        >
          {contextHolder}
          <Form form={form} layout="vertical" className="row">
            <div className="col-lg-6">
              <Form.Item
                label="Type de la demande"
                name="requestType"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select>
                  <Select.Option value="Demande de déploiement PROD">
                    Demande de déploiement PROD
                  </Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className="col-lg-6 d-flex align-items-center">
              <Typography.Link
                href="https://wiki.neopolis-dev.com/fr/livraison"
                style={{ fontWeight: 700 }}
                target="_blank"
              >
                <LinkOutlined /> Voir le détails de la procédure de déploiement
              </Typography.Link>
            </div>
            <div className="col-lg-8">
              <Form.Item
                name="gitUrl"
                label="Adresse GIT"
                rules={[
                  { required: true },
                  { type: "url" },
                  { type: "string", min: 6 },
                  {
                    pattern: "^https:\\/\\/git\\.interface-crm\\.com\\/.*$",
                    message: "Veuillez entrer une adresse git valide",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="col-lg-4">
              <Form.Item
                name="instanceName"
                label="Nom de l'instance"
                rules={[
                  { whitespace: true },
                  { required: true },
                  { type: "string", min: 2 },
                ]}
              >
                <Input placeholder="Nom de client concerné" />
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Space>
                <Form.Item
                  label="Numéro de Version"
                  name="versionNumber"
                  className="w-100"
                  tooltip={
                    <Typography.Link
                      target="_blank"
                      href="https://docs.google.com/spreadsheets/d/1MvSQadPBeqXaMCSmmftCvmLKHC8_FL29c4KvGSlOErY/edit?usp=sharing"
                    >
                      <LinkOutlined /> Voir le document de versionning
                    </Typography.Link>
                  }
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                    },
                  ]}
                >
                  <Input className="w-100" />
                </Form.Item>
                <div style={{ fontSize: "12px" }}>
                  Copier coller a partir du{" "}
                  <Typography.Link
                    style={{ fontSize: "12px" }}
                    target="_blank"
                    href="https://docs.google.com/spreadsheets/d/1MvSQadPBeqXaMCSmmftCvmLKHC8_FL29c4KvGSlOErY/edit?usp=sharing"
                  >
                    <LinkOutlined /> Document de versionning
                  </Typography.Link>
                </div>
              </Space>
            </div>
            <div className="col-lg-6">
              <Form.Item
                label="Tag Git"
                name="gitTag"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item
                label="Date de traitement souhaitée"
                name="requestedDate"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker
                  showTime={{
                    format: "HH:mm",
                  }}
                  className="w-100"
                  format={"YYYY-MM-DD HH:mm"}
                  disabledDate={(current) =>
                    current && current < moment().endOf("day").subtract(1, "d")
                  }
                />
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item
                label="Nature du déploiement"
                name="deployNature"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select>
                  <Select.Option value="Nouvelles Fonctionnalités">
                    Nouvelles Fonctionnalités
                  </Select.Option>
                  <Select.Option value="Hotfix">Hotfix</Select.Option>
                </Select>
              </Form.Item>
            </div>

            <Form.Item
              label="Notes de Version"
              name="versionNotes"
              rules={[
                {
                  required: true,
                  whitespace: true,
                },
              ]}
            >
              <Input.TextArea rows={5} />
            </Form.Item>

            <Form.Item label="Liens Utiles" name="usefullLink">
              <Space direction="vertical" className="w-100 ">
                <Input.TextArea rows={5} className="w-100" />
              </Space>
            </Form.Item>
            <Form.Item
              label="Instructions (Pour le cas de Trinita Prod)"
              name="instructions"
            >
              <Input.TextArea rows={5} className="w-100" />
            </Form.Item>
          </Form>
        </Modal>
        <Content
          className="container h-100 pb-4"
          style={{
            overflowY: "scroll",
          }}
        >
          <Outlet />
          <ThemeEditor />
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutClient;
