import { Button, ColorPicker, Form, Modal, Radio } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import GlobalContext from "../../Context/GlobalContext";

const CustomThemeEditorModal = () => {
  const { theme, setTheme } = useContext(GlobalContext);
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState();
  const [colorHexSider, setColorHexSider] = useState("#1677ff");
  const [colorHexPrimary, setColorHexPrimary] = useState("#1677ff");
  const [colorHexHeader, setColorHexHeader] = useState("#1677ff");
  const [formatHex, setFormatHex] = useState("hex");

  useEffect(() => {
    setMode(theme.logo);
    setColorHexPrimary(theme.color);
    setColorHexHeader(theme.headerBg);
    setColorHexSider(theme.siderBg);
  }, []);

  const siderBg = useMemo(
    () =>
      typeof colorHexSider === "string"
        ? colorHexSider
        : colorHexSider.toHexString(),
    [colorHexSider]
  );
  const headerBg = useMemo(
    () =>
      typeof colorHexHeader === "string"
        ? colorHexHeader
        : colorHexHeader.toHexString(),
    [colorHexHeader]
  );

  const color = useMemo(
    () =>
      typeof colorHexPrimary === "string"
        ? colorHexPrimary
        : colorHexPrimary.toHexString(),
    [colorHexPrimary]
  );

  const handleChangeTheme = () => {
    setTheme({
      color: color,
      logo: mode,
      siderBg: siderBg,
      headerBg: headerBg,
      siderMode: mode,
    });

    localStorage.setItem(
      "custom_theme",
      JSON.stringify({
        color: color,
        logo: mode,
        siderBg: siderBg,
        headerBg: headerBg,
        siderMode: mode,
      })
    );
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        type="primary"
        style={{
          width: "100%",
          fontSize: "1.5rem",
          backgroundColor: "#FFFFFF",
          border: "none",
          backgroundImage:
            "linear-gradient(45deg, #FFFFFF 0%, #6284FF 50%, #FF0000 100%)",
          height: "130px",
          margin: ".5rem 0",
        }}
      >
        Custom
        <br />
        <span style={{ fontSize: "1rem" }}>(Beta)</span>
      </Button>
      <Modal
        title="Éditeur de thème personnalisé"
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={1000}
      >
        <Form className="row bg-light p-4 rounded-4 shadow-sm">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <Form.Item label="Couleur principale">
              <ColorPicker
                format={formatHex}
                value={colorHexPrimary}
                onChange={setColorHexPrimary}
                onFormatChange={setFormatHex}
              />
            </Form.Item>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <Form.Item label="Couleur header">
              <ColorPicker
                format={formatHex}
                value={colorHexHeader}
                onChange={setColorHexHeader}
                onFormatChange={setFormatHex}
              />
            </Form.Item>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <Form.Item label="Couleur sidebar">
              <ColorPicker
                format={formatHex}
                value={colorHexSider}
                onChange={setColorHexSider}
                onFormatChange={setFormatHex}
              />
            </Form.Item>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <Form.Item label="Mode">
              <Radio.Group
                value={mode}
                onChange={(e) => setMode(e.target.value)}
              >
                <Radio value="dark">Dark</Radio>
                <Radio value="light">Light</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
        </Form>
        <div className="d-flex justify-content-center mt-2">
          <Button type="primary" onClick={handleChangeTheme}>
            Sauvgarder
          </Button>
        </div>
      </Modal>
    </>
  );
};
export default CustomThemeEditorModal;
