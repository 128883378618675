import { useState, useEffect, useRef, useContext } from "react";

import { SearchOutlined, ColumnHeightOutlined } from "@ant-design/icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faFileExport,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Space,
  Table,
  Tag,
  Typography,
  message,
  Form,
  Modal,
  Input,
  Tooltip,
  Popconfirm,
  Select,
  DatePicker,
  Radio,
  Dropdown,
  Checkbox,
  Popover,
} from "antd";
import dayjs from "dayjs";
import Highlighter from "react-highlight-words";
import * as XLSX from "xlsx";
import { Link } from "react-router-dom";
import axios from "../../../Utils/axios";
import moment from "moment";
import OtherRequests from "../../Components/OtherRequests";
import GlobalContext from "../../../Context/GlobalContext";
import EquipmentsRequests from "../../Components/EquipmentsRequests";
const { confirm } = Modal;

const options = [
  {
    label: "Autres",
    value: "OTHERS",
  },
  {
    label: "Attestation du travail",
    value: "CERTIF",
  },
];

const { Text, Paragraph } = Typography;
const { TextArea } = Input;

const { RangePicker } = DatePicker;
const RequestsList = () => {
  const { redirectData } = useContext(GlobalContext);
  const [size, setSize] = useState("small");
  const [optionsTab, setOptions] = useState([
    "fullName",
    "request",
    "status",
    "motif",
    "startDate",
    "endDate",
    "duration",
    "answered_by",
  ]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [dateRange, setDateRange] = useState("");

  const [currentValue, setCurrentValue] = useState("OTHERS");

  const onChange = ({ target: { value } }) => {
    setCurrentValue(value);
  };

  const handleSizeChange = (new_size) => {
    setSize(new_size);
  };

  const items = [
    {
      key: "1",
      label: (
        <a
          href="#"
          className="text-decoration-none"
          onClick={() => {
            handleSizeChange("large");
          }}
        >
          Plus grand
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          href="#"
          className="text-decoration-none"
          onClick={() => {
            handleSizeChange("middle");
          }}
        >
          Moyenne
        </a>
      ),
    },
    {
      key: "3",
      label: (
        <a
          href="#"
          className="text-decoration-none"
          onClick={() => {
            handleSizeChange("small");
          }}
        >
          Compact
        </a>
      ),
    },
  ];

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Recherche ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
          >
            Recherche
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Réinitialiser
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const [form] = Form.useForm();
  // eslint-disable-next-line
  const [expand, setExpand] = useState(true);

  const columns = [
    optionsTab.find((elem) => elem === "fullName")
      ? {
          title: "Nom et prénom",
          dataIndex: "fullName",
          key: "1",
          ...getColumnSearchProps("fullName", "Nom et prénom"),
          render: (_, requestedLeaves) => (
            <Link
              to={"/administration/employees/" + requestedLeaves.idEmployee}
            >
              #{requestedLeaves.idEmployee} - {requestedLeaves.fullName}
            </Link>
          ),
        }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "request")
      ? {
          title: "Demande",
          dataIndex: "_typeLeave",
          filters: [
            {
              text: "Congé Special",
              value: "Congé Special",
            },
            {
              text: "Congé Maladie",
              value: "Congé Maladie",
            },
            {
              text: "Congé Annuel",
              value: "Congé annuel",
            },
            {
              text: "Congé sans sold",
              value: "Congé sans sold",
            },
            {
              text: "Télétravail",
              value: "Télétravail",
            },
            {
              text: "Absence",
              value: "Absence",
            },
          ],
          onFilter: (value, record) => record._typeLeave.startsWith(value),
          filterSearch: true,
          key: "2",
          width: 200,
          render: (_, requestedLeaves) => (
            <>
              {requestedLeaves._special_leave ? (
                <Paragraph
                  style={{ margin: 0 }}
                  ellipsis={{
                    rows: 2,
                    expandable: true,
                    symbol: "Plus",
                    onExpand: () => {
                      setExpand(true);
                    },
                    onEllipsis: () => {
                      setExpand(false);
                    },
                  }}
                >
                  {requestedLeaves._typeLeave +
                    `${
                      requestedLeaves._special_leave &&
                      " - " + requestedLeaves._special_leave
                    }`}
                </Paragraph>
              ) : (
                <Text>{requestedLeaves._typeLeave}</Text>
              )}
            </>
          ),
        }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "motif")
      ? {
          title: "Motif",
          dataIndex: "_event",
          key: "4",
          width: 300,
          render: (_, requestedLeaves) => (
            <>
              <Paragraph
                style={{ margin: 0, whiteSpace: "pre-wrap" }}
                ellipsis={{
                  rows: 2,
                  expandable: true,
                  symbol: "Plus",
                }}
              >
                {requestedLeaves._event ||
                  requestedLeaves._motif ||
                  requestedLeaves._typeLeave}
              </Paragraph>
            </>
          ),
        }
      : { width: 0, className: "d-none" },

    optionsTab.find((elem) => elem === "startDate")
      ? {
          title: "Date de début",
          dataIndex: "BeginingDate",
          // ...getColumnSearchProps("BeginingDate"),
          key: "5",
          sorter: (a, b) => a.BeginingDate - b.BeginingDate,
          render: (text) => <Text>{moment(text).format("DD/MM/YYYY")}</Text>,
        }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "endDate")
      ? {
          title: "Date de fin",
          dataIndex: "EndingDate",
          // ...getColumnSearchProps("EndingDate"),
          key: "6",
          sorter: (a, b) => a.EndingDate - b.EndingDate,
          render: (text) => <Text>{moment(text).format("DD/MM/YYYY")}</Text>,
        }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "duration")
      ? {
          title: "Durée",
          render: (_, requestedLeaves) => (
            <Tooltip
              color="cyan"
              title={
                requestedLeaves._typeLeave === "Télétravail" ? (
                  <div>
                    Sauf:
                    <br />
                    {requestedLeaves._except.map((elem) => {
                      return <Tag color="blue">{elem}</Tag>;
                    })}
                  </div>
                ) : requestedLeaves._typeLeave === "Autorisation" ? (
                  requestedLeaves._duration
                ) : !requestedLeaves._is_half_day ? (
                  requestedLeaves.DaysNumber + " Jour(s)"
                ) : (
                  requestedLeaves._is_half_day
                )
              }
            >
              <Text>
                {requestedLeaves._typeLeave === "Autorisation"
                  ? requestedLeaves._duration
                  : !requestedLeaves._is_half_day
                  ? requestedLeaves.DaysNumber + " Jour(s)"
                  : requestedLeaves._is_half_day}
              </Text>
            </Tooltip>
          ),
        }
      : { width: 0, className: "d-none" },
    {
      title: "Soumis le",
      dataIndex: "_posted_date",
      sorter: (a, b) => moment(a._posted_date) - moment(b._posted_date),
      render: (_, leave) => (
        <Text strong>
          {leave?._posted_date
            ? moment(leave._posted_date).format("DD/MM/YYYY HH:mm")
            : "N/A"}
        </Text>
      ),
    },
    optionsTab.find((elem) => elem === "answered_by")
      ? {
          title: "Répondu par",
          dataIndex: "_answered_by",
          render: (_, requestedLeaves) => (
            <>
              {requestedLeaves.status === "Pending" ? (
                <Tag color={"orange"}>En attente</Tag>
              ) : requestedLeaves._answered_by ? (
                <Tooltip
                  title={
                    "Répondu à " +
                    moment(requestedLeaves._answered_at).format(
                      "DD/MM/YYYY HH:mm"
                    )
                  }
                >
                  <Text> {requestedLeaves._answered_by} </Text>
                </Tooltip>
              ) : (
                <Text>N/A</Text>
              )}
            </>
          ),
        }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "status")
      ? {
          title: "Statut",
          dataIndex: "status",
          key: "7",
          filters: [
            {
              text: <Tag color={"orange"}>En Attente</Tag>,
              value: "Pending",
            },

            {
              text: <Tag color={"green"}>Approuvée</Tag>,
              value: "Accepted",
            },

            {
              text: <Tag color={"cyan"}>En attente d'approbation</Tag>,
              value: "Validating",
            },

            {
              text: <Tag color={"red"}>Rejeté</Tag>,
              value: "Rejected",
            },

            {
              text: <Tag color="red">Annulée</Tag>,
              value: "Canceled",
            },

            {
              text: <Tag color={"gold"}>Non encore justifié</Tag>,
              value: "Unjustified",
            },

            {
              text: <Tag>échue</Tag>,
              value: "Failed",
            },
          ],
          defaultFilteredValue:
            redirectData?.type === "DEFAULT" && redirectData?.filters
              ? redirectData?.filters
              : [],
          onFilter: (value, record) => record.status.includes(value),
          render: (_, record) => (
            <>
              {record.status === "Pending" && (
                <Tag color={"orange"}>En attente</Tag>
              )}
              {record.status === "Accepted" && (
                <Tag color={"green"}>Approuvée</Tag>
              )}
              {record.status === "Validating" && (
                <Tag color={"cyan"}>En attente d'approbation</Tag>
              )}
              {record.status === "Rejected" && <Tag color={"red"}>Rejeté</Tag>}
              {record.status === "Canceled" && (
                <Tag color={"geekblue"}>Annuler</Tag>
              )}
              {record.status === "Unjustified" && (
                <Tag color={"gold"}>Non encore justifié</Tag>
              )}
              {record.status === "Failed" && (
                <Tooltip title="Considérée comme absence non justifiée">
                  <Tag>échue</Tag>
                </Tooltip>
              )}
            </>
          ),
        }
      : { width: 0, className: "d-none" },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          {record.status === "Pending" && (
            <>
              {/* eslint-disable-next-line */}
              <Popconfirm
                okText="Oui"
                title="Vous êtes sûr d'approuver la demande?"
                onConfirm={() => {
                  handleActions({
                    _id: record._id,
                    value: "Accepted",
                  });

                  const newRecord = requestedLeaves.map((RL) => {
                    if (RL._id === record._id) {
                      RL.status = "Accepted";
                    }
                    return RL;
                  });

                  setRequestedLeaves(newRecord);
                }}
              >
                <Button
                  size="small"
                  type="primary"
                  style={{ marginRight: "5px" }}
                >
                  Approuver
                </Button>
              </Popconfirm>
              {/* eslint-disable-next-line */}
              <Popconfirm
                okText="Oui"
                title="Vous êtes sûr de rejeter la demande?"
                onConfirm={() => {
                  Modal.info({
                    title: "Rejet de la demande",
                    content: (
                      <>
                        <Form form={form} layout="vertical">
                          <Form.Item name="message" label="Reponse:">
                            <TextArea />
                          </Form.Item>
                        </Form>
                      </>
                    ),
                    onOk: async () => {
                      const formValues = form.getFieldsValue();
                      await axios.patch("/api/leaves/options/" + record._id, {
                        message: formValues.message,
                        status: "Rejected",
                        by: "Admin",
                        self: false,
                      });
                      const newRecord = requestedLeaves.map((RL) => {
                        if (RL._id === record._id) {
                          RL.status = "Rejected";
                        }
                        return RL;
                      });

                      setRequestedLeaves(newRecord);
                    },
                  });
                }}
              >
                <Button type="primary" size="small" danger>
                  Rejeter
                </Button>
              </Popconfirm>
            </>
          )}{" "}
          {(record.status === "Accepted" ||
            record.status === "Rejected" ||
            record.status === "Failed" ||
            record.status === "Canceled") && (
            <Button type="primary" size="small" disabled>
              Valider
            </Button>
          )}
          {record.status === "Unjustified" && (
            <Button type="primary" size="small" disabled>
              Valider
            </Button>
          )}
          {record.status === "Validating" && (
            <>
              <Button
                onClick={() => {
                  confirm({
                    title: "Valider la demande de " + record.fullName,
                    okText: "Valider !",
                    content: (
                      <>
                        <Button>
                          <a
                            href={record.OptionalFiles}
                            download={"Justification de " + record.fullName}
                          >
                            {" "}
                            Télècharger <FontAwesomeIcon icon={faDownload} />
                          </a>
                        </Button>

                        <Button
                          style={{ marginLeft: "23px" }}
                          type="primary"
                          danger
                          ghost
                          onClick={() => {
                            handleActions({
                              _id: record._id,
                              value: "Unjustified",
                            });
                          }}
                        >
                          Rejetée
                        </Button>
                      </>
                    ),
                    onOk: () => {
                      handleActions({
                        _id: record._id,
                        value: "Accepted",
                      });
                    },
                  });
                }}
                type="primary"
                size="small"
              >
                Valider
              </Button>
            </>
          )}
        </>
      ),
    },
  ];

  const [loading, setLoading] = useState(true);
  const [requestedLeaves, setRequestedLeaves] = useState([]);
  const [type, setType] = useState(
    redirectData?.type ? redirectData?.type : "DEFAULT"
  );

  const handleActions = async (options) => {
    try {
      const { data } = await axios.patch("/api/leaves/options/" + options._id, {
        status: options.value,
        by: "Admin",
        self: false,
      });
      if (data.status === "success") {
        message.info(data.message);
        const newRequestedLeaves = requestedLeaves.map((RL) => {
          if (RL._id === options._id) {
            RL.status = options.value;
          }
          return RL;
        });
        setRequestedLeaves(newRequestedLeaves);
      }
    } catch (error) {}
  };

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(
        `/api/leaves/monthly/pending${
          dateRange
            ? "?startDate=" +
              dateRange.startDate +
              "&endDate=" +
              dateRange.endDate
            : ""
        }`
      );
      setRequestedLeaves(data.LeavesPerMonth);
      setLoading(false);
      return data;
    }
    fetchData();
  }, [dateRange]);

  const handleExportToXLSX = () => {
    const data = requestedLeaves.map((record) => {
      return {
        "Nom et Prénom": record.fullName,
        Motif: record._motif,
        "Date début demande": moment(record.BeginingDate).format("DD/MM/YYYY"),
        "Date fin demande": moment(record.EndingDate).format("DD/MM/YYYY"),
        Demandeur: record.sender,
        "Identifiant Employée": record.idEmployee,
        statut: record.status,
        "Durée de la  demande": record._duration,
        "Type du demande": record._typeLeave,
        "posted date": record._posted_date,
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(data);

    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(
      workbook,
      `Liste des demandes de congés/autorisations${moment().format(
        "DD/MM/YYYY HH-mm"
      )}.xlsx`
    );
  };

  const onChangeRangeDate = (date) => {
    if (date) {
      const startDate = dayjs(date[0]).format("DD-MM-YYYY");
      const endDate = dayjs(date[1]).format("DD-MM-YYYY");
      setDateRange({ startDate: startDate, endDate: endDate });
    } else {
      setDateRange("");
    }
  };

  const onChangeOptions = (checkedValues) => {
    setOptions(checkedValues);
  };

  return (
    <div className="bg-white p-4 rounded shadow-sm ">
      <div className="  mb-3">
        <h4 className="fw-bold">Liste des demandes</h4>
      </div>
      <div className="">
        <div
          className="d-flex justify-content-between flex-column flex-lg-row flex-md-row mb-2 "
          style={{
            backgroundColor: "#f2f5fb",
            padding: "20px",
            borderRadius: "13px",
          }}
        >
          <div>
            <Select
              style={{ width: "100%" }}
              defaultValue={type}
              placeholder="Choisissez les demandes que vous souhaitez gerer"
              onChange={(e) => {
                setType(e);
              }}
            >
              <Select.Option value="DEFAULT">
                Demandes de congés/autorisations
              </Select.Option>
              <Select.Option value="OTHERS">Autres demandes</Select.Option>
              <Select.Option value="EQUIPMENT">
                Demandes de materiel informatique
              </Select.Option>
            </Select>
            {type === "OTHERS" && (
              <div className=" mt-2">
                <label>Demandes: </label>{" "}
                <Radio.Group
                  options={options}
                  onChange={onChange}
                  value={currentValue}
                  optionType="button"
                  buttonStyle="solid"
                />
              </div>
            )}{" "}
            <div
              className={requestedLeaves.length > 0 ? `mb-2 pb-2` : `d-none`}
            >
              <Button
                type="primary"
                className="mt-2"
                onClick={handleExportToXLSX}
                disabled={!(requestedLeaves.length > 0)}
              >
                <FontAwesomeIcon
                  icon={faFileExport}
                  style={{ marginRight: "5px" }}
                />
                Exporter
              </Button>
            </div>
          </div>
          <div>
            <div className="d-flex align-items-center">
              <RangePicker
                onChange={onChangeRangeDate}
                className="w-100"
                placement="bottomLeft"
              />

              <Tooltip title="Paramètres du tableau">
                <Popover
                  trigger="click"
                  placement="bottom"
                  title="Colonne(s) sélectionnée(s):"
                  // className="gearIcon"
                  content={
                    <Checkbox.Group
                      onChange={onChangeOptions}
                      defaultValue={optionsTab}
                    >
                      <div className="d-flex flex-column">
                        <div>
                          <Checkbox value="fullName">Nom et prénom</Checkbox>
                        </div>
                        <div>
                          <Checkbox value="request">Demande</Checkbox>
                        </div>
                        <div>
                          <Checkbox value="status">Statut</Checkbox>
                        </div>
                        <div>
                          <Checkbox value="motif">Motif</Checkbox>
                        </div>
                        <div>
                          <Checkbox value="startDate">Date de début</Checkbox>
                        </div>
                        <div>
                          <Checkbox value="endDate">Date de fin</Checkbox>
                        </div>
                        <div>
                          <Checkbox value="duration">Durée</Checkbox>
                        </div>
                        <Checkbox value="answered_by">Répondu par</Checkbox>
                      </div>
                    </Checkbox.Group>
                  }
                >
                  <FontAwesomeIcon
                    icon={faGear}
                    style={{ marginLeft: "10px", cursor: "pointer" }}
                  />
                </Popover>
              </Tooltip>
              <Tooltip title="Densité">
                <Dropdown
                  menu={{
                    items,
                  }}
                >
                  <ColumnHeightOutlined
                    style={{ marginLeft: "10px", cursor: "pointer" }}
                  />
                </Dropdown>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>

      {type === "DEFAULT" && (
        <Table
          className="shadow-sm"
          bordered
          size={size}
          columns={columns}
          loading={loading}
          dataSource={requestedLeaves}
          scroll={{ x: "max-content" }}
        />
      )}
      {type === "OTHERS" && (
        <OtherRequests
          dateRange={dateRange}
          currentValue={currentValue}
          size={size}
          filteredData={
            redirectData?.type !== "DEFAULT" && redirectData?.filters
              ? redirectData.filters
              : []
          }
        />
      )}
      {type === "EQUIPMENT" && <EquipmentsRequests size={size} />}
    </div>
  );
};

export default RequestsList;
