import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import "./Button.css";

import moment from "moment";

import { fetchProfile } from "../../Actions/profileActions";

import { Typography, Spin, Image, Button, Descriptions, Tooltip } from "antd";

import ChangePasswordModal from "../../Components/ChangePasswordModal";
import RequestChangeModal from "../../Components/RequestChangeModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faUser,
  faPhone,
  faDownload,
  faFingerprint,
  faClipboardUser,
  faMapLocationDot,
  faIdCard,
  faMoneyCheckDollar,
  faCalendarDay,
  faEnvelope,
  faFileContract,
  faPeopleLine,
  faUserEdit,
  faKey,
} from "@fortawesome/free-solid-svg-icons";

import AvatarProfile from "../../Assets/avatar-svgrepo-com.svg";
import ImageModal from "../../Components/ImageModal";
import EditProfileDrawer from "../../Components/EditProfileDrawer";
import { useParams } from "react-router-dom";

const { Text, Paragraph } = Typography;

const Profile = () => {
  const [refresh, setRefresh] = useState(false);
  const profile = useSelector((state) => state.profile);
  const [mobileBreakPoint, setMobileBreakPoint] = useState(true);
  const [visible, setVisible] = useState(false);

  const [loading, setLoading] = useState(true);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  const { matricule } = useParams();
  const [drawerSize, setDrawerSize] = useState(320);

  useEffect(() => {
    function handleWindowResize() {
      if (getWindowSize().innerWidth < 992) {
        setDrawerSize(320);
        setMobileBreakPoint(true);
      } else if (getWindowSize().innerWidth >= 992) {
        setDrawerSize(720);
        setMobileBreakPoint(false);
      }
    }
    window.addEventListener("resize", handleWindowResize);

    handleWindowResize();
  }, []);

  useEffect(() => {
    dispatch(fetchProfile());
    // eslint-disable-next-line
  }, [refresh]);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const [emailTrigger, setEmailTrigger] = useState(false);
  const [passwordTrigger, setPasswordTrigger] = useState(false);

  const dispatch = useDispatch();

  const handleClickEmailTrigger = () => {
    // setEmailTrigger(true);
    setVisible(true);
  };

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const handleEmailTrigger = (ETriggerValue) => {
    setEmailTrigger(ETriggerValue);
  };

  const handleClickPasswordTrigger = () => {
    setPasswordTrigger(true);
  };

  const handlePasswordTrigger = (PTriggerValue) => {
    setPasswordTrigger(PTriggerValue);
  };

  return (
    <div className="pb-5 pt-4">
      <ChangePasswordModal
        Trigger={passwordTrigger}
        TriggerModal={handlePasswordTrigger}
      />
      <RequestChangeModal
        Trigger={emailTrigger}
        onClose={onClose}
        TriggerModal={handleEmailTrigger}
        visible={visible}
        variables={profile}
        drawerSize={drawerSize}
        //  setVariables={setProfileUser}
      />

      {profile.length === 0 ? (
        <div className="d-flex justify-content-center pt-4">
          <Spin size="large" />
        </div>
      ) : (
        <div className="bg-white p-4  rounded shadow-sm ">
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-12">
              <Image
                style={{ borderRadius: "50%" }}
                src={profile.imgProfile || AvatarProfile}
              />
            </div>

            <div className="col-lg-9 col-md-8 col-sm-12">
              <Descriptions
                title={
                  <Text strong style={{ fontSize: "24px" }}>
                    Mes informations
                  </Text>
                }
                column={{
                  xxl: 3,
                  xl: 3,
                  lg: 2,
                  md: 1,
                  sm: 1,
                  xs: 1,
                }}
              >
                <Descriptions.Item
                  label={
                    <Text strong style={{ fontSize: "16px" }}>
                      <FontAwesomeIcon icon={faUser} /> Nom et prénom
                    </Text>
                  }
                >
                  <Text style={{ fontSize: "16px" }}>
                    {profile.fullName || "Indéfinie"}
                  </Text>
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <Text strong style={{ fontSize: "16px" }}>
                      <FontAwesomeIcon icon={faPhone} /> Téléphone
                    </Text>
                  }
                >
                  <Text style={{ fontSize: "16px" }}>
                    {profile.phoneNum || "Indéfinie"}
                  </Text>
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <Text strong style={{ fontSize: "16px" }}>
                      <FontAwesomeIcon icon={faFingerprint} /> Matricule
                    </Text>
                  }
                >
                  <Text style={{ fontSize: "16px" }}>
                    {profile.matricule || "Indéfinie"}
                  </Text>
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <Text strong style={{ fontSize: "16px" }}>
                      <FontAwesomeIcon icon={faClipboardUser} /> Poste
                    </Text>
                  }
                >
                  <Text style={{ fontSize: "16px" }}>
                    {profile.Poste || "Indéfinie"}
                  </Text>
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <Text strong style={{ fontSize: "16px" }}>
                      <FontAwesomeIcon icon={faMapLocationDot} /> Adresse
                    </Text>
                  }
                >
                  <Text style={{ fontSize: "16px" }}>
                    {profile.address || "Indéfinie"}
                  </Text>
                </Descriptions.Item>
              </Descriptions>

              <Button
                className="my-1"
                style={{ marginRight: "7px" }}
                onClick={handleClickEmailTrigger}
                type="primary"
              >
                <FontAwesomeIcon
                  icon={faUserEdit}
                  style={{ paddingRight: "6px" }}
                />{" "}
                Demander des modifications
              </Button>
              <Button
                className="my-1"
                style={{ marginRight: "7px" }}
                onClick={handleClickPasswordTrigger}
                type="primary"
              >
                <FontAwesomeIcon icon={faKey} style={{ paddingRight: "6px" }} />{" "}
                Changer mon mot de passe
              </Button>

              <ImageModal
                imageSrc={profile.imgProfile}
                refresh={refresh}
                setRefresh={handleRefresh}
              />
              {/* <EditProfileDrawer variables={profile} drawerSize={drawerSize} /> */}
            </div>
          </div>
          <div className="mt-5 shadow-md py-3 px-4">
            <Descriptions
              column={{
                xxl: 3,
                xl: 3,
                lg: 2,
                md: 1,
                sm: 1,
                xs: 1,
              }}
              title={
                <Text style={{ color: "#284c8b", fontSize: "20px" }} strong>
                  <FontAwesomeIcon
                    icon={faUser}
                    style={{ marginRight: "10px" }}
                  />{" "}
                  Informations personnelles
                </Text>
              }
            >
              <Descriptions.Item
                label={
                  <Text style={{ fontSize: "16px" }}>
                    <FontAwesomeIcon icon={faIdCard} /> Numéro CIN
                  </Text>
                }
              >
                <Paragraph copyable style={{ fontSize: "16px" }}>
                  {profile.CIN || "Indéfinie"}
                </Paragraph>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Text style={{ fontSize: "16px" }}>
                    <FontAwesomeIcon icon={faMoneyCheckDollar} /> RIB
                  </Text>
                }
              >
                <Paragraph copyable style={{ fontSize: "16px" }}>
                  {profile.Rib || "Indéfinie"}
                </Paragraph>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Text style={{ fontSize: "16px" }}>
                    {" "}
                    <FontAwesomeIcon
                      icon={faCalendarDay}
                      style={{ paddingRight: "5px" }}
                    />{" "}
                    Date de naissance
                  </Text>
                }
              >
                <Paragraph copyable style={{ fontSize: "16px" }}>
                  {moment(profile.birthDate).format("DD/MM/YYYY") ||
                    "Indéfinie"}
                </Paragraph>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Text style={{ fontSize: "16px" }}>
                    {" "}
                    <FontAwesomeIcon icon={faMapLocationDot} /> Lieu de
                    naissance
                  </Text>
                }
              >
                <Paragraph copyable style={{ fontSize: "16px" }}>
                  {profile.birthPlace || "Indéfinie"}
                </Paragraph>
              </Descriptions.Item>
            </Descriptions>
          </div>
          <div className="mt-3 shadow-md py-3 px-4">
            <Descriptions
              column={{
                xxl: 3,
                xl: 3,
                lg: 2,
                md: 1,
                sm: 1,
                xs: 1,
              }}
              layout={mobileBreakPoint ? "vertical" : "horizontal"}
              title={
                <Text style={{ color: "#284c8b", fontSize: "20px" }} strong>
                  <FontAwesomeIcon
                    icon={faPhone}
                    style={{ marginRight: "10px" }}
                  />{" "}
                  Contact
                </Text>
              }
            >
              <Descriptions.Item
                span={2}
                label={
                  <Text style={{ fontSize: "16px" }}>
                    <FontAwesomeIcon icon={faEnvelope} /> Email professionnel
                  </Text>
                }
              >
                <Paragraph copyable style={{ fontSize: "16px" }}>
                  {profile.email || "Indéfinie"}
                </Paragraph>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Text style={{ fontSize: "16px" }}>
                    <FontAwesomeIcon icon={faPhone} /> Téléphone
                  </Text>
                }
              >
                <Paragraph copyable style={{ fontSize: "16px" }}>
                  {profile.phoneNum || "Indéfinie"}
                </Paragraph>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Text style={{ fontSize: "16px" }}>
                    <FontAwesomeIcon icon={faEnvelope} /> Email personnel
                  </Text>
                }
              >
                <Paragraph copyable style={{ fontSize: "16px" }}>
                  {profile._personal_email || "Indéfinie"}
                </Paragraph>
              </Descriptions.Item>
            </Descriptions>
            <div
              className="container shadow-md p-3 rounded"
              style={{ backgroundColor: "#F0FAFD" }}
            >
              <Descriptions
                column={{
                  xxl: 3,
                  xl: 3,
                  lg: 2,
                  md: 1,
                  sm: 1,
                  xs: 1,
                }}
                title={
                  <Text style={{ color: "#284c8b", fontSize: "18px" }} strong>
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{ marginRight: "10px" }}
                    />{" "}
                    Personne à prévenir en cas d'urgence
                  </Text>
                }
              >
                <Descriptions.Item
                  label={
                    <Text style={{ fontSize: "14px" }}>
                      <FontAwesomeIcon icon={faUser} /> Nom et prénom
                    </Text>
                  }
                >
                  <Paragraph copyable style={{ fontSize: "14px" }}>
                    {profile._urgence_name || "Indéfinie"}
                  </Paragraph>
                </Descriptions.Item>

                <Descriptions.Item
                  label={
                    <Text style={{ fontSize: "14px" }}>
                      <FontAwesomeIcon icon={faPeopleLine} /> Relation
                    </Text>
                  }
                >
                  <Paragraph copyable style={{ fontSize: "14px" }}>
                    {profile._urgence_relation || "Indéfinie"}
                  </Paragraph>
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <Text style={{ fontSize: "14px" }}>
                      <FontAwesomeIcon icon={faPhone} /> Téléphone
                    </Text>
                  }
                >
                  <Paragraph copyable style={{ fontSize: "14px" }}>
                    {profile._urgence_phone || "Indéfinie"}
                  </Paragraph>
                </Descriptions.Item>
              </Descriptions>
            </div>
          </div>
          <div className="mt-3 shadow-md py-3 px-4">
            <Descriptions
              column={{
                xxl: 3,
                xl: 3,
                lg: 2,
                md: 1,
                sm: 1,
                xs: 1,
              }}
              title={
                <Text style={{ color: "#284c8b", fontSize: "20px" }} strong>
                  <FontAwesomeIcon
                    icon={faBriefcase}
                    style={{ marginRight: "10px" }}
                  />{" "}
                  Informations liées au travail
                </Text>
              }
            >
              <Descriptions.Item
                label={
                  <Text style={{ fontSize: "16px" }}>
                    <FontAwesomeIcon icon={faFingerprint} /> Matricule
                  </Text>
                }
              >
                <Paragraph copyable strong style={{ fontSize: "16px" }}>
                  {profile.matricule || "Indéfinie"}
                </Paragraph>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Text style={{ fontSize: "16px" }}>
                    <FontAwesomeIcon
                      icon={faCalendarDay}
                      style={{ paddingRight: "5px" }}
                    />{" "}
                    Date d'embauche
                  </Text>
                }
              >
                <Paragraph copyable style={{ fontSize: "16px" }}>
                  {moment(profile.embaucheDate).format("DD/MM/YYYY") ||
                    "Indéfinie"}
                </Paragraph>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Text style={{ fontSize: "16px" }}>
                    <FontAwesomeIcon icon={faClipboardUser} /> Poste
                  </Text>
                }
              >
                <Paragraph copyable style={{ fontSize: "16px" }}>
                  {profile.Poste || "Indéfinie"}
                </Paragraph>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Text style={{ fontSize: "16px" }}>
                    <FontAwesomeIcon icon={faFileContract} /> Type de contrat
                  </Text>
                }
              >
                <Paragraph copyable style={{ fontSize: "16px" }}>
                  {profile.contractType || "Indéfinie"}
                </Paragraph>
              </Descriptions.Item>
            </Descriptions>
            <div
              className="container shadow-md p-3 rounded"
              style={{ backgroundColor: "#F0FAFD" }}
            >
              <Descriptions
                column={{
                  xxl: 3,
                  xl: 3,
                  lg: 2,
                  md: 1,
                  sm: 1,
                  xs: 1,
                }}
                title={
                  <Text style={{ color: "#284c8b", fontSize: "18px" }} strong>
                    <FontAwesomeIcon
                      icon={faDownload}
                      style={{ marginRight: "10px" }}
                    />{" "}
                    Mes documents perso
                  </Text>
                }
              >
                {profile._cin_File ? (
                  <Descriptions.Item>
                    <Paragraph style={{ fontSize: "14px" }}>
                      <Text>Mon CV</Text>
                      <Tooltip title="Télécharger">
                        <a
                          style={{
                            textDecoration: "none",
                            color: "#0d6efd",
                            marginLeft: "5px",
                          }}
                          href={profile.CV}
                          download={"CV_" + profile.fullName}
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </a>
                      </Tooltip>
                    </Paragraph>
                  </Descriptions.Item>
                ) : (
                  <Descriptions.Item>
                    <Paragraph style={{ fontSize: "14px" }}>
                      <Text delete>Mon CV</Text>
                      <Tooltip title="Aucune pièce jointe à télécharger">
                        {/* eslint-disable-next-line */}
                        <a
                          style={{
                            textDecoration: "none",
                            color: "#0d6efd",
                            marginLeft: "5px",
                          }}
                          href="#"
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </a>
                      </Tooltip>
                    </Paragraph>
                  </Descriptions.Item>
                )}
                {profile._rib_File ? (
                  <Descriptions.Item>
                    <Paragraph style={{ fontSize: "14px" }}>
                      <Text>Mon RIB</Text>
                      <Tooltip title="Télécharger">
                        <a
                          style={{
                            textDecoration: "none",
                            color: "#0d6efd",
                            marginLeft: "5px",
                          }}
                          href={profile._rib_File}
                          download={"RIB_" + profile.fullName}
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </a>
                      </Tooltip>
                    </Paragraph>
                  </Descriptions.Item>
                ) : (
                  <Descriptions.Item>
                    <Paragraph style={{ fontSize: "14px" }}>
                      <Text delete>Mon RIB</Text>
                      <Tooltip title="Aucune pièce jointe à télécharger">
                        {/* eslint-disable-next-line */}
                        <a
                          style={{
                            textDecoration: "none",
                            color: "#0d6efd",
                            marginLeft: "5px",
                          }}
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </a>
                      </Tooltip>
                    </Paragraph>
                  </Descriptions.Item>
                )}
                {profile._cin_File ? (
                  <Descriptions.Item>
                    <Paragraph style={{ fontSize: "14px" }}>
                      <Text>Ma CIN</Text>
                      <Tooltip title="Télécharger">
                        <a
                          style={{
                            textDecoration: "none",
                            color: "#0d6efd",
                            marginLeft: "5px",
                          }}
                          href={profile._cin_File}
                          download={"CIN_" + profile.fullName}
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </a>
                      </Tooltip>
                    </Paragraph>
                  </Descriptions.Item>
                ) : (
                  <Descriptions.Item>
                    <Paragraph style={{ fontSize: "14px" }}>
                      <Text delete>Ma CIN</Text>
                      <Tooltip title="Aucune pièce jointe à télécharger">
                        {/* eslint-disable-next-line */}
                        <a
                          style={{
                            textDecoration: "none",
                            color: "#0d6efd",
                            marginLeft: "5px",
                          }}
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </a>
                      </Tooltip>
                    </Paragraph>
                  </Descriptions.Item>
                )}
              </Descriptions>
            </div>
          </div>
          {/* <div className="mb-2 py-5 px-2">
            <Typography.Text className="fw-light h3 ">
              Historique de la solde du congé
            </Typography.Text>
            {profile._historiqueSold > 0 && (
              <SoldHistory data={profile._historiqueSold} />
            )}
          </div> */}
        </div>
      )}
    </div>
  );
};

export default Profile;

<div></div>;
