const LocalStorageService = function () {
  const _setToken = (accessToken) => {
    localStorage.setItem("access_token", accessToken);
  };

  const _setNotification = (notification) => {
    localStorage.setItem("notification", notification);
  };

  const _getNotification = () => {
    return localStorage.getItem("notification");
  };

  const _getAccessToken = () => {
    return localStorage.getItem("access_token");
  };

  const _clearToken = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
  };
  const _setAccessToken = (accessToken) => {
    localStorage.setItem("access_token", accessToken);
  };
  const _getRefreshToken = () => {
    return localStorage.getItem("refresh_token");
  };
  const _setRefreshToken = (refresh_token) => {
    localStorage.setItem("refresh_token", refresh_token);
  };

  const _setRole = (role) => {
    localStorage.setItem("role", role);
  };

  const _getRole = () => {
    return localStorage.getItem("role");
  };

  return {
    setToken: _setToken,
    getRefreshToken: _getRefreshToken,
    setRefreshToken: _setRefreshToken,
    setAccessToken: _setAccessToken,
    getAccessToken: _getAccessToken,
    setRole: _setRole,
    getRole: _getRole,
    getNotif: _getNotification,
    setNotif: _setNotification,
    clearToken: _clearToken,
  };
};

export default LocalStorageService;
