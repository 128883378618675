import React from "react";
import EquipmentStock from "../../Components/EquipmentStock/EquipmentStock";

const EquipmentsList = () => {
  return (
    <div>
      <EquipmentStock access={true} />
    </div>
  );
};

export default EquipmentsList;
