import {
  FETCH_PROFILE,
  FETCH_PROFILES,
  CREATE_PROFILE,
} from "../Constants/actionTypes";

const profileReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_PROFILE:
      return action.payload;
    case CREATE_PROFILE:
      return action.payload;
    case FETCH_PROFILES:
      return action.payload;
    default:
      return state;
  }
};

export default profileReducer;
