const FETCH_PROFILE = "FETCH_PROFILE";
const FETCH_PROFILES = "FETCH_PROFILES";
const FETCH_LEAVE = "FETCH_LEAVE";
const FETCH_LEAVES = "FETCH_LEAVES";
const DELETE_PROFILE = "DELETE_PROFILE";
const UPDATE_PROFILE = "UPDATE_PROFILE";
const CREATE_PROFILE = "CREATE_PROFILE";
const DELETE_LEAVE = "DELETE_LEAVES";
const UPDATE_LEAVE = "UPDATE_LEAVES";

export {
  FETCH_PROFILE,
  FETCH_PROFILES,
  FETCH_LEAVE,
  FETCH_LEAVES,
  DELETE_PROFILE,
  UPDATE_PROFILE,
  CREATE_PROFILE,
  DELETE_LEAVE,
  UPDATE_LEAVE,
};
