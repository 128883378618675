import { Table, Typography } from "antd";
import dayjs from "dayjs";
import React from "react";

const TeleWorkClockingTable = ({ dataSource, loading }) => {
  const columns = [
    {
      title: "Date",
      dataIndex: "_date",
      render: (text) => (
        <Typography.Text>{dayjs(text).format("DD/MM/YYYY")}</Typography.Text>
      ),
    },
    {
      title: "Horaire de prise de poste",
      dataIndex: "_start_post",
    },
    {
      title: "Horaire de départ",
      dataIndex: "_end_post",
    },
    {
      title: "Horaire de début de pause",
      dataIndex: "_start_pause",
    },
    {
      title: "Horaire de fin de pause",
      dataIndex: "_end_pause",
    },
    {
      title: "Actions",
    },
  ];
  return (
    <div>
      <Table
        className="shadow-md rounded-4"
        size="small"
        bordered={true}
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default TeleWorkClockingTable;
