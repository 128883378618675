import React, { useContext, useEffect, useState } from "react";
import TypingTextAnimation from "react-typing-text-animation";

import "react-typing-text-animation/dist/index.css";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Empty,
  Skeleton,
  Statistic,
  Tag,
  Timeline,
  Typography,
  notification,
} from "antd";

import { CaretDownOutlined } from "@ant-design/icons";

import { SmileOutlined } from "@ant-design/icons";
import axiosInstance from "../../Utils/axios";
import moment from "moment";
import GlobalContext from "../../Context/GlobalContext";
import dayjs from "dayjs";
import SondageCard from "../../Components/SondageCard/SondageCard";
import PostCard from "../../Components/PostCard";
import DocumentCard from "../../Components/DocumentCard";

const container = {
  hidden: {
    hidden: { opacity: 1, scale: 0 },
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

const transitionYPositive = {
  hidden: { y: 80, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const transitionXNegative = {
  hidden: { x: 80, opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
  },
};

const Default = () => {
  const Navigate = useNavigate();
  const { setRedirectData, profile } = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const [api, contextHolder] = notification.useNotification();
  const [statsLoading, setStatsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageNumbers, setPageNumbers] = useState(1);
  const [actualities, setActualities] = useState([]);
  const [todayLeaves, setTodayLeaves] = useState([]);
  const [todayRemote, setTodayRemote] = useState([]);
  const [stats, setStats] = useState({});

  const openNotification = () => {
    api.open({
      message: "Bienvenue " + profile.fullName,
      description: (
        <TypingTextAnimation
          text="Nous avons eu un problème avec notre plateforme, nous aimerions que vous consultiez votre Profil --> Demande de modification, et que vous remplissiez toutes les données nécessaires."
          cursorDuration="1"
          cursor={<div className="d-none"></div>}
          delay="20"
        />
      ),

      icon: (
        <SmileOutlined
          style={{
            color: "#108ee9",
          }}
        />
      ),
      duration: 0,
    });
  };

  const handlePlusLeaves = () => {
    setRedirectData({
      type: "DEFAULT",
      filters: ["Unjustified"],
    });
    Navigate("/manage/self");
  };

  const handlePlusOthers = () => {
    setRedirectData({
      type: "OTHERS",
      filters: ["PENDING"],
    });
    Navigate("/manage/self");
  };

  const handlePlusSubs = () => {
    setRedirectData({
      type: "DEFAULT",
      filters: ["Pending", "Validating", "Unjustified"],
    });
    Navigate("/manage/subordinates");
  };

  useEffect(() => {
    async function getTodayLeaves() {
      const { data } = await axiosInstance.get("/api/leaves/get/today/leaves");
      setTodayLeaves(data.todayLeave);
      setTodayRemote(data.todayRemote);
      setLoading(false);
      return data;
    }

    async function getStats() {
      const { data } = await axiosInstance.get("/api/platform/employee");
      setStats(data);
      setStatsLoading(false);
      return data;
    }

    getStats();
    getTodayLeaves();
    // openNotification();
  }, []);

  useEffect(() => {
    async function getActualities() {
      const { data } = await axiosInstance.get(
        `/api/factory/actualities?page=${page}&pageSize=3`
      );
      setActualities([...actualities, ...data.actualities]);
      setPageNumbers(data.totalPages);
    }
    getActualities();
  }, [page]);

  return (
    <motion.div variants={container} initial="hidden" animate="visible">
      <div className="pb-4">
        <div className="container w-100 px-4">
          <div className="my-3 row justify-content-center">
            <div className="col-lg-11 col-md-12">
              <h1 className="fw-bold h3 pb-1 ">
                <TypingTextAnimation
                  text="Bienvenue sur N-Dev HaRmonie"
                  cursorDuration="1s"
                  cursor={<div className="d-none"></div>}
                  delay="50"
                />
              </h1>
              <TypingTextAnimation
                text="La plateforme de gestion des ressources humaines de Neopolis
              Development. Désormais, vous avez accès à toutes vos informations
              professionnelles en un seul endroit."
                cursorDuration="1s"
                cursor={<div className="d-none"></div>}
                delay="20"
              />
            </div>
          </div>
          {contextHolder}

          {statsLoading ? (
            <div className="row justify-content-center">
              <div className="bg-white col-lg-11 p-3 rounded-4  d-flex  flex-md-row flex-lg-row flex-column justify-content-evenly my-4">
                <div className="col-lg-3 col-md-12 col-sm-12 mb-2">
                  <Skeleton.Input active={true} size={"small"} block="true" />
                  <div className="mt-2">
                    <Skeleton.Input active={true} size={"small"} />
                    <Skeleton.Button
                      active={true}
                      size={"small"}
                      className="mx-2"
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12 mb-2">
                  <Skeleton.Input active={true} size={"small"} block="true" />
                  <div className="mt-2">
                    <Skeleton.Input active={true} size={"small"} />
                    <Skeleton.Button
                      active={true}
                      size={"small"}
                      className="mx-2"
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 mb-2">
                  <Skeleton.Input active={true} size={"small"} block="true" />
                  <div className="mt-2">
                    <Skeleton.Input active={true} size={"small"} />
                    <Skeleton.Button
                      active={true}
                      size={"small"}
                      className="mx-2"
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row justify-content-center">
              <div className="bg-white col-lg-11 col-md-12 col-sm-12  p-3 rounded-4  d-flex  flex-md-row flex-lg-row flex-column justify-content-evenly my-4">
                <Statistic
                  title="Demande(s) non encore justifiée(s)"
                  value={stats.unjustifiedLeaves}
                  style={{ padding: "10px", borderRadius: "13px" }}
                  suffix={
                    <Button
                      size="small"
                      type="primary"
                      onClick={handlePlusLeaves}
                      style={{ marginLeft: "10px", marginTop: "-5px" }}
                    >
                      Voir plus
                    </Button>
                  }
                />

                <Statistic
                  className="responsive-border"
                  title="Autre demande(s) non encore traitée(s)"
                  value={stats.untreatedRequests}
                  suffix={
                    <Button
                      size="small"
                      type="primary"
                      onClick={handlePlusOthers}
                      style={{ marginLeft: "10px", marginTop: "-5px" }}
                    >
                      Voir plus
                    </Button>
                  }
                />

                <Statistic
                  title="Demande(s) de mes subordonnés non encore traitée(s)"
                  value={stats.subLeaves}
                  style={{ padding: "10px", borderRadius: "13px" }}
                  suffix={
                    <Button
                      size="small"
                      type="primary"
                      onClick={handlePlusSubs}
                      style={{ marginLeft: "10px", marginTop: "-5px" }}
                    >
                      Voir plus
                    </Button>
                  }
                />
              </div>
            </div>
          )}
          <div className="row justify-content-center">
            <div
              className="col-lg-11"
              style={{
                paddingRight: "0",
                paddingLeft: "0",
              }}
            >
              <div className="d-flex flex-column flex-lg-row flex-md-row justify-content-between">
                <div className="my-1 bg-white p-2  rounded-4 shadow-sm custom-margin-right w-100 p-3">
                  <h4 className=" mb-4 d-flex justify-content-between align-items-center">
                    <span>Collaborateurs indisponibles</span>

                    <Button
                      size="small"
                      type="primary"
                      onClick={() => {
                        Navigate("/team/calendar");
                      }}
                    >
                      Voir plus
                    </Button>
                  </h4>
                  {loading ? (
                    <div>
                      <Skeleton
                        active
                        size="small"
                        paragraph={{
                          rows: 1,
                        }}
                      />
                      <Skeleton
                        active
                        size="small"
                        paragraph={{
                          rows: 1,
                        }}
                      />
                    </div>
                  ) : (
                    <Timeline className="mt-3">
                      {todayLeaves?.length > 0 ? (
                        todayLeaves.map((leave, idx) => {
                          if (
                            leave._typeLeave === "Autorisation" &&
                            !moment(
                              leave._duration.split(" - ")[1],
                              "HH:mm"
                            ).isAfter(moment())
                          ) {
                            return null;
                          }
                          return (
                            leave._typeLeave && (
                              <Timeline.Item key={idx}>
                                <Typography.Text strong>
                                  <TypingTextAnimation
                                    text={`${leave.fullName} - ${
                                      leave._typeLeave === "Autorisation"
                                        ? "Autorisation"
                                        : leave._is_half_day
                                        ? "Congé - demi journée"
                                        : "Congé"
                                    }`}
                                    cursorDuration="1"
                                    cursor={<div className="d-none"></div>}
                                    delay="20"
                                  />
                                </Typography.Text>

                                <Typography.Text>
                                  {leave._typeLeave === "Autorisation" && (
                                    <>
                                      <Tag color={"geekblue"}>
                                        <TypingTextAnimation
                                          text={`
                                            ${leave._duration}
                                         `}
                                          cursorDuration="1"
                                          cursor={
                                            <div className="d-none"></div>
                                          }
                                          delay="20"
                                        />
                                      </Tag>
                                    </>
                                  )}
                                </Typography.Text>
                              </Timeline.Item>
                            )
                          );
                        })
                      ) : (
                        <>
                          <Empty
                            description={"Aucune collaborateurs indisponible"}
                          />
                        </>
                      )}
                    </Timeline>
                  )}
                </div>
                <div className="my-1 bg-white p-2 rounded-4 shadow-sm custom-margin-left w-100 p-3">
                  <h4 className="mb-4">
                    <span>Collaborateurs en télétravail</span>
                  </h4>
                  {loading ? (
                    <div>
                      <Skeleton
                        active
                        size="small"
                        paragraph={{
                          rows: 1,
                        }}
                      />
                      <Skeleton
                        active
                        size="small"
                        paragraph={{
                          rows: 1,
                        }}
                      />
                    </div>
                  ) : (
                    <Timeline className="mt-3">
                      {todayRemote?.length > 0 ? (
                        todayRemote.map((leave, idx) => {
                          return (
                            leave?._except?.find(
                              (val) => val === dayjs().format("dddd")
                            ) === undefined && (
                              <Timeline.Item key={idx}>
                                <Typography.Text strong>
                                  <TypingTextAnimation
                                    text={`${leave.fullName} - ${leave._typeLeave}`}
                                    cursorDuration="1"
                                    cursor={<div className="d-none"></div>}
                                    delay="20"
                                  />
                                </Typography.Text>
                              </Timeline.Item>
                            )
                          );
                        })
                      ) : (
                        <>
                          <Empty
                            description={"Aucune collaborateurs en télétravail"}
                          />
                        </>
                      )}
                    </Timeline>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-11 bg-white p-3 rounded-4 shadow-sm mt-2 d-flex flex-column justify-content-center align-items-center">
              <div>
                <h4 className="fw-bold">Fil d'actualité</h4>
              </div>
              {actualities.map((elem) => {
                if (elem.type === "document") {
                  return <DocumentCard id={elem._id} access={false} />;
                } else if (elem.type === "suggestion") {
                  return <PostCard id={elem._id} access={false} />;
                } else if (elem.type === "survey") {
                  return <SondageCard id={elem._id} access={false} />;
                }
              })}
              {actualities.length > 0 ? (
                <Button
                  shape="round"
                  disabled={pageNumbers === page}
                  onClick={() => {
                    const temp = page + 1;
                    setPage(temp);
                  }}
                  className="d-flex justify-content-center align-items-center mt-2"
                >
                  <CaretDownOutlined />
                  Afficher plus
                </Button>
              ) : (
                <Empty description={"Aucune donnée"} />
              )}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Default;
