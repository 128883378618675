import { useState } from "react";
import LocalStorageService from "../Utils/localStorageService";
import GlobalContext from "./GlobalContext";
import jwt from "jwt-decode";

const themes = [
  {
    color: "#5382d2",
    logo: "light",
    siderMode: "light",
    siderBg: "#ffffff",
    headerBg: "#ffffff",
  },
  {
    color: "#2b5497",
    logo: "dark",
    siderMode: "dark",
    siderBg: "#2b5497",
    headerBg: "#f1f6f9",
  },
  {
    color: "#4663ac",
    logo: "dark",
    siderMode: "dark",
    siderBg: "#001529",
    headerBg: "#f1f6f9",
  },
];

const AppContext = (props) => {
  const [profile, setProfile] = useState({});
  const [equipments, setEquipments] = useState([]);
  const [redirectData, setRedirectData] = useState({});
  const [theme, setTheme] = useState(
    localStorage.getItem("custom_theme")
      ? JSON.parse(localStorage.getItem("custom_theme"))
      : themes[
          localStorage.getItem("theme") ? localStorage.getItem("theme") : 2
        ]
  );
  const [role, setRole] = useState(
    LocalStorageService().getAccessToken()
      ? jwt(LocalStorageService().getAccessToken()).Role
      : null
  );

  const handleTheme = (idx) => {
    localStorage.removeItem("custom_theme");
    localStorage.setItem("theme", idx);
    setTheme(themes[idx]);
  };

  return (
    <GlobalContext.Provider
      value={{
        redirectData,
        setRedirectData,
        profile,
        setProfile,
        role,
        setRole,
        equipments,
        setEquipments,
        theme,
        setTheme,
        handleTheme,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default AppContext;
