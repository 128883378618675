import { useState, useEffect, useRef, useContext } from "react";

import { SearchOutlined, ColumnHeightOutlined } from "@ant-design/icons";

import {
  Button,
  Space,
  Table,
  Tag,
  Typography,
  Input,
  Form,
  Tooltip,
  message,
  Modal,
  Popconfirm,
  DatePicker,
  Select,
  Radio,
  Dropdown,
  Popover,
  Checkbox,
} from "antd";
import GlobalContext from "../../../Context/GlobalContext";
import Highlighter from "react-highlight-words";
import * as XLSX from "xlsx";
import axios from "../../../Utils/axios";
import moment from "moment";
import { faFileExport, faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import OthersHistory from "../../Components/OthersHistory";

const { Text, Paragraph, Link } = Typography;

const { RangePicker } = DatePicker;
const HistoryLeave = () => {
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [dateRange, setDateRange] = useState("");
  const searchInput = useRef(null);
  const [optionsTab, setOptions] = useState([
    "fullName",
    "request",
    "status",
    "motif",
    "startDate",
    "endDate",
    "duration",
    "answered_by",
  ]);
  const [loading, setLoading] = useState(true);
  const [requestedLeaves, setRequestedLeaves] = useState([]);
  const { redirectData } = useContext(GlobalContext);

  const [size, setSize] = useState("small");
  const handleSizeChange = (new_size) => {
    setSize(new_size);
  };

  const items = [
    {
      key: "1",
      label: (
        <a
          href="#"
          className="text-decoration-none"
          onClick={() => {
            handleSizeChange("large");
          }}
        >
          Plus grand
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          href="#"
          className="text-decoration-none"
          onClick={() => {
            handleSizeChange("middle");
          }}
        >
          Moyenne
        </a>
      ),
    },
    {
      key: "3",
      label: (
        <a
          href="#"
          className="text-decoration-none"
          onClick={() => {
            handleSizeChange("small");
          }}
        >
          Compact
        </a>
      ),
    },
  ];

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        justify-content-center
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  /* eslint-disable-next-line */
  const [expand, setExpand] = useState(true);

  const columns = [
    optionsTab.find((elem) => elem === "fullName")
      ? {
          title: "Nom et prénom",
          dataIndex: "fullName",
          key: "1",
          ...getColumnSearchProps("fullName"),
          render: (_, requestedLeaves) => (
            <Link strong>
              #{requestedLeaves.idEmployee} - {requestedLeaves.fullName}
            </Link>
          ),
        }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "request")
      ? {
          title: "Demande",
          dataIndex: "_typeLeave",
          width: 200,
          render: (_, requestedLeaves) => (
            <>
              {requestedLeaves._special_leave ? (
                <Paragraph
                  style={{ margin: 0 }}
                  ellipsis={{
                    rows: 2,
                    expandable: true,
                    symbol: "Plus",
                    onExpand: () => {
                      setExpand(true);
                    },
                    onEllipsis: () => {
                      setExpand(false);
                    },
                  }}
                >
                  {requestedLeaves._typeLeave +
                    `${
                      requestedLeaves._special_leave &&
                      " - " + requestedLeaves._special_leave
                    }`}
                </Paragraph>
              ) : (
                <Text>{requestedLeaves._typeLeave}</Text>
              )}
            </>
          ),
        }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "status")
      ? {
          title: "Statut",
          dataIndex: "status",
          key: "7",
          filters: [
            {
              text: <Tag color={"orange"}>En attente</Tag>,
              value: "Pending",
            },

            {
              text: <Tag color={"green"}>Approuvée</Tag>,
              value: "Accepted",
            },

            {
              text: <Tag color={"cyan"}>En attente d'approbation</Tag>,
              value: "Validating",
            },

            {
              text: <Tag color={"red"}>Rejeté</Tag>,
              value: "Rejected",
            },

            {
              text: <Tag color="red">Annulée</Tag>,
              value: "Canceled",
            },

            {
              text: <Tag color={"gold"}>Non encore justifié</Tag>,
              value: "Unjustified",
            },

            {
              text: <Tag>échue</Tag>,
              value: "Failed",
            },
          ],
          onFilter: (value, record) => record.status.includes(value),
          render: (_, record) => (
            <>
              {record.status === "Pending" && (
                <Tag color={"orange"}>En attente</Tag>
              )}
              {record.status === "Accepted" && (
                <Tag color={"green"}>Approuvée</Tag>
              )}
              {record.status === "Validating" && (
                <Tag color={"cyan"}>En attente d'approbation</Tag>
              )}
              {record.status === "Rejected" && <Tag color={"red"}>Rejeté</Tag>}
              {record.status === "Canceled" && <Tag color="red">Annulée</Tag>}
              {record.status === "Unjustified" && (
                <Tag color={"gold"}>Non encore justifié</Tag>
              )}
              {record.status === "Failed" && (
                <Tooltip title="Considérée comme absence non justifiée">
                  <Tag>échue</Tag>
                </Tooltip>
              )}
            </>
          ),
        }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "motif")
      ? {
          title: "Motif",
          dataIndex: "_event",
          key: "4",
          width: 300,
          render: (_, requestedLeaves) => (
            <>
              <Paragraph
                style={{ margin: 0, whiteSpace: "pre-wrap" }}
                ellipsis={{
                  rows: 2,
                  expandable: true,
                  symbol: "Plus",
                }}
              >
                {requestedLeaves._event || requestedLeaves._motif || "N/A"}
              </Paragraph>
            </>
          ),
        }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "startDate")
      ? {
          title: "Date de début",
          dataIndex: "BeginingDate",
          sorter: (a, b) => moment(a.BeginingDate) - moment(b.BeginingDate),
          render: (text) => <Text>{moment(text).format("DD/MM/YYYY")}</Text>,
        }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "endDate")
      ? {
          title: "Date de fin",
          dataIndex: "EndingDate",
          key: "6",
          sorter: (a, b) => moment(a.EndingDate) - moment(b.EndingDate),
          render: (text) => <Text>{moment(text).format("DD/MM/YYYY")}</Text>,
        }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "duration")
      ? {
          title: "Durée",
          render: (_, requestedLeaves) => (
            <Text>
              {requestedLeaves._typeLeave === "Autorisation"
                ? requestedLeaves._duration
                : !requestedLeaves._is_half_day
                ? requestedLeaves.DaysNumber + " Jour(s)"
                : requestedLeaves._is_half_day}
            </Text>
          ),
        }
      : { width: 0, className: "d-none" },
    {
      title: "Soumis le",
      dataIndex: "_posted_date",
      sorter: (a, b) => moment(a._posted_date) - moment(b._posted_date),
      render: (_, leave) => (
        <Text strong>
          {leave?._posted_date
            ? moment(leave._posted_date).format("DD/MM/YYYY HH:mm")
            : "N/A"}
        </Text>
      ),
    },
    optionsTab.find((elem) => elem === "answered_by")
      ? {
          title: "Répondu par",
          dataIndex: "_answered_by",
          render: (_, requestedLeaves) => (
            <>
              {requestedLeaves.status === "Pending" ? (
                <Tag color={"orange"}>En attente</Tag>
              ) : requestedLeaves._answered_by ? (
                <Tooltip
                  title={
                    "Répondu à " +
                    moment(requestedLeaves._answered_at).format(
                      "DD/MM/YYYY HH:mm"
                    )
                  }
                >
                  <Text> {requestedLeaves._answered_by} </Text>
                </Tooltip>
              ) : (
                <Text>N/A</Text>
              )}
            </>
          ),
        }
      : { width: 0, className: "d-none" },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          {record.status === "Canceled" ? (
            <Button size="small" disabled>
              Valider
            </Button>
          ) : (
            <Popconfirm
              okText="Oui"
              title="Vous êtes sûr d'annuler la demande?"
              onConfirm={() => {
                Modal.info({
                  title: "Annuler la demande",
                  content: (
                    <>
                      <Form form={form} layout="vertical">
                        <Form.Item name="motif" label="Reponse:">
                          <Input.TextArea />
                        </Form.Item>
                      </Form>
                    </>
                  ),
                  onOk: () => {
                    handleActions({
                      _id: record._id,
                      value: "Canceled",
                    });
                  },
                });
              }}
            >
              <Button size="small" type="primary">
                Annuler
              </Button>
            </Popconfirm>
          )}
        </>
      ),
    },
  ];

  const handleActions = async (options) => {
    try {
      const { data } = await axios.patch("/api/leaves/force/" + options._id, {
        status: options.value,
        motif: form.getFieldsValue().motif,
      });
      if (data.status === "success") {
        message.info(data.message);
        const newRequestedLeaves = requestedLeaves.map((RL) => {
          if (RL._id === options._id) {
            RL.status = options.value;
          }
          return RL;
        });
        setRequestedLeaves(newRequestedLeaves);
      }
    } catch (error) {}
  };

  useEffect(() => {
    async function fetchRequestedLeaves() {
      try {
        const { data } = await axios.get(
          `/api/leaves/admin/requests${
            dateRange
              ? "?startDate=" +
                dateRange.startDate +
                "&endDate=" +
                dateRange.endDate
              : ""
          }`
        );
        setRequestedLeaves(data);

        setLoading(false);
        return data;
      } catch (error) {
        message.info(error.response.data.message);
      }
    }

    fetchRequestedLeaves();
  }, [dateRange]);

  const handleExportToXLSX = () => {
    const data = requestedLeaves.map((element) => {
      return {
        Collaborateur: element.fullName,
        Matricule: element.idEmployee,
        Demande: element._typeLeave,
        "Date de début": moment(element.BeginingDate).format("DD/MM/YYYY"),
        "Date de fin": moment(element.EndingDate).format("DD/MM/YYYY"),
        Durée: element._is_half_day
          ? "Demi-journée: " + element._is_half_day
          : element.DaysNumber + " Jour(s)",
        Motif: element._motif,
        "Répondu par": element._answered_by,
        "Répondu le ": moment(element._answered_at).format("DD/MM/YYYY, HH:mm"),
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(data);

    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(
      workbook,
      `Historique des demandes ${moment().format("DD/MM/YYYY HH-mm")}.xlsx`
    );
  };

  const onChange = (date) => {
    if (date) {
      const startDate = dayjs(date[0]).format("DD-MM-YYYY");
      const endDate = dayjs(date[1]).format("DD-MM-YYYY");
      setDateRange({ startDate: startDate, endDate: endDate });
    } else {
      setDateRange("");
    }
  };
  const [type, setType] = useState(
    redirectData?.type ? redirectData?.type : "DEFAULT"
  );

  const onChangeOptions = (checkedValues) => {
    setOptions(checkedValues);
  };

  return (
    <div className="bg-white p-4 rounded shadow-sm ">
      <div className="mb-3">
        <h4 className="fw-bold">Historique des demandes</h4>
        <div
          className="row justify-content-between mb-2 "
          style={{
            backgroundColor: "#f2f5fb",
            padding: "20px",
            borderRadius: "13px",
          }}
        >
          <div className="col-lg-5 col-md-6 col-sm-12">
            <Select
              style={{ width: "100%" }}
              defaultValue={type}
              placeholder="Choisissez les demandes que vous souhaitez gerer"
              onChange={(e) => {
                setType(e);
              }}
            >
              <Select.Option value="DEFAULT">
                Demandes de congés/autorisations
              </Select.Option>
              <Select.Option value="OTHERS">Autres demandes</Select.Option>
              <Select.Option value="PENDING" disabled>
                <Tooltip title="Coming soon" placement="bottom">
                  Demandes de materiel informatique
                </Tooltip>
              </Select.Option>
            </Select>
            <br />
            <Button
              type="primary"
              className="my-2"
              onClick={handleExportToXLSX}
              disabled={!(requestedLeaves.length > 0)}
            >
              <FontAwesomeIcon
                icon={faFileExport}
                style={{ marginRight: "5px" }}
              />
              Exporter
            </Button>
          </div>
          <div className="col-lg-5 col-md-6 col-sm-12">
            <div className=" d-flex align-items-center">
              <RangePicker
                onChange={onChange}
                className="w-100"
                placement="bottomLeft"
              />
              <Tooltip title="Paramètres du tableau">
                <Popover
                  trigger="click"
                  placement="bottom"
                  title="Colonne(s) sélectionnée(s):"
                  // className="gearIcon"
                  content={
                    <Checkbox.Group
                      onChange={onChangeOptions}
                      defaultValue={optionsTab}
                    >
                      <div className="d-flex flex-column">
                        <div>
                          <Checkbox value="fullName">Nom et prénom</Checkbox>
                        </div>
                        <div>
                          <Checkbox value="request">Demande</Checkbox>
                        </div>
                        <div>
                          <Checkbox value="status">Statut</Checkbox>
                        </div>
                        <div>
                          <Checkbox value="motif">Motif</Checkbox>
                        </div>
                        <div>
                          <Checkbox value="startDate">Date de début</Checkbox>
                        </div>
                        <div>
                          <Checkbox value="endDate">Date de fin</Checkbox>
                        </div>
                        <div>
                          <Checkbox value="duration">Durée</Checkbox>
                        </div>
                        <Checkbox value="answered_by">Répondu par</Checkbox>
                      </div>
                    </Checkbox.Group>
                  }
                >
                  <FontAwesomeIcon
                    icon={faGear}
                    style={{ marginLeft: "10px", cursor: "pointer" }}
                  />
                </Popover>
              </Tooltip>
              <Tooltip title="Densité">
                <Dropdown
                  menu={{
                    items,
                  }}
                >
                  <ColumnHeightOutlined
                    style={{ marginLeft: "10px", cursor: "pointer" }}
                  />
                </Dropdown>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      {type === "DEFAULT" ? (
        <Table
          className="shadow-md"
          bordered={true}
          loading={loading}
          columns={columns}
          size={size}
          scroll={{ x: "max-content" }}
          dataSource={requestedLeaves}
        />
      ) : type === "OTHERS" ? (
        <OthersHistory dateRange={dateRange} size={size} />
      ) : (
        ""
      )}
    </div>
  );
};

export default HistoryLeave;
