import {
  Button,
  Space,
  Table,
  Tag,
  message,
  Input,
  Popconfirm,
  Typography,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import Highlighter from "react-highlight-words";
import axios from "../../../Utils/axios";
import * as XLSX from "xlsx";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const { Paragraph } = Typography;

const OthersHistory = (props) => {
  const { dateRange, size } = props;
  const [requests, setRequests] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(
        `/api/request/history${
          dateRange
            ? "?startDate=" +
              dateRange.startDate +
              "&endDate=" +
              dateRange.endDate
            : ""
        }`
      );
      setRequests(data.requests);
      setLoading(false);
      return data;
    }

    fetchData();
  }, [dateRange]);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Recherche ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            // style={{
            //   width: 90,
            // }}
          >
            Recherche
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Réinitialiser
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleApprove = async (record) => {
    try {
      const { data } = await axios.patch("/api/request/" + record._id, {
        status: "APPROVED",
      });
      if (data.status === "success") {
        message.success(data.message);
        const new_requests = requests.map((r) => {
          if (r._id === data.request._id) {
            return data.request;
          } else {
            return r;
          }
        });
        setRequests(new_requests);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleReject = async (record) => {
    try {
      const { data } = await axios.patch("/api/request/" + record._id, {
        status: "REJECTED",
      });
      if (data.status === "success") {
        message.success(data.message);
        const new_requests = requests.map((r) => {
          if (r._id === data.request._id) {
            return data.request;
          } else {
            return r;
          }
        });
        setRequests(new_requests);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const columns = [
    {
      title: "Nom et prénom",
      dataIndex: "sender",
      ...getColumnSearchProps("sender", "Nom et prénom"),
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (type) => (
        <>
          {type === "EQUIPMENT" ? (
            <Tag style={{ fontSize: "12px" }} color="cyan">
              Materiel Informatique
            </Tag>
          ) : (
            <Tag style={{ fontSize: "12px" }} color="green">
              Modification Personnelle
            </Tag>
          )}
        </>
      ),
      filters: [
        {
          text: (
            <Tag style={{ fontSize: "12px" }} color="cyan">
              Materiel Informatique
            </Tag>
          ),
          value: "EQUIPMENT",
        },
        {
          text: (
            <Tag style={{ fontSize: "12px" }} color="green">
              Modification Personnelle
            </Tag>
          ),
          value: "MODIFICATION",
        },
      ],
      onFilter: (value, record) => record.type === value,
    },
    {
      title: "Demande",
      dataIndex: "request",
      width: 210,
      render: (text) => (
        <Typography.Paragraph
          ellipsis={{
            rows: 2,
            expandable: true,
            symbol: "Plus",
          }}
          style={{ whiteSpace: "pre-wrap" }}
        >
          {text}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Statut",
      dataIndex: "status",
      render: (_, record) => (
        <>
          {record.status === "PENDING" && (
            <Tag style={{ fontSize: "12px" }} color="orange">
              En attente
            </Tag>
          )}
          {record.status === "APPROVED" && (
            <Tag style={{ fontSize: "12px" }} color="green">
              Approuvée
            </Tag>
          )}

          {record.status === "REJECTED" && (
            <Tag style={{ fontSize: "12px" }} color="red">
              Rejetée
            </Tag>
          )}
          {record.status === "CANCELLED" && (
            <Tag style={{ fontSize: "12px" }} color="red">
              Annulée
            </Tag>
          )}
        </>
      ),
      filters: [
        {
          text: (
            <Tag style={{ fontSize: "12px" }} color="orange">
              En attente
            </Tag>
          ),
          value: "PENDING",
        },
        {
          text: (
            <Tag style={{ fontSize: "12px" }} color="green">
              Acceptée
            </Tag>
          ),
          value: "APPROVED",
        },
        {
          text: (
            <Tag style={{ fontSize: "12px" }} color="red">
              Rejetée
            </Tag>
          ),
          value: "REJECTED",
        },
        {
          text: (
            <Tag style={{ fontSize: "12px" }} color="red">
              Annulée
            </Tag>
          ),
          value: "CANCELLED",
        },
      ],

      onFilter: (value, record) => record.status === value,
    },
    {
      title: "Motif",
      dataIndex: "motif",
      width: 250,
      render: (_, requestedLeaves) => (
        <>
          <Paragraph
            style={{ margin: 0, whiteSpace: "pre-wrap" }}
            ellipsis={{
              rows: 2,
              expandable: true,
              symbol: "Plus",
            }}
          >
            {requestedLeaves.motif}
          </Paragraph>
        </>
      ),
    },
    {
      title: "Soumis le",
      dataIndex: "send_time_date",
      render: (_, leave) => (
        <Typography.Text strong>
          {leave?.send_time_date
            ? moment(leave.send_time_date).format("DD/MM/YYYY HH:mm")
            : "N/A"}
        </Typography.Text>
      ),
      sorter: (a, b) => moment(a.send_time_date) - moment(b.send_time_date),
    },

    {
      title: "Fichier joint",
      dataIndex: "attachment_file",
      render: (_, record) => (
        <>
          {record.attachment_file ? (
            <a
              style={{ textDecoration: "none" }}
              href={record.attachment_file}
              download={"attachment_" + record.sender}
            >
              <Button size="small">Télècharger</Button>
            </a>
          ) : (
            <Button size="small" disabled>
              Télècharger
            </Button>
          )}
        </>
      ),
    },
    {
      title: "Actions",
      render: (_, record) => (
        <>
          {record.status === "PENDING" && (
            <>
              <Popconfirm
                okText="Oui"
                title="Vous êtes sûr d'approuver la demande?"
                onConfirm={() => {
                  handleApprove(record);
                }}
              >
                <Button size="small" type="primary">
                  Approuver
                </Button>
              </Popconfirm>
              <Popconfirm
                okText="Oui"
                title="Vous êtes sûr de rejeter la demande?"
                onConfirm={() => {
                  handleReject(record);
                }}
              >
                <Button
                  size="small"
                  type="primary"
                  style={{ marginLeft: "5px" }}
                  danger
                >
                  Rejeter
                </Button>
              </Popconfirm>
            </>
          )}
          {record.status === "APPROVED" && (
            <Button disabled size="small" type="primary">
              Valider
            </Button>
          )}
          {record.status === "REJECTED" && (
            <Button disabled size="small" type="primary">
              Valider
            </Button>
          )}
          {record.status === "CANCELED" && (
            <Button disabled size="small" type="primary">
              Valider
            </Button>
          )}
        </>
      ),
    },
  ];

  return (
    <div>
      <Table
        bordered
        className="shadow-sm"
        columns={columns}
        size={size}
        loading={loading}
        dataSource={requests}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default OthersHistory;
