import { Radio } from "antd";
import React from "react";

const ProgressBar = ({ progress, value, valueId, color }) => {
  return (
    <div className="progress-bar-custom shadow-sm">
      <div
        style={{
          // PRECENTAGE OF VOTES
          width: `${progress}%`,
          backgroundColor: color,
          borderRadius: "100px",
          height: "100%",
          position: "absolute",
          top: "0",
          left: "0",
          overflow: "initial",
          display: "flex",
        }}
        className="shadow-sm"
      >
        <Radio
          style={{
            margin: "10px",
            position: "flex",
            width: "100%",
            whiteSpace: "nowrap",
            flexShrink: 0,
          }}
          value={valueId}
        >
          {value}
        </Radio>
      </div>
    </div>
  );
};

export default ProgressBar;
