import React, { useContext, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

// UTILS
import LocalStorageService from "./Utils/localStorageService";

// ADMIN PAGES
import Layout from "./Admin/Components/Layout";
import EmployeeForm from "./Admin/Pages/EmployeeForm";
import EmployeeProfile from "./Admin/Pages/EmployeeProfile";
import EmployeesList from "./Admin/Pages/EmployeesList";
import LeaveFormAdmin from "./Admin/Pages/LeaveFormAdmin";
import RequestsList from "./Admin/Pages/RequestsList";
import LeavesList from "./Admin/Pages/LeavesList";
import Holidays from "./Admin/Pages/Holidays";
import EquipmentsList from "./Admin/Pages/EquipmentsList";
import AbsenceListAdmin from "./Admin/Pages/AbsenceListAdmin";
import Logs from "./Admin/Pages/Logs";
import Home from "./Admin/Pages/Home";

// PERSONNELLE PAGES
import LayoutClient from "./Components/LayoutClient";
import Profile from "./Pages/Profile";
import LeaveForm from "./Pages/LeaveForm";
import Default from "./Pages/Default/Default";
import LeavesListEmployees from "./Pages/LeavesListEmployees";
import AcceptePage from "./Pages/AcceptePage";
import RefusePage from "./Pages/RefusePage";
import OwnLeaves from "./Pages/OwnLeaves";
import AbsencesList from "./Pages/AbsencesList";

// COMMUN PAGES
import Login from "./Pages/Login";
import PasswordReset from "./Pages/PasswordReset";
import ForgotPassword from "./Pages/ForgotPassword";
import ErrorPage from "./Pages/ErrorPage";

// UTILS
import axios from "./Utils/axios";
import MaintainancePage from "./Pages/MaintainancePage";
import Parameters from "./Admin/Pages/Parameters";
import RequestInfoComponents from "./Pages/RequestInfoComponents";
import FaqPage from "./Pages/FaqPage";
import SharedDocuments from "./Admin/Pages/SharedDocuments";
import DocumentsList from "./Admin/Pages/DocumentsList";
import SharedDocs from "./Pages/SharedDocs";
import Faq from "./Admin/Pages/Faq";
import Trombinoscope from "./Pages/Trombinoscope";
import MyEquipments from "./Pages/MyEquipments";
import HistoryLeave from "./Admin/Pages/HistoryLeaves";
import GlobalContext from "./Context/GlobalContext";
import AttestationTravail from "./Pages/AttestationTravail";
import TeleWorkClocking from "./Pages/TeleWorkClocking";
import EquipmentsManagment from "./Admin/Pages/EquipmentsManagment";
import EquipmentList from "./Pages/EquipmentList";
import EditRequest from "./Admin/Pages/EditRequest";
import Suggestion from "./Pages/Suggestion";
import AccountCredentials from "./Admin/Pages/AccountCredentials";

import frFR from "antd/lib/locale/fr_FR";
import { ConfigProvider } from "antd";
import TeamCalendar from "./Pages/TeamCalendar";
import AdminCalendar from "./Admin/Pages/AdminCalendar";

const App = () => {
  const { role, setRole, theme } = useContext(GlobalContext);

  const [token, setToken] = React.useState(
    LocalStorageService().getAccessToken() || null
  );

  const [restrict, setRestrict] = React.useState(true);

  useEffect(() => {
    async function platformStatus() {
      const { data } = await axios.get("/api/platform/");
      setRestrict(data.status);
      return data;
    }
    platformStatus();
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: theme.color,
        },
        components: {
          Button: {
            colorBorder: theme.color,
            controlOutline: "rgba(3, 27, 51, 0.10)",
            controlOutlineWidth: 1,
          },
        },
      }}
      locale={frFR}
    >
      <Routes>
        {restrict && (
          <>
            <Route path="/accept/:id" exact element={<AcceptePage />} />
            <Route path="/refuse/:id" exact element={<RefusePage />} />

            <Route path="/reset/:token" exact element={<PasswordReset />} />
            <Route path="/forgot-password" exact element={<ForgotPassword />} />
          </>
        )}
        {/* ADMIN AND SUPER_ADMIN ROUTES */}
        {token &&
          (role === "SUPER_ADMIN" ||
            role === "SYS_ADMIN" ||
            role === "HR" ||
            role === "ACCOUNTANT") && (
            <>
              <Route
                path="/administration"
                children
                element={<Layout role={role} />}
              >
                <Route path="team/calendar" exact element={<AdminCalendar />} />
                <Route
                  path="/administration/equipments/managment"
                  element={<EquipmentsManagment />}
                />
                <Route
                  path=""
                  element={<Home status={restrict} setStatus={setRestrict} />}
                />
                <Route path="employees/form" element={<EmployeeForm />} />
                <Route path="employees/list" element={<EmployeesList />} />
                <Route
                  path="employees/:matricule"
                  element={<EmployeeProfile />}
                />
                <Route path="equipments/list" element={<EquipmentsList />} />

                <Route path="leaves/list" element={<LeavesList />} />
                <Route path="absences/list" element={<AbsenceListAdmin />} />
                <Route path="leaves/form" element={<LeaveFormAdmin />} />
                <Route path="requests" element={<RequestsList />} />
                <Route path="requests/archive" element={<HistoryLeave />} />
                <Route path="holidays" element={<Holidays />} />
                <Route path="logs" element={<Logs />} />
                <Route
                  path="parametre"
                  element={
                    <Parameters status={restrict} setStatus={setRestrict} />
                  }
                />
                <Route path="documents/form" element={<SharedDocuments />} />
                <Route path="documents/list" element={<DocumentsList />} />
                <Route
                  path="edit/request/:_id/:matricule"
                  element={<EditRequest />}
                />
                {/* <Route path="suggestions" element={<Suggestions />} /> */}
                <Route path="faq" element={<Faq />} />
              </Route>

              {/* FORCE TO HOME PAGE IF ROUTE UNDEFINED */}
              <Route
                path="*"
                exact
                element={<Navigate to="/administration/requests" />}
              />
            </>
          )}

        {/* PERSONNELLE ROUTES */}
        {token && role === "EMPLOYEE" && restrict === true && (
          <Route path="/" exact element={<LayoutClient />}>
            <Route path="/" exact element={<Default />} />
            <Route path="/faq" exact element={<FaqPage />} />
            <Route path="/profile" exact element={<Profile />} />
            <Route path="/tools/" exact element={<SharedDocs />} />
            <Route path="/request" exact element={<LeaveForm />} />
            <Route
              path="/manage/subordinates"
              element={<LeavesListEmployees />}
            />
            <Route path="/manage/self" element={<OwnLeaves />} />
            <Route path="/suggestions" element={<Suggestion />} />
            <Route
              path="/request/equipments"
              element={<RequestInfoComponents />}
            />
            <Route path="/self/equipments" element={<MyEquipments />} />
            <Route path="/equipments/available" element={<EquipmentList />} />
            <Route path="/absences" element={<AbsencesList />} />
            <Route path="/work/clocking" element={<TeleWorkClocking />} />
            <Route path="/trombinoscope" element={<Trombinoscope />} />
            <Route path="/team/calendar" element={<TeamCalendar />} />
            <Route path="/work/certificate" element={<AttestationTravail />} />

            {/* FORCE TO HOME PAGE IF ROUTE UNDEFINED */}
            <Route path="*" exact element={<Navigate to="/" />} />
          </Route>
        )}

        {/* COMMUN ROUTES */}
        <Route
          path="/login"
          exact
          element={
            <Login
              _setToken={setToken}
              _setRole={setRole}
              maintainance={restrict}
            />
          }
        />
        <Route path="/credentials/:id" exact element={<AccountCredentials />} />

        {!restrict && <Route path="*" exact element={<MaintainancePage />} />}

        {/* FORCE TO LOGIN PAGE IF NOT LOGGED IN */}
        <Route path="/" element={<Navigate to="/login" />} />

        {/* ERROR ROUTES */}
        {restrict && <Route path="*" exact element={<ErrorPage />} />}
      </Routes>
    </ConfigProvider>
  );
};

export default App;
