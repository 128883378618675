import React from "react";
import { Button, Modal, Tag } from "antd";
import axios from "../../../Utils/axios";

const PlatformActions = ({ status, setStatus }) => {
  const handleMaintainance = async () => {
    try {
      const { data } = await axios.patch("/api/platform/mode/maintainance");

      if (!data.status) {
        Modal.info({ content: "La plateforme a été mis en mode maintenance" });
        setStatus(data.status);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleActivation = async () => {
    try {
      const { data } = await axios.patch("/api/platform/mode/actif");
      if (data.status) {
        Modal.info({ content: "La plateforme a été mis en mode Actif" });
        setStatus(data.status);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="row py-2">
      <div className="col-lg-4 col-md-6 col-sm-12 d-flex flex-column">
        <div>
          <Button
            type="primary"
            style={{ fontWeight: "bold" }}
            onClick={handleMaintainance}
            disabled={!status}
            className="w-100"
          >
            Afficher en mode maintenance
          </Button>
        </div>
        <div>
          <Button
            type="primary"
            style={{ fontWeight: "bold" }}
            onClick={handleActivation}
            className="mt-2 w-100"
            disabled={status}
          >
            Remettre en mode actif
          </Button>
        </div>
      </div>
      <div className="col-lg-5 col-md-6 col-sm-12">
        <h6 className="fw-bold">
          Statut actuel de la plateforme:{" "}
          {status ? (
            <Tag style={{ fontSize: "13px" }} color="blue">
              Actif
            </Tag>
          ) : (
            <Tag style={{ fontSize: "13px" }} color="red">
              En maintenance
            </Tag>
          )}
        </h6>
      </div>
    </div>
  );
};

export default PlatformActions;
