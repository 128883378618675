import { useState, useEffect } from "react";
import { Collapse, Skeleton, Typography } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import axios from "../../Utils/axios";
import moment from "moment";
import FaqCard from "../../Admin/Components/FaqCard";

const { Text } = Typography;

const { Panel } = Collapse;
const panelStyle = {
  marginBottom: 24,
  background: "#f2f5fb",
  borderRadius: "8px",
  border: "none",
};
const FAQ = () => {
  // eslint-disable-next-line
  const [holidays, setHolidays] = useState([]);
  const [faqs, setFAQS] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get("/api/faq/");
      setFAQS(data.faqs);
      return data.faqs;
    }

    const fetchHolidays = async () => {
      const { data } = await axios.get("/api/holidays/");

      setHolidays(data.holidays);

      return data;
    };

    fetchHolidays();
    fetchData();
  }, []);
  return (
    <div className="bg-white shadow-md rounded-4">
      <div className="mt-4 p-3">
        <div className="mb-3 ">
          <h4 className="fw-bold">Questions Fréquentes</h4>
        </div>
        <Typography.Paragraph>
          Bienvenue dans notre FAQ. Cette page vise à répondre aux questions
          fréquemment posées par nos collaborateurs au sujet de Neopolis
          Development, de nos services et de notre portail de gestion des
          ressources humaines (N-Dev Harmonie). Si vous ne trouvez pas la
          réponse à votre question ici, n'hésitez pas à nous contacter
          directement pour obtenir une assistance supplémentaire.
        </Typography.Paragraph>

        {faqs.map((faq, idx) => {
          return <FaqCard faq={faq} idx={idx} access={false} />;
        })}

        {holidays.length ? (
          <Collapse
            bordered={false}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            <Panel header=" Les jours fériés ?" key={"1"} style={panelStyle}>
              {holidays.map((holiday, index) => {
                return (
                  <div key={index} className="py-1 px-2">
                    {" "}
                    <Text>{holiday.label}</Text>
                    {" : "}
                    {moment(holiday.Date).format("DD-MM-YYYY")} - {holiday.Days}{" "}
                    Jour(s)
                  </div>
                );
              })}
            </Panel>
          </Collapse>
        ) : (
          <Skeleton.Input active={true} size={"default"} block="true" />
        )}
      </div>
    </div>
  );
};

export default FAQ;
