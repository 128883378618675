import axios from "../../../Utils/axios";

import moment from "moment";

import { Spin, Typography, Image, Button, Descriptions, Tooltip } from "antd";

import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faUser,
  faPhone,
  faDownload,
  faUserEdit,
  faFingerprint,
  faClipboardUser,
  faMapLocationDot,
  faIdCard,
  faMoneyCheckDollar,
  faCalendarDay,
  faEnvelope,
  faPeopleLine,
  faFileContract,
} from "@fortawesome/free-solid-svg-icons";

import EditEmployeeDrawer from "../../Components/EditEmployeeDrawer";

import AvatarProfile from "../../../Assets/avatar-svgrepo-com.svg";
import SubordinatesList from "../../Components/SubordinatesList";
import GlobalContext from "../../../Context/GlobalContext";

const { Text, Paragraph } = Typography;

const EmployeeProfile = () => {
  const { role } = useContext(GlobalContext);
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  // eslint-disable-next-line

  const { matricule } = useParams();

  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(true);

  const [mobileBreakPoint, setMobileBreakPoint] = useState(true);
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const [drawerSize, setDrawerSize] = useState(320);

  useEffect(() => {
    async function fetchProfile() {
      const { data } = await axios.get("/api/employees/" + matricule);
      setProfile(data.employee[0]);
      setLoading(false);
    }
    fetchProfile();

    function handleWindowResize() {
      if (getWindowSize().innerWidth >= 992) {
        setDrawerSize(720);
        setMobileBreakPoint(false);
        setMobileBreakPoint(false);
      } else {
        setDrawerSize(320);
        setMobileBreakPoint(true);
      }
    }

    handleWindowResize();

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
    // eslint-disable-next-line
  }, [matricule]);

  return (
    <div className="pb-5">
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spin size="large" />
        </div>
      ) : (
        <div className="bg-white p-4 rounded shadow-sm">
          {(role === "RH" || role === "SUPER_ADMIN") && (
            <EditEmployeeDrawer
              drawerSize={drawerSize}
              visible={visible}
              onClose={onClose}
              variables={profile}
              setVariables={setProfile}
            />
          )}

          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-12">
              <Image
                style={{ borderRadius: "50%" }}
                src={profile.imgProfile || AvatarProfile}
              />
            </div>

            <div className="col-lg-9 col-md-8 col-sm-12">
              <Descriptions
                column={{
                  xxl: 3,
                  xl: 3,
                  lg: 2,
                  md: 1,
                  sm: 1,
                  xs: 1,
                }}
                title={
                  <Text strong style={{ fontSize: "24px" }}>
                    Informations de l'utilisateur
                  </Text>
                }
              >
                <Descriptions.Item
                  label={
                    <Text strong style={{ fontSize: "16px" }}>
                      <FontAwesomeIcon icon={faUser} /> Nom et prénom
                    </Text>
                  }
                >
                  <Text style={{ fontSize: "16px" }}>
                    {profile.fullName || "Indéfinie"}
                  </Text>
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <Text strong style={{ fontSize: "16px" }}>
                      <FontAwesomeIcon icon={faPhone} /> Téléphone
                    </Text>
                  }
                >
                  <Text style={{ fontSize: "16px" }}>
                    {profile.phoneNum || "Indéfinie"}
                  </Text>
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <Text strong style={{ fontSize: "16px" }}>
                      <FontAwesomeIcon icon={faFingerprint} /> Matricule
                    </Text>
                  }
                >
                  <Text style={{ fontSize: "16px" }}>
                    {profile.matricule || "Indéfinie"}
                  </Text>
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <Text strong style={{ fontSize: "16px" }}>
                      <FontAwesomeIcon icon={faClipboardUser} /> Poste
                    </Text>
                  }
                >
                  <Text style={{ fontSize: "16px" }}>
                    {profile.Poste || "Indéfinie"}
                  </Text>
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <Text strong style={{ fontSize: "16px" }}>
                      <FontAwesomeIcon icon={faMapLocationDot} /> Adresse
                    </Text>
                  }
                >
                  <Text style={{ fontSize: "16px" }}>
                    {profile.address || "Indéfinie"}
                  </Text>
                </Descriptions.Item>
                {profile._is_alternate && (
                  <Descriptions.Item
                    label={
                      <Text strong style={{ fontSize: "16px" }}>
                        <FontAwesomeIcon icon={faMapLocationDot} /> École
                      </Text>
                    }
                  >
                    <Text style={{ fontSize: "16px" }}>
                      {profile._alternant_school || "Indéfinie"}
                    </Text>
                  </Descriptions.Item>
                )}
              </Descriptions>

              {(role === "RH" || role === "SUPER_ADMIN") && (
                <Button
                  type="primary"
                  className="my-1"
                  style={{ marginRight: "7px" }}
                  onClick={showDrawer}
                >
                  <Text
                    strong
                    style={{
                      letterSpacing: "1px",
                      color: "white",
                    }}
                  >
                    <FontAwesomeIcon
                      style={{ marginRight: "3px" }}
                      icon={faUserEdit}
                    />
                    Modifier Profil
                  </Text>
                </Button>
              )}
            </div>
          </div>
          <div className="mt-5 shadow-md py-3 px-4">
            <Descriptions
              column={{
                xxl: 3,
                xl: 3,
                lg: 2,
                md: 1,
                sm: 1,
                xs: 1,
              }}
              title={
                <Text style={{ color: "#284c8b", fontSize: "20px" }} strong>
                  <FontAwesomeIcon
                    icon={faUser}
                    style={{ marginRight: "10px" }}
                  />{" "}
                  Informations personnelles
                </Text>
              }
            >
              <Descriptions.Item
                label={
                  <Text style={{ fontSize: "16px" }}>
                    <FontAwesomeIcon icon={faIdCard} /> Numéro CIN
                  </Text>
                }
              >
                <Paragraph copyable style={{ fontSize: "16px" }}>
                  {profile.CIN || "Indéfinie"}
                </Paragraph>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Text style={{ fontSize: "16px" }}>
                    <FontAwesomeIcon icon={faMoneyCheckDollar} /> RIB
                  </Text>
                }
              >
                <Paragraph copyable style={{ fontSize: "16px" }}>
                  {profile.Rib || "Indéfinie"}
                </Paragraph>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Text style={{ fontSize: "16px" }}>
                    {" "}
                    <FontAwesomeIcon
                      icon={faCalendarDay}
                      style={{ marginRight: "5px" }}
                    />
                    Date de naissance
                  </Text>
                }
              >
                <Paragraph copyable style={{ fontSize: "16px" }}>
                  {moment(profile.birthDate).format("DD-MM-YYYY") ||
                    "Indéfinie"}
                </Paragraph>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Text style={{ fontSize: "16px" }}>
                    {" "}
                    <FontAwesomeIcon icon={faMapLocationDot} /> Lieu de
                    naissance
                  </Text>
                }
              >
                <Paragraph copyable style={{ fontSize: "16px" }}>
                  {profile.birthPlace || "Indéfinie"}
                </Paragraph>
              </Descriptions.Item>
            </Descriptions>
          </div>
          <div className="mt-3 shadow-md py-3 px-4">
            <Descriptions
              column={{
                xxl: 3,
                xl: 3,
                lg: 2,
                md: 1,
                sm: 1,
                xs: 1,
              }}
              layout={mobileBreakPoint ? "vertical" : "horizontal"}
              title={
                <Text style={{ color: "#284c8b", fontSize: "20px" }} strong>
                  <FontAwesomeIcon
                    icon={faPhone}
                    style={{ marginRight: "10px" }}
                  />{" "}
                  Contact
                </Text>
              }
            >
              <Descriptions.Item
                span={2}
                label={
                  <Text style={{ fontSize: "16px" }}>
                    <FontAwesomeIcon icon={faEnvelope} /> Email professionnel
                  </Text>
                }
              >
                <Paragraph copyable style={{ fontSize: "16px" }}>
                  {profile.email || "Indéfinie"}
                </Paragraph>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Text style={{ fontSize: "16px" }}>
                    {" "}
                    <FontAwesomeIcon icon={faPhone} /> Téléphone
                  </Text>
                }
              >
                <Paragraph copyable style={{ fontSize: "16px" }}>
                  {profile.phoneNum || "Indéfinie"}
                </Paragraph>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Text style={{ fontSize: "16px" }}>
                    <FontAwesomeIcon icon={faEnvelope} /> Email personnel
                  </Text>
                }
              >
                <Paragraph copyable style={{ fontSize: "16px" }}>
                  {profile._personal_email || "Indéfinie"}
                </Paragraph>
              </Descriptions.Item>
            </Descriptions>
            <div
              className="container shadow-md p-3 rounded"
              style={{ backgroundColor: "#F0FAFD" }}
            >
              <Descriptions
                title={
                  <Text style={{ color: "#284c8b", fontSize: "18px" }} strong>
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{ marginRight: "10px" }}
                    />{" "}
                    Personne à prévenir en cas d'urgence
                  </Text>
                }
              >
                <Descriptions.Item
                  label={
                    <Text style={{ fontSize: "14px" }}>
                      <FontAwesomeIcon icon={faUser} />
                      Nom et prénom
                    </Text>
                  }
                >
                  <Paragraph copyable style={{ fontSize: "14px" }}>
                    {profile._urgence_name || "Indéfinie"}
                  </Paragraph>
                </Descriptions.Item>

                <Descriptions.Item
                  label={
                    <Text style={{ fontSize: "14px" }}>
                      {" "}
                      <FontAwesomeIcon
                        icon={faPeopleLine}
                        style={{ marginRight: "5px" }}
                      />
                      Relation
                    </Text>
                  }
                >
                  <Paragraph copyable style={{ fontSize: "14px" }}>
                    {profile._urgence_relation || "Indéfinie"}
                  </Paragraph>
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <Text style={{ fontSize: "14px" }}>
                      <FontAwesomeIcon icon={faPhone} /> Téléphone
                    </Text>
                  }
                >
                  <Paragraph copyable style={{ fontSize: "14px" }}>
                    {profile._urgence_phone || "Indéfinie"}
                  </Paragraph>
                </Descriptions.Item>
              </Descriptions>
            </div>
          </div>
          <div className="mt-3 shadow-md py-3 px-4">
            <Descriptions
              column={{
                xxl: 3,
                xl: 3,
                lg: 2,
                md: 1,
                sm: 1,
                xs: 1,
              }}
              title={
                <Text style={{ color: "#284c8b", fontSize: "20px" }} strong>
                  <FontAwesomeIcon
                    icon={faBriefcase}
                    style={{ marginRight: "10px" }}
                  />{" "}
                  Informations liées au travail
                </Text>
              }
            >
              <Descriptions.Item
                label={
                  <Text style={{ fontSize: "16px" }}>
                    <FontAwesomeIcon icon={faFingerprint} /> Matricule
                  </Text>
                }
              >
                <Paragraph copyable strong style={{ fontSize: "16px" }}>
                  {profile.matricule || "Indéfinie"}
                </Paragraph>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Text style={{ fontSize: "16px" }}>
                    <FontAwesomeIcon
                      icon={faCalendarDay}
                      style={{ paddingRight: "5px" }}
                    />
                    Date d'embauche
                  </Text>
                }
              >
                <Paragraph copyable style={{ fontSize: "16px" }}>
                  {moment(profile.embaucheDate).format("DD-MM-YYYY") ||
                    "Indéfinie"}
                </Paragraph>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Text style={{ fontSize: "16px" }}>
                    <FontAwesomeIcon icon={faClipboardUser} /> Poste
                  </Text>
                }
              >
                <Paragraph copyable style={{ fontSize: "16px" }}>
                  {profile.Poste || "Indéfinie"}
                </Paragraph>
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <Text style={{ fontSize: "16px" }}>
                    <FontAwesomeIcon
                      icon={faFileContract}
                      style={{ marginRight: "5px" }}
                    />
                    Type de contrat
                  </Text>
                }
              >
                <Paragraph copyable style={{ fontSize: "16px" }}>
                  {profile.contractType || "Indéfinie"}
                </Paragraph>
              </Descriptions.Item>
            </Descriptions>
            <div
              className="container shadow-md p-3 rounded"
              style={{ backgroundColor: "#F0FAFD" }}
            >
              <Descriptions
                title={
                  <Text style={{ color: "#284c8b", fontSize: "18px" }} strong>
                    <FontAwesomeIcon
                      icon={faDownload}
                      style={{ marginRight: "10px" }}
                    />{" "}
                    Pièces jointes
                  </Text>
                }
              >
                {profile._cin_File ? (
                  <Descriptions.Item>
                    <Paragraph style={{ fontSize: "14px" }}>
                      <Text>Pièce jointe CV</Text>
                      <Tooltip title="Télécharger">
                        <a
                          style={{
                            textDecoration: "none",
                            color: "#0d6efd",
                            marginLeft: "5px",
                          }}
                          href={profile.CV}
                          download={"CV_" + profile.fullName}
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </a>
                      </Tooltip>
                    </Paragraph>
                  </Descriptions.Item>
                ) : (
                  <Descriptions.Item>
                    <Paragraph style={{ fontSize: "14px" }}>
                      <Text delete>Pièce jointe CV</Text>
                      <Tooltip title="Aucune pièce jointe à télécharger">
                        {/* eslint-disable-next-line */}
                        <a
                          style={{
                            textDecoration: "none",
                            color: "#0d6efd",
                            marginLeft: "5px",
                          }}
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </a>
                      </Tooltip>
                    </Paragraph>
                  </Descriptions.Item>
                )}
                {profile._rib_File ? (
                  <Descriptions.Item>
                    <Paragraph style={{ fontSize: "14px" }}>
                      <Text>Pièce jointe RIB</Text>
                      <Tooltip title="Télécharger">
                        <a
                          style={{
                            textDecoration: "none",
                            color: "#0d6efd",
                            marginLeft: "5px",
                          }}
                          href={profile._rib_File}
                          download={"RIB_" + profile.fullName}
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </a>
                      </Tooltip>
                    </Paragraph>
                  </Descriptions.Item>
                ) : (
                  <Descriptions.Item>
                    <Paragraph style={{ fontSize: "14px" }}>
                      <Text delete>Pièce jointe RIB</Text>
                      <Tooltip title="Aucune pièce jointe à télécharger">
                        {/* eslint-disable-next-line */}
                        <a
                          style={{
                            textDecoration: "none",
                            color: "#0d6efd",
                            marginLeft: "5px",
                          }}
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </a>
                      </Tooltip>
                    </Paragraph>
                  </Descriptions.Item>
                )}
                {profile._cin_File ? (
                  <Descriptions.Item>
                    <Paragraph style={{ fontSize: "14px" }}>
                      <Text>Pièce jointe CIN</Text>
                      <Tooltip title="Télécharger">
                        <a
                          style={{
                            textDecoration: "none",
                            color: "#0d6efd",
                            marginLeft: "5px",
                          }}
                          href={profile._cin_File}
                          download={"CIN_" + profile.fullName}
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </a>
                      </Tooltip>
                    </Paragraph>
                  </Descriptions.Item>
                ) : (
                  <Descriptions.Item>
                    <Paragraph style={{ fontSize: "14px" }}>
                      <Text delete>Pièce jointe CIN</Text>
                      <Tooltip title="Aucune pièce jointe à télécharger">
                        {/* eslint-disable-next-line */}
                        <a
                          style={{
                            textDecoration: "none",
                            color: "#0d6efd",
                            marginLeft: "5px",
                          }}
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </a>
                      </Tooltip>
                    </Paragraph>
                  </Descriptions.Item>
                )}
              </Descriptions>
            </div>
          </div>

          <div className="mb-2 py-5 px-2">
            <Typography.Text className="fw-light h3 ">
              Subordonnées de l'utilisateur
            </Typography.Text>

            <SubordinatesList />
          </div>
          {/* <div className="mb-2 py-5 px-2">
            <Typography.Text className="fw-light h3 ">
              Historique des évènements
            </Typography.Text>
            {profile._historiqueSold > 0 && (
              <SoldHistory data={profile._historiqueSold} />
            )}
          </div> */}
        </div>
      )}
    </div>
  );
};

export default EmployeeProfile;
