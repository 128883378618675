import { Button, message, Space, Spin } from "antd";
import axios from "../../Utils/axios";
import Kalend, { CalendarView } from "kalend";
import "kalend/dist/styles/index.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import GlobalContext from "../../Context/GlobalContext";
import Popup from "./Popup";

const CalendarCongeModern = () => {
  const { theme } = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const [monthEvents, setMonthEvents] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedView, setSelectedView] = useState(CalendarView.WEEK);

  const [agendaEvents, setAgendaEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({});
  const kalendRef = useRef();

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const renderSummary = (event) => {
    let splitName = "";
    const splitedFullName = event.fullName.split(" ");
    splitedFullName.forEach((element) => {
      splitName = splitName.concat(element[0]);
    });
    return `${splitName} - ${
      event?._typeLeave === "Autorisation" ? "Autorisation" : "Congé"
    } ${event?._is_half_day ? "(" + event?._is_half_day + ")" : ""}`;
  };

  const onEventClick = (e) => {
    setSelectedEvent(e);
    showModal();
  };

  useEffect(() => {
    axios
      .get("/api/leaves/team/calendar")
      .then((res) => {
        const tempAgenda = res.data.map((elem, idx) => {
          if (elem?._typeLeave === "Autorisation") {
            elem.BeginingDate =
              dayjs(elem.BeginingDate).format("YYYY-MM-DD") +
              "T" +
              elem._duration.substring(0, 5) +
              ":00";
            elem.EndingDate =
              dayjs(elem.EndingDate).format("YYYY-MM-DD") +
              "T" +
              elem._duration.substring(8, 14) +
              ":00";
          } else if (elem?._is_half_day !== undefined) {
            if (elem?._is_half_day === "Aprés midi") {
              elem.BeginingDate =
                dayjs(elem.BeginingDate).format("YYYY-MM-DD") + "T12:00:00:00";
              elem.EndingDate =
                dayjs(elem.EndingDate).format("YYYY-MM-DD") + "T17:00:00:00";
            } else {
              elem.BeginingDate =
                dayjs(elem.BeginingDate).format("YYYY-MM-DD") + "T08:00:00:00";
              elem.EndingDate =
                dayjs(elem.EndingDate).format("YYYY-MM-DD") + "T12:00:00:00";
            }
          }
          const event = {
            id: idx,
            fullName: elem?.fullName,
            _special_leave: elem._special_leave,
            _is_half_day: elem?._is_half_day,
            _typeLeave: elem?._typeLeave,
            _duration: elem?._duration,
            color:
              elem._typeLeave === "Congé annuel"
                ? "#ADC6FF"
                : elem._typeLeave === "Autorisation"
                ? "#D3ADF7"
                : // : elem._typeLeave==="Télétravail"? "cyan"
                elem._typeLeave === "Congé Special"
                ? "#FFA39E"
                : elem._typeLeave === "Congé Maladie"
                ? "#FFE58F"
                : elem._typeLeave === "Congé sans solde"
                ? "#D3ADF7"
                : "#ADC6FF",
            allDay:
              (elem?._typeLeave !== "Autorisation" &&
                elem?._is_half_day === undefined) ||
              dayjs(elem.BeginingDate).format("DD/MM/YYYY") !==
                dayjs(elem.EndingDate).format("DD/MM/YYYY"),
            startAt: dayjs(elem.BeginingDate).toISOString(),
            endAt:
              elem?._typeLeave === "Autorisation" ||
              elem?._is_half_day !== undefined
                ? dayjs(elem.EndingDate).toISOString()
                : dayjs(elem.EndingDate).toISOString(),
            summary:
              elem.fullName +
              " : " +
              `${
                elem?._typeLeave === "Autorisation" ? "Autorisation" : "Congé"
              }` +
              `${
                elem?._typeLeave === "Autorisation"
                  ? " (" + elem._duration + ")"
                  : ""
              }` +
              `${
                elem?._typeLeave === "Congé annuel" &&
                elem?._is_half_day !== undefined
                  ? " - demi journée (" + elem?._is_half_day + ")"
                  : ""
              }` +
              `${
                elem?._typeLeave === "Congé sans solde" &&
                elem?._is_half_day !== undefined
                  ? " - demi journée (" + elem?._is_half_day + ")"
                  : " "
              }` +
              `${
                elem?._typeLeave === "Congé Special"
                  ? " (" + elem._special_leave + ")"
                  : ""
              }`,
          };
          return event;
        });

        const tempMonth = res.data.map((elem, idx) => {
          if (elem?._typeLeave === "Autorisation") {
            elem.BeginingDate =
              dayjs(elem.BeginingDate).format("YYYY-MM-DD") +
              "T" +
              elem._duration.substring(0, 5) +
              ":00";
            elem.EndingDate =
              dayjs(elem.EndingDate).format("YYYY-MM-DD") +
              "T" +
              elem._duration.substring(8, 14) +
              ":00";
          } else if (elem?._is_half_day !== undefined) {
            if (elem?._is_half_day === "Aprés midi") {
              elem.BeginingDate =
                dayjs(elem.BeginingDate).format("YYYY-MM-DD") + "T12:00:00:00";
              elem.EndingDate =
                dayjs(elem.EndingDate).format("YYYY-MM-DD") + "T17:00:00:00";
            } else {
              elem.BeginingDate =
                dayjs(elem.BeginingDate).format("YYYY-MM-DD") + "T08:00:00:00";
              elem.EndingDate =
                dayjs(elem.EndingDate).format("YYYY-MM-DD") + "T12:00:00:00";
            }
          }
          const event = {
            fullName: elem?.fullName,
            _is_half_day: elem?._is_half_day,
            _typeLeave: elem?._typeLeave,
            _special_leave: elem._special_leave,
            _duration: elem?._duration,
            id: idx,
            color:
              elem._typeLeave === "Congé annuel"
                ? "#ADC6FF"
                : elem._typeLeave === "Autorisation"
                ? "#D3ADF7"
                : // : elem._typeLeave==="Télétravail"? "cyan"
                elem._typeLeave === "Congé Special"
                ? "#FFA39E"
                : elem._typeLeave === "Congé Maladie"
                ? "#FFE58F"
                : elem._typeLeave === "Congé sans solde"
                ? "#D3ADF7"
                : "#ADC6FF",
            allDay:
              (elem?._typeLeave !== "Autorisation" &&
                elem?._is_half_day === undefined) ||
              dayjs(elem.BeginingDate).format("DD/MM/YYYY") !==
                dayjs(elem.EndingDate).format("DD/MM/YYYY"),
            startAt: dayjs(elem.BeginingDate).toISOString(),
            endAt:
              elem?._typeLeave === "Autorisation" ||
              elem?._is_half_day !== undefined
                ? dayjs(elem.EndingDate).toISOString()
                : dayjs(elem.EndingDate).toISOString(),
            summary: renderSummary(elem),
          };
          return event;
        });
        setMonthEvents(tempMonth);
        setAgendaEvents(tempAgenda);
        setLoading(false);

        setTimeout(() => {
          const list = document.querySelectorAll(
            ".Kalend__Event__time__type-agenda"
          );

          list.forEach((elem) => {
            if (elem.textContent === "All day") {
              elem.textContent = "Journée entière";
            }
          });
        }, [100]);
      })

      .catch((err) => {
        message.error("Something went so wrong !");
      });
  }, []);

  return (
    <div className="h-100 w-100">
      <div className="w-100 h-100">
        {loading ? (
          <Spin
            size="large"
            className=" d-flex justify-content-center align-items-center align-content-center"
          />
        ) : (
          <div>
            <div className="d-flex justify-content-end mb-3">
              <Space>
                <Button
                  type="primary"
                  onClick={() => setSelectedView(CalendarView.AGENDA)}
                >
                  Jour
                </Button>

                <Button
                  type="primary"
                  onClick={() => setSelectedView(CalendarView.WEEK)}
                >
                  Semaine
                </Button>

                <Button
                  type="primary"
                  onClick={() => setSelectedView(CalendarView.MONTH)}
                >
                  Mois
                </Button>
              </Space>
            </div>
            <Popup
              key={selectedEvent?.id || 0}
              isModalOpen={isModalOpen}
              handleOk={handleOk}
              handleCancel={handleCancel}
              data={selectedEvent}
            />
            <div style={{ height: "70vh" }}>
              <Kalend
                colors={{
                  light: {
                    primaryColor: theme.color,
                  },
                  dark: {
                    primaryColor: theme.color,
                  },
                }}
                // kalendRef={kalendRef}
                // selectedView={selectedView}
                events={
                  selectedView === CalendarView.MONTH
                    ? monthEvents
                    : agendaEvents
                }
                disabledViews={[]}
                hourHeight={60}
                onEventClick={onEventClick}
                initialDate={dayjs().toISOString()}
                initialView={CalendarView.MONTH}
                timezone="Europe/Berlin"
                kalendRef={kalendRef}
                showTimeLine={false}
                isDark={false}
                autoScroll={true}
                selectedView={selectedView}
                isNewEventOpen={false}
                disabledDragging={true}
                timeFormat={"24"}
                weekDayStart={"Monday"}
                language={"fr"}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CalendarCongeModern;
