import React from "react";
import { Button, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import ModalSuccess from "../../Assets/Confirmed-pana.svg";

const ConfirmEmailModal = ({ TriggerModal, Trigger }) => {
  const Navigate = useNavigate();

  const handleOk = () => {
    TriggerModal(false);
    Navigate("/login");
  };

  return (
    <>
      <Modal
        visible={Trigger}
        title="L'e-mail a été envoyé avec succès"
        onCancel={() => TriggerModal(false)}
        footer={[
          <Button key="submit" type="primary" onClick={handleOk}>
            Confirmer
          </Button>,
        ]}
      >
        <div className="d-flex flex-column align-items-center justify-content-center">
          <img
            src={ModalSuccess}
            alt="Modal success"
            style={{ width: "65%" }}
          />
          <h6 className="fw-light" style={{ letterSpacing: "1px" }}>
            Confirmez votre e-mail !
          </h6>
        </div>
      </Modal>
    </>
  );
};

export default ConfirmEmailModal;
