import { Button, Form, Input, Modal, Select } from "antd";
import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import {
  faPlus,
  faHeadphones,
  faComputerMouse,
  faPlug,
  faKeyboard,
  faLaptop,
  faDesktop,
} from "@fortawesome/free-solid-svg-icons";

const { Option } = Select;

const EditModalEquipment = ({ record }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ ...record });
  }, [record]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Button size="small" onClick={showModal}>
        Modifier
      </Button>
      <Modal
        title="Modifier l'équipement"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 20 }}>
          <Form.Item name="_categorie" label="Catégorie">
            <Select placeholder="Choisir une catégorie">
              <Option value="ECRAN">
                Ecran <FontAwesomeIcon icon={faDesktop} />
              </Option>
              <Option value="PC">
                PC <FontAwesomeIcon icon={faLaptop} />
              </Option>
              <Option value="SOURIS">
                Souris <FontAwesomeIcon icon={faComputerMouse} />
              </Option>
              <Option value="CLAVIER">
                Clavier <FontAwesomeIcon icon={faKeyboard} />
              </Option>
              <Option value="CABLE">
                Cable <FontAwesomeIcon icon={faPlug} />
              </Option>
              <Option value="CASQUE">
                Casque <FontAwesomeIcon icon={faHeadphones} />
              </Option>
            </Select>
          </Form.Item>
          <Form.Item name="_ref" label="Réference">
            <Input placeholder="Réference" />
          </Form.Item>
          <Form.Item name="_brand" label="Marque">
            <Input placeholder="Mark (HP/DELL/SAMSUNG)" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default EditModalEquipment;
