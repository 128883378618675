import { useState, useEffect } from "react";
import { Card, Typography, Spin, Modal, Form, Input, Button } from "antd";

import { useNavigate, useParams } from "react-router-dom";

import moment from "moment";
import axios from "../../Utils/axios";

import "../AcceptePage/AcceptePage.css";

const { Text } = Typography;
const { TextArea } = Input;

const RefusePage = () => {
  const [form] = Form.useForm();

  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(true);
  const [request, setRequest] = useState("");

  const Navigate = useNavigate();

  const { id } = useParams();

  const UpdateLeave = async (formValues) => {
    const { data } = await axios.patch("/api/leaves/options/" + id, {
      status: "Rejected",
      message: formValues.message,
    });
    if (data.status === "success") {
      Modal.success({
        title: "Action Terminé!",
      });
      window.location.reload();
    }

    return data;
  };

  useEffect(() => {
    async function fetchRequest() {
      const { data } = await axios.get("/api/leaves/" + id);
      if (data.Leave.status !== "Pending") {
        setLoading(false);
        setSent(true);
        Modal.info({
          title: "La demande a déjà reçu une réponse ou a été annulée",
        });
        return data;
      }
      if (data.status === "success") {
        setRequest(data.Leave);
        setLoading(false);
        return data;
      }
    }
    fetchRequest();
    // eslint-disable-next-line
  }, []);

  const handleReturn = () => {
    Navigate("/");
  };

  return (
    <div className="min-vh-100 h-100 errorPageBackground d-flex align-items-center">
      <div className="container pt-4 d-flex flex-column align-items-center">
        {loading ? (
          <>
            <div className="d-flex justify-content-center">
              <Spin size="large" />
            </div>
            <h5
              className="fw-bold text-center mt-3"
              style={{ letterSpacing: "2px", color: "#001529" }}
            >
              Cela peut prendre un moment...
            </h5>
          </>
        ) : (
          <>
            {!sent ? (
              <div className="col-lg-6 col-md-8 col-sm-12 d-flex flex-column align-items-center">
                <Card
                  title={
                    <Text style={{ fontSize: "20px" }}>
                      Rejet de la demande de '{request._typeLeave}'
                    </Text>
                  }
                  bordered={false}
                  style={{
                    width: "100%",
                  }}
                >
                  <p style={{ fontSize: "16px" }}>
                    De{" "}
                    <span className="fw-bold">
                      {moment(request.BeginingDate).format("YYYY-MM-DD")}
                    </span>{" "}
                    à{" "}
                    <span className="fw-bold">
                      {moment(request.EndingDate).format("YYYY-MM-DD")}
                    </span>
                    <br />
                    Durée:{" "}
                    <span className="fw-bold">
                      {request.DaysNumber} jour(s)
                    </span>
                  </p>
                  <Form form={form} layout="vertical" onFinish={UpdateLeave}>
                    <Form.Item
                      label={<Text style={{ fontSize: "16px" }}>Motif</Text>}
                      name="message"
                    >
                      <TextArea size="large" maxLength={300} rows={4} />
                    </Form.Item>
                    <Form.Item>
                      <Button size="large" htmlType="submit">
                        Soumettre
                      </Button>
                    </Form.Item>
                  </Form>
                </Card>
              </div>
            ) : (
              <div className="mt-3 text-center">
                <p style={{ fontSize: "18px" }}>
                  La demande a déjà reçu une réponse ou a été annulée
                </p>

                <Button size="large" onClick={handleReturn}>
                  Accueil
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default RefusePage;
