import { Button, DatePicker, Form, Input, Modal, Select } from "antd";
import React, { useState } from "react";
import axiosInstance from "../../../Utils/axios";

const SelfAccountForm = ({ employees }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (formValues) => {
    formValues.Role = "EMPLOYEE";

    setLoading(true);
    try {
      const { data } = await axiosInstance.post(
        "/api/employees/half",
        formValues
      );
      if (data) {
        Modal.success({ title: "Le profil a été crée avec succès !" });
        form.resetFields();
      }
    } catch (error) {
      Modal.error({
        title: "Impossible de créer un compte!",
        content: <p>{error.response.data.message}</p>,
      });
      setLoading(false);
    }
  };
  return (
    <Form
      onFinish={handleSubmit}
      form={form}
      style={{ backgroundColor: "#f1f6f9" }}
      className="p-3 rounded-3 shadow-sm"
      labelCol={{
        sm: { span: 10 },
        md: { span: 8 },
        lg: { span: 7 },
      }}
      wrapperCol={{
        sm: { span: 16 },
        md: { span: 14 },
        lg: { span: 16 },
      }}
      layout="horizontal"
      size="middle"
    >
      <Form.Item
        label="Nom et prénom"
        name="fullName"
        validateTrigger={"onDone"}
        rules={[{ required: true, message: "Merci de remplir le champ" }]}
      >
        <Input
          onChange={(e) => {
            form.setFieldsValue({
              fullName: e.target.value.replace(/[^a-zA-Z ]/g, ""),
            });
          }}
        />
      </Form.Item>
      <Form.Item
        label="Email Professionel"
        name="email"
        validateTrigger={"onDone"}
        rules={[
          { type: "email", message: "Veuillez entrer un email valid" },
          { required: true, message: "Merci de remplir le champ" },
          // {
          //   validator: (_, value) => {
          //     if (value) {
          //       if (!value.includes("@neopolis-dev.com")) {
          //         return Promise.reject(
          //           "l'email professionnel doit se terminer par '@neopolis-dev.com'"
          //         );
          //       } else {
          //         return Promise.resolve();
          //       }
          //     } else {
          //       return Promise.resolve();
          //     }
          //   },
          // },
        ]}
      >
        <Input placeholder="email@neopolis-dev.com" />
      </Form.Item>
      <Form.Item
        label="Type de contrat"
        name="contractType"
        rules={[{ required: true, message: "Merci de remplir le champ" }]}
      >
        <Select placeholder="Type de contract (CDI, CIVP, KARAMA, CDD)">
          <Select.Option key="1" value="CDI">
            CDI
          </Select.Option>
          <Select.Option key="2" value="CIVP">
            CIVP
          </Select.Option>
          <Select.Option key="3" value="KARAMA">
            KARAMA
          </Select.Option>
          <Select.Option key="4" value="CDD">
            CDD
          </Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Date d'embauche"
        name="embaucheDate"
        rules={[{ required: true, message: "Merci de remplir le champ" }]}
      >
        <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
      </Form.Item>
      <Form.Item label="Responsable" name="_idResponsable">
        <Select placeholder="choisir un responsable pour cet employé">
          {employees.map((emp) => {
            return (
              <Select.Option value={emp.matricule}>
                {emp.fullName} #{emp.matricule}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item
        label="Matricule"
        name="matricule"
        rules={[{ required: true, message: "Merci de remplir le champ" }]}
      >
        <Input maxLength={5} />
      </Form.Item>

      <Form.Item
        label="Poste"
        name="Poste"
        rules={[{ required: true, message: "Merci de remplir le champ" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        maxLength={2}
        minLength={0}
        type="Number"
        label="Solde"
        name="sold"
        rules={[{ required: true, message: "Merci de remplir le champ" }]}
        validateTrigger="onDone"
      >
        <Input
          style={{ width: "100%" }}
          onChange={(e) => {
            form.setFieldsValue({
              sold: e.target.value.replace(/[^0-9 .]/g, ""),
            });
          }}
        />
      </Form.Item>

      <div className="d-flex justify-content-evenly">
        <Button>Annuler</Button>
        <Button type="primary" htmlType="submit">
          Ajouter
        </Button>
      </div>
    </Form>
  );
};

export default SelfAccountForm;
