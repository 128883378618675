import { Form, Input, Button, Typography, message } from "antd";
import axios from "../../Utils/axios";
import LoginTag from "../../Assets/Reset-password-pana.svg";
import Logo from "../../Assets/logo-neop-dev-bleu.png";
import { useNavigate, useParams } from "react-router-dom";

const { Text } = Typography;

const PasswordReset = () => {
  const Navigate = useNavigate();

  const { token } = useParams();

  const handleReset = async (formValues) => {
    try {
      const { data } = await axios.patch(
        "/api/auth/reset-password/" + token,
        formValues
      );
      if (data) {
        message.success("le mot de passe a été réinitialisé", 3);
        setTimeout(() => {
          Navigate("/login");
        }, 3000);
      }
    } catch (err) {}
  };
  return (
    <div className="min-vh-100 h-100 bg-custom">
      <div className="container">
        <div className="d-flex justify-content-center px-4">
          <div className="col-lg-9 col-md-12 col-sm-12 row bg-light mt-5 bg-opacity-75 p-3 rounded-5 shadow flex-column-reverse flex-lg-row ">
            <div className="col-lg-6 col-md-6 col-sm-12 p-4">
              <h5
                className="fw-light text-center mb-4"
                style={{ letterSpacing: "1px" }}
              >
                Réinitialisez votre mot de passe
              </h5>
              <Form
                labelCol={{
                  xs: { span: 24 },
                  sm: { span: 24 },
                  md: { span: 24 },
                }}
                wrapperCol={{
                  xs: { span: 24 },
                  sm: { span: 24 },
                  md: { span: 24 },
                  lg: { span: 24 },
                }}
                layout="vertical"
                size="middle"
                onFinish={handleReset}
              >
                <Form.Item
                  label="Nouvelle mot de passe"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "S’il vous plaît entrer votre mot de passe!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  label="Confirmer mot de passe"
                  name="passwordConfirm"
                  rules={[
                    {
                      required: true,
                      message: "S’il vous plaît entrer votre mot de passe!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item>
                  <div className="d-flex justify-content-between">
                    <Button type="primary" htmlType="submit">
                      Réinitialiser !
                    </Button>
                  </div>
                </Form.Item>
              </Form>
              <div className="">
                <div className="d-flex justify-content-between">
                  <Text type="primary" className="mt-2" strong>
                    ©2022 Neopolis-dev
                  </Text>
                  <img src={Logo} style={{ height: "35px" }} alt="Neopolis" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 border-left-custom d-flex align-items-center justify-content-center  ">
              <img src={LoginTag} alt="Neopolis" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
