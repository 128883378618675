import { useEffect, useState } from "react";
import { Form, Input, DatePicker, Select, Radio, notification } from "antd";
import axiosInstance from "../../../Utils/axios";
import { SmileOutlined, LoadingOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import UploadInput from "../../../Components/UploadInput";
import StepsForm from "../../Components/StepsForm";
import PhoneInput from "react-phone-number-input";
import TypingTextAnimation from "react-typing-text-animation";
import "react-phone-number-input/style.css";

const { Option } = Select;
const AccountCredentials = () => {
  const [loading, setLoading] = useState(true);
  const [isAlternate, setIsAlternate] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();

  const openNotification = (name) => {
    api.open({
      message: "Bienvenue " + name,
      description: (
        <TypingTextAnimation
          text="Veuillez remplir toutes les données nécessaires pour accéder à votre compte."
          cursorDuration="1"
          cursor={<div className="d-none"></div>}
          delay="20"
        />
      ),
      icon: (
        <SmileOutlined
          style={{
            color: "#108ee9",
          }}
        />
      ),
      duration: 0,
    });
  };

  const { id } = useParams();
  const Navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axiosInstance.get("/api/employees/self/" + id);
        if (data.employee.status === true && data.employee.selfGen === false) {
          api.open({
            message: "L'opération n'est pas autorisée.",
            description: (
              <TypingTextAnimation
                text="Vous serez redirigé vers la page de connexion"
                cursorDuration="1"
                cursor={<div className="d-none"></div>}
                delay="20"
              />
            ),
            icon: (
              <LoadingOutlined
                style={{
                  fontSize: 24,
                }}
                spin
              />
            ),
            type: "error",
            duration: 0,
          });
          setTimeout(() => {
            Navigate("/login");
          }, [3000]);
        } else {
          form.setFieldsValue({ ...data.employee });
          openNotification(data.employee.fullName);
          setLoading(false);
        }
      } catch (error) {
        api.open({
          message: "Erreur",
          description: error.response.data.message,
          type: "error",
          duration: 4.5,
        });
      }
    }
    fetchData();
  }, []);

  const setCV = (base64) => {
    form.setFieldsValue({
      CV: base64,
    });
  };

  const setProfilePic = (base64) => {
    form.setFieldsValue({
      imgProfile: base64,
    });
  };

  const setCIN = (base64) => {
    form.setFieldsValue({
      _cin_File: base64,
    });
  };

  const setRIB = (base64) => {
    form.setFieldsValue({
      _rib_File: base64,
    });
  };

  const steps = [
    {
      title: "Généralités",
      content: (
        <>
          <Form.Item
            label="Nom et prénom"
            name="fullName"
            validateTrigger={"onDone"}
            rules={[{ required: true, message: "Merci de remplir le champ" }]}
          >
            <Input
              disabled
              onChange={(e) => {
                form.setFieldsValue({
                  fullName: e.target.value.replace(/[^a-zA-Z ]/g, ""),
                });
              }}
            />
          </Form.Item>

          <Form.Item
            label="Numéro du télèphone"
            name="phoneNum"
            validateTrigger={"onDone"}
            rules={[{ required: true, message: "Merci de remplir le champ" }]}
          >
            <PhoneInput defaultCountry="TN" placeholder="12 345 678" />
          </Form.Item>

          <Form.Item
            label="Email Professionel"
            name="email"
            validateTrigger={"onDone"}
            // rules={[
            //   { type: "email", message: "Veuillez entrer un email valid" },
            //   { required: true, message: "Merci de remplir le champ" },
            //   {
            //     validator: (_, value) => {
            //       if (value) {
            //         if (!value.includes("@neopolis-dev.com")) {
            //           return Promise.reject(
            //             "l'email professionnel doit se terminer par '@neopolis-dev.com'"
            //           );
            //         } else {
            //           return Promise.resolve();
            //         }
            //       } else {
            //         return Promise.resolve();
            //       }
            //     },
            //   },
            // ]}
          >
            <Input disabled placeholder="email@neopolis-dev.com" />
          </Form.Item>

          <Form.Item
            label="Email Personelle"
            name="_personal_email"
            validateTrigger={"onDone"}
            rules={[
              { required: true, message: "Merci de remplir le champ" },
              { type: "email", message: "veuillez entrer un email valid" },
            ]}
          >
            <Input type="email" />
          </Form.Item>

          <Form.Item label="Alternance" name="_is_alternate">
            <Radio.Group
              onChange={(e) => {
                form.setFieldsValue({
                  ...form.getFieldsValue(),
                  _is_alternate: e.target.value,
                });
                setIsAlternate(e.target.value);
              }}
              defaultValue={false}
              buttonStyle="solid"
            >
              <Radio.Button value={true}>Oui</Radio.Button>
              <Radio.Button value={false}>Non</Radio.Button>
            </Radio.Group>
          </Form.Item>
          {isAlternate && (
            <Form.Item
              label="École"
              name="_alternant_school"
              rules={[{ required: true, message: "Veuillez remplir le champ" }]}
            >
              <Input />
            </Form.Item>
          )}

          <Form.Item
            name="CV"
            label="CV"
            validateTrigger={"onDone"}
            rules={[{ required: true, message: "Merci de remplir le champ" }]}
          >
            <UploadInput setFile={setCV} />
          </Form.Item>

          <Form.Item name="imgProfile" label="Photo de profil">
            <UploadInput setFile={setProfilePic} />
          </Form.Item>
        </>
      ),
    },
    {
      title: "Personnelle",
      content: (
        <>
          <Form.Item
            label="Date de naissance"
            name="birthDate"
            rules={[{ required: true, message: "Merci de remplir le champ" }]}
          >
            <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
          </Form.Item>

          <Form.Item
            label="Lieu naissance"
            name="birthPlace"
            rules={[{ required: true, message: "Merci de remplir le champ" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Adresse"
            name="address"
            rules={[{ required: true, message: "Merci de remplir le champ" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            maxLength={8}
            minLength={8}
            type="Number"
            label="CIN"
            name="CIN"
            rules={[
              {
                pattern: /^[\d]{8,8}$/,
                message: "CIN ne doit avoir que 8 chiffres",
              },
              { required: true, message: "Merci de remplir le champ" },
            ]}
            validateTrigger="onDone"
          >
            <Input
              style={{ width: "100%" }}
              maxLength={8}
              onChange={(e) => {
                form.setFieldsValue({
                  CIN: e.target.value.replace(/[^0-9]/g, ""),
                });
              }}
            />
          </Form.Item>

          <Form.Item
            name="_cin_File"
            label="Joindre CIN"
            rules={[{ required: true, message: "Merci de remplir le champ" }]}
          >
            <UploadInput setFile={setCIN} />
          </Form.Item>
        </>
      ),
    },
    {
      title: "Liés au travail",
      content: (
        <>
          <Form.Item
            label="Matricule"
            name="matricule"
            rules={[{ required: true, message: "Merci de remplir le champ" }]}
          >
            <Input disabled maxLength={5} />
          </Form.Item>

          <Form.Item
            label="Poste"
            name="Poste"
            rules={[{ required: true, message: "Merci de remplir le champ" }]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="RIB"
            name="Rib"
            rules={[
              { required: true, message: "Merci de remplir le champ" },
              {
                pattern: /^[\d]{20,20}$/,
                message: "RIB ne doit avoir que 20 chiffres",
              },
            ]}
          >
            <Input
              style={{ width: "100%" }}
              maxLength={20}
              onChange={(e) => {
                form.setFieldsValue({
                  Rib: e.target.value.replace(/[^0-9]/g, ""),
                });
              }}
            />
          </Form.Item>

          <Form.Item
            name="_rib_File"
            label="Joindre RIB"
            rules={[{ required: true, message: "Merci de remplir le champ" }]}
          >
            <UploadInput setFile={setRIB} />
          </Form.Item>
        </>
      ),
    },
    {
      title: "Personne à prévenir en cas d'urgence",
      content: (
        <>
          <Form.Item
            name="_urgence_name"
            label="Nom et prénom"
            rules={[{ required: true, message: "Merci de remplir le champ" }]}
          >
            <Input
              onChange={(e) => {
                form.setFieldsValue({
                  _urgence_name: e.target.value.replace(/[^a-zA-Z ]/g, ""),
                });
              }}
            />
          </Form.Item>

          <Form.Item
            validateTrigger={"onDone"}
            name="_urgence_relation"
            label="Relation"
            rules={[{ required: true, message: "Merci de remplir le champ" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="_urgence_phone"
            label="Numéro du télèphone"
            rules={[{ required: true, message: "Merci de remplir le champ" }]}
          >
            <PhoneInput defaultCountry="TN" placeholder="12 345 678" />
          </Form.Item>
        </>
      ),
    },
  ];

  const handleFinish = async () => {
    const formValues = form.getFieldsValue(true);
    setLoading(true);
    try {
      const { data } = await axiosInstance.patch(
        "/api/employees/save/" + id,
        formValues
      );
      api.open({
        message: "Le compte a été mis à jour avec succès.",
        description: (
          <TypingTextAnimation
            text={
              "Consultez votre email pour obtenir le mot de passe de votre compte."
            }
            cursorDuration="1"
            cursor={<div className="d-none"></div>}
            delay="20"
          />
        ),
        type: "success",
        duration: 0,
      });

      setTimeout(() => {
        Navigate("/login");
      }, [4500]);
    } catch (error) {
      api.open({
        message: "Erreur.",
        description: (
          <TypingTextAnimation
            text={error.response.data.message}
            cursorDuration="1"
            cursor={<div className="d-none"></div>}
            delay="20"
          />
        ),
        type: "error",
        duration: 0,
      });
      setLoading(false);
    }
  };

  return (
    <div
      className="min-vh-100 vw-100 pt-5 pb-5"
      style={{ backgroundColor: "#f1f6f9" }}
    >
      <div className="container p-5 bg-white shadow-md rounded-5">
        {contextHolder}
        <Form
          form={form}
          disabled={loading}
          labelCol={{
            sm: { span: 10 },
            md: { span: 8 },
            lg: { span: 7 },
          }}
          wrapperCol={{
            sm: { span: 16 },
            md: { span: 14 },
            lg: { span: 16 },
          }}
          layout="horizontal"
          size="middle"
          onFinish={handleFinish}
        >
          <StepsForm steps={steps} form={form} loading={loading} />
        </Form>
      </div>
    </div>
  );
};

export default AccountCredentials;
