import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../../Context/GlobalContext";
import {
  Avatar,
  Button,
  Form,
  Input,
  Typography,
  message,
  Radio,
  Spin,
  notification,
  Space,
} from "antd";
import {
  InboxOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import axiosInstance from "../../Utils/axios";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

const PostForm = () => {
  const { profile } = useContext(GlobalContext);
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();
  const handleUpload = async (info) => {
    let fileList = [...info.fileList];
    // Accept 5 files only
    fileList = fileList.slice(-5);

    fileList.forEach(function (file, index) {
      let reader = new FileReader();
      reader.onload = (e) => {
        file.base64 = e.target.result;
      };
      reader.readAsDataURL(file.originFileObj);
    });

    setFileList(fileList);
  };

  const hnadleFinish = async (formValues) => {
    setLoading(true);

    formValues._file = fileList;
    try {
      const { data } = await axiosInstance.post("/api/suggestion/", formValues);
      api["success"]({
        message: "Soumis avec succès",
        description: data.message,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      api["error"]({
        message: "Oups! Une erreur est survenue",
        description: error.response.data.message,
      });
    }
    handleCancel();
  };
  const handleCancel = () => {
    form.resetFields();
  };
  return (
    <div className="">
      {contextHolder}
      <div className="d-flex col-lg-12 col-md-12 col-sm-12 ">
        <Avatar
          size={{
            xs: 24,
            sm: 32,
            md: 40,
            lg: 40,
            xl: 48,
            xxl: 64,
          }}
          src={<img src={profile?.imgProfile} alt="avatar" />}
        />
        <Form
          style={{ marginLeft: "5px" }}
          className="my-2 w-100"
          layout="vertical"
          disabled={loading}
          form={form}
          onFinish={hnadleFinish}
        >
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 d-flex flex-column justify-content-between">
              <div>
                <Form.Item
                  name="_message"
                  rules={[
                    { required: true, message: "Veuillez remplir le champ" },
                  ]}
                >
                  <Input.TextArea
                    allowClear
                    placeholder="Text..."
                    autoSize={{
                      minRows: 4,
                      maxRows: 9,
                    }}
                  />
                </Form.Item>
              </div>
            </div>

            <div className="row d-flex flex-column flex-lg-row flex-md-row justify-content-between">
              <div className="col-lg-5 col-md-12 col-sm-12">
                <Form.Item name="_file" label="Fichier(s) (optionnel)">
                  <Dragger
                    style={{
                      border: "1.6px dashed #303030",
                      backgroundColor: "#FFFFFF",
                    }}
                    multiple={true}
                    onChange={handleUpload}
                    beforeUpload={() => false}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined className="InboxColorCustum" />
                    </p>
                    <p className="custum-text-upload">
                      Cliquez ou faites glisser le fichier dans cette zone
                    </p>
                  </Dragger>
                </Form.Item>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <Form.Item label="Lien(s) (optionnel)" defaultOpen>
                  <Form.List name="_link" defaultOpen initialValue={[""]}>
                    {(fields, { add, remove }, { errors }) => (
                      <div>
                        {fields.map((field, index) => (
                          <Form.Item required={false} key={field.key}>
                            <Form.Item
                              {...field}
                              validateTrigger={["onChange", "onBlur"]}
                              rules={[
                                {
                                  required: true,
                                  whitespace: false,
                                  message:
                                    "Veuillez saisir un lien ou supprimer ce champ.",
                                },
                              ]}
                              noStyle
                            >
                              <Input
                                placeholder="https//mywebsite.com"
                                style={{
                                  width: "90%",
                                  marginRight: "10px",
                                }}
                              />
                            </Form.Item>
                            {fields.length >= 1 ? (
                              <MinusCircleOutlined
                                className="dynamic-delete-button text-danger pt-2"
                                onClick={() => remove(field.name)}
                              />
                            ) : null}
                          </Form.Item>
                        ))}
                        <Form.Item
                        // className="d-flex justify-content-end "
                        // style={{
                        //   width: "90%",
                        //   marginRight: "10px",
                        // }}
                        >
                          <button
                            type="button"
                            onClick={() => add()}
                            style={{}}
                            className="custum-btn-link"
                          >
                            <PlusOutlined />
                            Ajouter un lien
                          </button>

                          <Form.ErrorList errors={errors} />
                        </Form.Item>
                      </div>
                    )}
                  </Form.List>
                </Form.Item>
              </div>
            </div>
          </div>
          <Form.Item className="d-flex justify-content-center">
            <Space>
              <Button onClick={handleCancel}>Annuler</Button>
              <Button type="primary" htmlType="submit">
                Publier
              </Button>
            </Space>
            {loading && (
              <Spin
                style={{ marginLeft: "10px" }}
                size="large"
                indicator={antIcon}
              />
            )}
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default PostForm;
