import React, { useState, useEffect } from "react";
import { Button, Steps, Spin } from "antd";
import accountPana from "../../../Assets/Account-pana.svg";
import { LoadingOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faArrowLeft,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";

const { Step } = Steps;
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);
function StepsForm({ steps, form, loading, setInitFormValues }) {
  const [current, setCurrent] = useState(0);

  const next = () => {
    form.validateFields().then(() => {
      setCurrent(current + 1);
    });
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const [windowSize, setWindowSize] = useState(
    getWindowSize().innerWidth <= 992
  );

  useEffect(() => {
    function handleWindowResize() {
      if (getWindowSize().innerWidth < 992) {
        setWindowSize(true);
      } else {
        setWindowSize(false);
      }
    }
    window.addEventListener("resize", handleWindowResize);

    handleWindowResize();
    // disabled-warning-next-line
  }, []);

  return (
    <>
      <Steps
        direction={windowSize ? "vertical" : "horizontal"}
        current={current}
      >
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <Spin indicator={antIcon} spinning={loading}>
        <div
          style={{ backgroundColor: "#f0fafd" }}
          className="my-5 p-4 rounded shadow-md row"
        >
          <div className="steps-content py-4 col-lg-8 col-md-12 col-sm-12">
            {steps[current].content}
          </div>
          <div className="col-lg-4 d-none d-lg-block">
            <img src={accountPana} />
          </div>
          <div className="steps-action d-flex justify-content-evenly">
            {current >= 0 && (
              <Button
                style={{
                  margin: "0 8px",
                }}
                onClick={() => {
                  if (current === 0) {
                    setInitFormValues({ type: "", isFull: false });
                  } else {
                    prev();
                  }
                }}
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  style={{ marginRight: "5px", marginTop: "5px" }}
                />{" "}
                Précédent
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button type="primary" onClick={() => next()}>
                Suivant{" "}
                <FontAwesomeIcon
                  icon={faArrowRight}
                  style={{ marginLeft: "5px", marginTop: "5px" }}
                />
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button type="primary" htmlType="submit">
                Ajouter !{" "}
                <FontAwesomeIcon
                  icon={faUserPlus}
                  style={{ marginLeft: "5px", marginTop: "5px" }}
                />
              </Button>
            )}
          </div>
        </div>
      </Spin>
    </>
  );
}

export default StepsForm;
