import { Button, Drawer, FloatButton, Tooltip } from "antd";
import { FormatPainterFilled } from "@ant-design/icons";
import { useContext, useState } from "react";
import GlobalContext from "../../Context/GlobalContext";
import CustomThemeEditorModal from "../CustomThemeEditorModal";

const ThemeEditor = () => {
  const { handleTheme } = useContext(GlobalContext);
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <FloatButton
        onClick={showDrawer}
        type="primary"
        icon={<FormatPainterFilled />}
        tooltip={<div>Theme</div>}
      />
      <Drawer
        title="Éditeur de thème"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <Button
            onClick={() => handleTheme(1)}
            type="primary"
            style={{
              width: "100%",
              background: "#2b5497",
              fontSize: "1.5rem",
              height: "130px",
              margin: ".5rem 0",
            }}
          >
            Classic
          </Button>
          <Button
            onClick={() => handleTheme(2)}
            type="primary"
            style={{
              width: "100%",
              background: "#001529",
              fontSize: "1.5rem",
              height: "130px",
              margin: ".5rem 0",
            }}
          >
            Dark
          </Button>
          <Button
            onClick={() => handleTheme(0)}
            style={{
              width: "100%",
              fontSize: "1.5rem",
              height: "130px",

              margin: ".5rem 0",
            }}
          >
            Light
          </Button>

          <CustomThemeEditorModal />
        </div>
      </Drawer>
    </>
  );
};
export default ThemeEditor;
