import { Button, Drawer, Form, Input, message } from "antd";
import React, { useState } from "react";
import axios from "../../../Utils/axios";

const FaqDrawer = ({ faqs, setFaqs }) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  /* eslint-disable-next-line */
  const [placement, setPlacement] = useState("left");
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const handleFinish = async (formValues) => {
    try {
      const { data } = await axios.post("/api/faq/", formValues);
      setFaqs([...faqs, { _id: data.faq._id }]);
      form.resetFields();
      onClose();
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  return (
    <div>
      <Button type="primary" onClick={showDrawer}>
        Nouvelle FAQ
      </Button>

      <Drawer
        title="Nouvelle FAQ"
        placement={"top"}
        closable={false}
        onClose={onClose}
        open={open}
        key={placement}
      >
        <Form form={form} onFinish={handleFinish} layout="vertical">
          <Form.Item
            label="Question"
            name="_question"
            rules={[{ required: true, message: "Merci de remplir le champ" }]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item
            label="Réponse"
            name="_description"
            rules={[{ required: true, message: "Merci de remplir le champ" }]}
          >
            <Input.TextArea size="large" />
          </Form.Item>
          <Form.Item>
            <Button size="large" type="primary" htmlType="submit">
              Soumettre!
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};

export default FaqDrawer;
