import { useState, useEffect } from "react";
import axios from "../../Utils/axios";
import { Form, Input, Button, Typography, Spin } from "antd";
import LoginTag from "../../Assets/Fingerprint-pana.svg";
import Logo from "../../Assets/logo-neop-dev-bleu.png";
import jwt from "jwt-decode";
import { useNavigate } from "react-router-dom";
import LocalStorageService from "../../Utils/localStorageService";

import { LoadingOutlined } from "@ant-design/icons";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

const { Text, Link } = Typography;

const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [token, setToken] = useState("");

  const [role, setRole] = useState("");
  const [form] = Form.useForm();

  const Navigate = useNavigate();
  const { _setToken, _setRole } = props;

  const handleLogin = async (formValues) => {
    setLoading(true);
    try {
      const { data } = await axios.patch("/api/auth/login", formValues);
      if (data.status) {
        setToken(data.token);
        setRole(jwt(data.token).Role);
        _setToken(data.token);
        _setRole(jwt(data.token).Role);
      } else {
        setMessage("Le compte est désactivé.");
        setLoading(false);
      }
    } catch (error) {
      setMessage(error.response.data.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    sessionStorage.clear();
    localStorage.removeItem("access_token");
  }, []);

  useEffect(() => {
    if (token) {
      LocalStorageService().setToken(token);
      if (role === "EMPLOYEE") {
        if (!props.maintainance) {
          Navigate("/maintenance");
        } else {
          Navigate("/");
        }
      } else {
        Navigate("/administration/");
        LocalStorageService().setNotif(true);
      }
    }
    // eslint-disable-next-line
  }, [role, token]);

  return (
    <div className="min-vh-100 h-100 bg-custom">
      <div className="container">
        <div className="d-flex justify-content-center ">
          <div className="col-lg-9 col-md-12 col-sm-12 row bg-light mt-5 bg-opacity-75 py-3 rounded-5 shadow">
            <div className="col-lg-6 col-md-6 col-sm-12 border-right-custom ">
              <img src={LoginTag} alt="LOGIN" />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 p-4 d-flex flex-column justify-content-between">
              <div>
                <h5 className="fw-bold text-center mb-4">
                  Connectez-vous à votre compte
                </h5>
                <Form
                  disabled={loading}
                  form={form}
                  labelCol={{
                    xs: { span: 24 },
                    sm: { span: 24 },
                    md: { span: 24 },
                  }}
                  wrapperCol={{
                    xs: { span: 24 },
                    sm: { span: 24 },
                    md: { span: 24 },
                    lg: { span: 24 },
                  }}
                  layout="vertical"
                  size="middle"
                  onFinish={handleLogin}
                >
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "S’il vous plaît entrer votre email!",
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => {
                        form.setFieldsValue({
                          email: e.target.value.replace(/ /g, ""),
                        });
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Mot de passe"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "S’il vous plaît entrer votre mot de passe!",
                      },
                    ]}
                  >
                    <Input.Password
                      onChange={(e) => {
                        form.setFieldsValue({
                          password: e.target.value.replace(/ /g, ""),
                        });
                      }}
                    />
                  </Form.Item>
                  {message && <Text type="danger">{message}</Text>}
                  <Form.Item>
                    <div className="d-flex justify-content-between mt-2">
                      <Button type="primary" htmlType="submit">
                        Se connecter
                      </Button>
                      {loading && (
                        <Spin
                          style={{ marginLeft: "10px" }}
                          size="large"
                          indicator={antIcon}
                        />
                      )}
                      <Link
                        className="mt-1"
                        onClick={() => {
                          Navigate("/forgot-password");
                        }}
                      >
                        Mot de passe oublié ?
                      </Link>
                    </div>
                  </Form.Item>
                </Form>
              </div>
              <div>
                <div className="d-flex justify-content-between">
                  <Text type="primary" className="mt-2" strong>
                    ©2023 Neopolis-dev
                  </Text>
                  <img src={Logo} style={{ height: "35px" }} alt="Logo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
