import { useEffect, useState } from "react";
import { Avatar, List, Skeleton, Typography } from "antd";

import axios from "../../Utils/axios";
const SubordinateItem = ({ id }) => {
  const [item, setItem] = useState();

  useEffect(() => {
    async function fetchSubordinates() {
      const { data } = await axios.get("/api/employees/sub/" + id);
      setItem(data);
      return data;
    }

    fetchSubordinates();
  }, []);
  return (
    <List.Item>
      <Skeleton avatar title={false} active loading={item ? false : true}>
        <List.Item.Meta
          avatar={<Avatar src={item?.imgProfile} />}
          title={<Typography.Text strong>{item?.fullName}</Typography.Text>}
          description={item?.Poste}
        />
      </Skeleton>
    </List.Item>
  );
};

export default SubordinateItem;
