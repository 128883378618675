import { useState, useEffect, useRef } from "react";

import { SearchOutlined } from "@ant-design/icons";

import { Button, Space, Table, Tag, Typography, Input, Tooltip } from "antd";

import Highlighter from "react-highlight-words";

import axios from "../../Utils/axios";
import moment from "moment";
const { Text, Paragraph, Link } = Typography;

const HistoryLeave = (props) => {
  const { size, dateRange } = props;
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        justify-content-center
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Nom et prénom",
      dataIndex: "fullName",
      key: "1",
      ...getColumnSearchProps("fullName"),
      render: (_, requestedLeaves) => (
        <Link strong>
          #{requestedLeaves.idEmployee} - {requestedLeaves.fullName}
        </Link>
      ),
    },
    {
      title: "Demande",
      dataIndex: "_typeLeave",
      width: 200,
      render: (_, requestedLeaves) => (
        <>
          {requestedLeaves._special_leave ? (
            <Paragraph
              style={{ margin: 0 }}
              ellipsis={{
                rows: 2,
                expandable: true,
                symbol: "Plus",
              }}
            >
              {requestedLeaves._typeLeave +
                `${
                  requestedLeaves._special_leave &&
                  " - " + requestedLeaves._special_leave
                }`}
            </Paragraph>
          ) : (
            <Text>{requestedLeaves._typeLeave}</Text>
          )}
        </>
      ),
    },
    {
      title: "Statut",
      dataIndex: "status",
      key: "7",
      filters: [
        {
          text: <Tag color={"orange"}>En attente</Tag>,
          value: "Pending",
        },

        {
          text: <Tag color={"green"}>Approuvée</Tag>,
          value: "Accepted",
        },

        {
          text: <Tag color={"cyan"}>En attente d'approbation</Tag>,
          value: "Validating",
        },

        {
          text: <Tag color={"red"}>Rejeté</Tag>,
          value: "Rejected",
        },

        {
          text: <Tag color="red">Annulée</Tag>,
          value: "Canceled",
        },

        {
          text: <Tag color={"gold"}>Non encore justifié</Tag>,
          value: "Unjustified",
        },

        {
          text: <Tag>échue</Tag>,
          value: "Failed",
        },
      ],
      onFilter: (value, record) => record.status.includes(value),
      render: (_, record) => (
        <>
          {record.status === "Pending" && (
            <Tag color={"orange"}>En attente</Tag>
          )}
          {record.status === "Accepted" && <Tag color={"green"}>Approuvée</Tag>}
          {record.status === "Validating" && (
            <Tag color={"cyan"}>En attente d'approbation</Tag>
          )}
          {record.status === "Rejected" && <Tag color={"red"}>Rejeté</Tag>}
          {record.status === "Canceled" && <Tag color="red">Annulée</Tag>}
          {record.status === "Unjustified" && (
            <Tag color={"gold"}>Non encore justifié</Tag>
          )}
          {record.status === "Failed" && (
            <Tooltip title="Considérée comme absence non justifiée">
              <Tag>échue</Tag>
            </Tooltip>
          )}
        </>
      ),
    },
    {
      title: "Motif",
      dataIndex: "_event",
      key: "4",
      width: 230,
      render: (_, requestedLeaves) => (
        <>
          <Paragraph
            style={{ margin: 0, whiteSpace: "pre-wrap" }}
            ellipsis={{
              rows: 2,
              expandable: true,
              symbol: "Plus",
            }}
          >
            {requestedLeaves._event || requestedLeaves._motif || "N/A"}
          </Paragraph>
        </>
      ),
    },
    {
      title: "Date de début",
      dataIndex: "BeginingDate",
      sorter: (a, b) => moment(a.BeginingDate) - moment(b.BeginingDate),
      render: (text) => <Text>{moment(text).format("DD/MM/YYYY")}</Text>,
    },
    {
      title: "Date de fin",
      dataIndex: "EndingDate",
      key: "6",
      sorter: (a, b) => moment(a.EndingDate) - moment(b.EndingDate),
      render: (text) => <Text>{moment(text).format("DD/MM/YYYY")}</Text>,
    },

    {
      title: "Durée",
      render: (_, requestedLeaves) => (
        <Text>
          {requestedLeaves._typeLeave === "Autorisation"
            ? requestedLeaves._duration
            : !requestedLeaves._is_half_day
            ? requestedLeaves.DaysNumber + " Jour(s)"
            : requestedLeaves._is_half_day}
        </Text>
      ),
    },
    {
      title: "Soumis le",
      dataIndex: "_posted_date",
      sorter: (a, b) => moment(a._posted_date) - moment(b._posted_date),
      render: (_, leave) => (
        <Text strong>
          {leave?._posted_date
            ? moment(leave._posted_date).format("DD/MM/YYYY HH:mm")
            : "N/A"}
        </Text>
      ),
    },
    {
      title: "Répondu par",
      dataIndex: "_answered_by",
      render: (_, requestedLeaves) => (
        <>
          {requestedLeaves.status === "Pending" ? (
            <Tag color={"orange"}>En attente</Tag>
          ) : requestedLeaves._answered_by ? (
            <Tooltip
              title={
                "Répondu à " +
                moment(requestedLeaves._answered_at).format("DD/MM/YYYY HH:mm")
              }
            >
              <Text> {requestedLeaves._answered_by} </Text>
            </Tooltip>
          ) : (
            <Text>N/A</Text>
          )}
        </>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button size="small" disabled>
          Valider
        </Button>
      ),
    },
  ];

  const [loading, setLoading] = useState(true);
  const [requestedLeaves, setRequestedLeaves] = useState([]);

  useEffect(() => {
    async function fetchRequestedLeaves() {
      const { data } = await axios.get(
        `/api/leaves/requested/leaves/history${
          dateRange
            ? "?startDate=" +
              dateRange.startDate +
              "&endDate=" +
              dateRange.endDate
            : ""
        }`
      );
      setRequestedLeaves(data);
      setLoading(false);
      return data;
    }

    fetchRequestedLeaves();
  }, [dateRange]);

  return (
    <Table
      className="shadow-md"
      bordered={true}
      loading={loading}
      columns={columns}
      size={size}
      scroll={{ x: "max-content" }}
      dataSource={requestedLeaves}
    />
  );
};

export default HistoryLeave;
